import { xingZuoForNum3 } from "../../utils/constant";
import Format from "../../utils/Format";

var count = [0, 0, 0, 0, 0, 0, 0, 0, 0];

export const resetCount = () => {
	count = [0, 0, 0, 0, 0, 0, 0, 0, 0];
};

export const getCount = () => {
	return count;
};

// 生命数
export const formula1 = (year, month, date) => {
	var tempTotal = 0;

	for (var i = 0; i < year.length; i++) {
		let tempNum = parseInt(year[i]);
		tempTotal += tempNum;

		switch (tempNum) {
			case 1:
				count[0]++;
				break;
			case 2:
				count[1]++;
				break;
			case 3:
				count[2]++;
				break;
			case 4:
				count[3]++;
				break;
			case 5:
				count[4]++;
				break;
			case 6:
				count[5]++;
				break;
			case 7:
				count[6]++;
				break;
			case 8:
				count[7]++;
				break;
			case 9:
				count[8]++;
				break;
			default:
		}
	}

	for (var j = 0; j < month.length; j++) {
		let tempNum = parseInt(month[j]);
		tempTotal += tempNum;

		switch (tempNum) {
			case 1:
				count[0]++;
				break;
			case 2:
				count[1]++;
				break;
			case 3:
				count[2]++;
				break;
			case 4:
				count[3]++;
				break;
			case 5:
				count[4]++;
				break;
			case 6:
				count[5]++;
				break;
			case 7:
				count[6]++;
				break;
			case 8:
				count[7]++;
				break;
			case 9:
				count[8]++;
				break;
			default:
		}
	}

	for (var k = 0; k < date.length; k++) {
		let tempNum = parseInt(date[k]);
		tempTotal += tempNum;

		switch (tempNum) {
			case 1:
				count[0]++;
				break;
			case 2:
				count[1]++;
				break;
			case 3:
				count[2]++;
				break;
			case 4:
				count[3]++;
				break;
			case 5:
				count[4]++;
				break;
			case 6:
				count[5]++;
				break;
			case 7:
				count[6]++;
				break;
			case 8:
				count[7]++;
				break;
			case 9:
				count[8]++;
				break;
			default:
		}
	}

	while (tempTotal > 9) {
		var temp = tempTotal.toString();
		tempTotal = 0;

		for (var a = 0; a < temp.length; a++) {
			let tempNum = parseInt(temp[a]);
			tempTotal += tempNum;

			switch (tempNum) {
				case 1:
					count[0]++;
					break;
				case 2:
					count[1]++;
					break;
				case 3:
					count[2]++;
					break;
				case 4:
					count[3]++;
					break;
				case 5:
					count[4]++;
					break;
				case 6:
					count[5]++;
					break;
				case 7:
					count[6]++;
					break;
				case 8:
					count[7]++;
					break;
				case 9:
					count[8]++;
					break;
				default:
			}
		}
	}

	switch (tempTotal) {
		case 1:
			count[0]++;
			break;
		case 2:
			count[1]++;
			break;
		case 3:
			count[2]++;
			break;
		case 4:
			count[3]++;
			break;
		case 5:
			count[4]++;
			break;
		case 6:
			count[5]++;
			break;
		case 7:
			count[6]++;
			break;
		case 8:
			count[7]++;
			break;
		case 9:
			count[8]++;
			break;
		default:
	}

	return tempTotal;
};

export const tianfushu = (year, month, date) => {
	var tempTotal = 0;

	for (var i = 0; i < year.length; i++) {
		let tempNum = parseInt(year[i]);
		tempTotal += tempNum;
	}

	for (var j = 0; j < month.length; j++) {
		let tempNum = parseInt(month[j]);
		tempTotal += tempNum;
	}

	for (var k = 0; k < date.length; k++) {
		let tempNum = parseInt(date[k]);
		tempTotal += tempNum;
	}

	return tempTotal;
};

// 生日数
export const formula2 = (date) => {
	var tempTotal = 0;

	for (var i = 0; i < date.length; i++) {
		tempTotal += parseInt(date[i]);
	}

	while (tempTotal > 9) {
		var temp = tempTotal.toString();
		tempTotal = 0;

		for (var a = 0; a < temp.length; a++) {
			tempTotal += parseInt(temp[a]);
		}
	}

	return tempTotal;
};

// 星座
export const formula3 = (xingzuo) => {
	let tempNum = xingZuoForNum3.find(({ name }) => name === xingzuo).num;

	switch (tempNum) {
		case 1:
			count[0]++;
			break;
		case 2:
			count[1]++;
			break;
		case 3:
			count[2]++;
			break;
		case 4:
			count[3]++;
			break;
		case 5:
			count[4]++;
			break;
		case 6:
			count[5]++;
			break;
		case 7:
			count[6]++;
			break;
		case 8:
			count[7]++;
			break;
		case 9:
			count[8]++;
			break;
		default:
	}

	return tempNum;
};

// 生日第二个号码
export const formula4 = (date) => {
	var temp = Format.left_pad(date, 2, "0");

	return parseInt(temp[1]);
};

// 天赋数第二个号码
export const formula5 = (year, month, date) => {
	var tempTotal = 0;

	for (var i = 0; i < year.length; i++) {
		tempTotal += parseInt(year[i]);
	}

	for (var j = 0; j < month.length; j++) {
		tempTotal += parseInt(month[j]);
	}

	for (var k = 0; k < date.length; k++) {
		tempTotal += parseInt(date[k]);
	}

	var temp = Format.left_pad(tempTotal.toString(), 2, "0")[1];

	return parseInt(temp);
};

// 生日加起来的单数
export const formula6 = (date) => {
	var tempTotal = 0;

	for (var i = 0; i < date.length; i++) {
		tempTotal += parseInt(date[i]);
	}

	while (tempTotal > 9) {
		var temp = tempTotal.toString();
		tempTotal = 0;

		for (var a = 0; a < temp.length; a++) {
			tempTotal += parseInt(temp[a]);
		}
	}

	return tempTotal;
};

// 月份
export const formula7 = (month) => {
	if (parseInt(month) > 9) {
		let tempTotal = 0;

		for (let i = 0; i < month.length; i++) {
			tempTotal += parseInt(month[i]);
		}

		return tempTotal;
	} else {
		return parseInt(month);
	}
};

// 年份
export const formula8_1 = (year) => {
	return parseInt(year.toString()[3]);
};

export const formula8_2 = (year) => {
	return parseInt(year.toString()[2]);
};

export const formula8_3 = (year) => {
	return parseInt(year.toString()[1]);
};

export const formula8_4 = (year) => {
	return parseInt(year.toString()[0]);
};

export const formula8_5 = (year) => {
	let num1 = parseInt(year.toString()[0]);
	let num2 = parseInt(year.toString()[1]);
	let num3 = parseInt(year.toString()[2]);
	let num4 = parseInt(year.toString()[3]);

	let tempTotal = num1 + num2 + num3 + num4;

	while (tempTotal > 9) {
		let temp = tempTotal.toString();
		tempTotal = 0;

		for (let a = 0; a < temp.length; a++) {
			tempTotal += parseInt(temp[a]);
		}
	}

	return tempTotal;
};
