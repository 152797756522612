import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	Paper,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Tooltip,
	Typography,
	Zoom,
} from "@mui/material";
import React, { Fragment, useState } from "react";

import { MuiButton } from "../components/common";
import { BadIcon, CloseIcon, GoodIcon, HalfIcon, SmileIcon } from "../components/Icon";
import { useTheme } from "../hooks/useTheme";

function XingMingXue() {
	const { theme } = useTheme();

	const [state, setState] = useState({
		openZodiacDialog: false,
		openMatchDialog: false,
		hanzi1: "",
		hanzi2: "",
		hanzi3: "",
		hanzi4: "",
		bihua1: 1,
		bihua2: 1,
		bihua3: 1,
		bihua4: 1,
		tiangeBihua: 2,
		rengeBihua: 2,
		digeBihua: 2,
		waigeBihua: 2,
		zonggeBihua: 0,
		tiangeIcon: "default",
		rengeIcon: "default",
		digeIcon: "default",
		waigeIcon: "default",
		zonggeIcon: "default",
		wuge: [],
	});

	const handleOpenZodiacDialog = () => setState({ ...state, openZodiacDialog: true });
	const handleCloseZodiacDialog = () => setState({ ...state, openZodiacDialog: false });

	const handleOpenMatchDialog = () => setState({ ...state, openMatchDialog: true });
	const handleCloseMatchDialog = () => setState({ ...state, openMatchDialog: false });

	const onChangeHanzi = async (e) => {
		const { name, value } = e.target;

		setState((prevState) => ({ ...prevState, [name]: value }));

		fetch("https://tianshu.estoneweb.com/api/xingming/get_xingming_result.php", {
			method: "POST",
			body: JSON.stringify({
				hanzi1: name === "hanzi1" ? value : state.hanzi1,
				hanzi2: name === "hanzi2" ? value : state.hanzi2,
				hanzi3: name === "hanzi3" ? value : state.hanzi3,
				hanzi4: name === "hanzi4" ? value : state.hanzi4,
			}),
		})
			.then((res) => res.json())
			.then((result) => {
				let bihua1 = name === "hanzi1" ? (result.bihua1 ? parseInt(result.bihua1) : 1) : state.bihua1;
				let bihua2 = name === "hanzi2" ? (result.bihua2 ? parseInt(result.bihua2) : 1) : state.bihua2;
				let bihua3 = name === "hanzi3" ? (result.bihua3 ? parseInt(result.bihua3) : 1) : state.bihua3;
				let bihua4 = name === "hanzi4" ? (result.bihua4 ? parseInt(result.bihua4) : 1) : state.bihua4;

				setState((prevState) => ({
					...prevState,
					bihua1,
					bihua2,
					bihua3,
					bihua4,
					tiangeBihua: result.tiange.bihua,
					rengeBihua: result.renge.bihua,
					digeBihua: result.dige.bihua,
					waigeBihua: result.waige.bihua,
					zonggeBihua: result.zongge.bihua,
					tiangeIcon: result.tiange?.icon ? result.tiange.icon : "default",
					rengeIcon: result.renge?.icon ? result.renge.icon : "default",
					digeIcon: result.dige?.icon ? result.dige.icon : "default",
					waigeIcon: result.waige?.icon ? result.waige.icon : "default",
					zonggeIcon: result.zongge?.icon ? result.zongge.icon : "default",
					wuge: [
						{ ...result.tiange, name: "天格", number: result.tiange.bihua },
						{ ...result.renge, name: "人格", number: result.renge.bihua },
						{ ...result.dige, name: "地格", number: result.dige.bihua },
						{ ...result.waige, name: "外格", number: result.waige.bihua },
						{ ...result.zongge, name: "总格", number: result.zongge.bihua },
					],
				}));
			})
			.catch((error) => console.error(error));
	};

	const renderIcon = (icon) => {
		return icon === "good" ? <GoodIcon /> : icon === "half" ? <HalfIcon /> : icon === "bad" ? <BadIcon /> : <SmileIcon />;
	};

	const clear = () => {
		setState({
			...state,
			hanzi1: "",
			hanzi2: "",
			hanzi3: "",
			hanzi4: "",
			bihua1: 1,
			bihua2: 1,
			bihua3: 1,
			bihua4: 1,
			tiangeBihua: 2,
			rengeBihua: 2,
			digeBihua: 2,
			waigeBihua: 2,
			zonggeBihua: 0,
			tiangeIcon: "default",
			rengeIcon: "default",
			digeIcon: "default",
			waigeIcon: "default",
			zonggeIcon: "default",
			wuge: [],
		});
	};

	const getBihuaGoodBad = () => {
		fetch("https://tianshu.estoneweb.com/api/xingming/get_bihua_good_bad.php", {
			method: "POST",
		})
			.then((res) => res.json())
			.then((result) => console.log(result))
			.catch((error) => console.error(error));
	};

	return (
		<>
			<Paper elevation={4} sx={{ p: 2, mb: 2 }}>
				<Stack direction="column" spacing={2}>
					<Typography sx={{ textAlign: "center" }}>姓名学</Typography>
					<Stack direction="row" spacing={2} justifyContent="center">
						<MuiButton variant="outlined" content="生肖 宜忌" onClick={handleOpenZodiacDialog} />
						<MuiButton variant="outlined" content="姓名配合吉数" onClick={handleOpenMatchDialog} />
					</Stack>
					<ZodiacDialog openZodiacDialog={state.openZodiacDialog} handleCloseZodiacDialog={handleCloseZodiacDialog} />
					<MatchDialog openMatchDialog={state.openMatchDialog} handleCloseMatchDialog={handleCloseMatchDialog} />

					<Stack
						direction="row"
						spacing={2}
						sx={{ borderTop: "2px solid #eef0f8", marginTop: "10px", paddingTop: "15px", justifyContent: "center" }}
					>
						<table className={`name-input ${theme}`} style={{ maxWidth: "340px", width: "50vw" }}>
							<tbody>
								<tr>
									<td rowSpan={4}>
										<Stack direction="column" spacing={1} sx={{ textAlign: "center" }}>
											<span>{state.waigeBihua}</span>
											<Typography>外格</Typography>
											<span>{renderIcon(state.waigeIcon)}</span>
										</Stack>
									</td>
									<td className="text-align-center w-bg rounded-top-left">
										<TextField
											hiddenLabel
											inputProps={{ maxLength: 1, style: { textAlign: "center" } }}
											variant="filled"
											size="small"
											className="hanzi"
											name="hanzi1"
											onChange={onChangeHanzi}
											value={state.hanzi1}
											sx={{ width: "15vw", maxWidth: "60px" }}
										/>
									</td>
									<td className="text-align-center w-bg rounded-top-right">
										<span className="bihua">{state.bihua1}</span>
									</td>
								</tr>
								<tr>
									<td className="text-align-center w-bg">
										<TextField
											hiddenLabel
											inputProps={{ maxLength: 1, style: { textAlign: "center" } }}
											variant="filled"
											size="small"
											className="hanzi"
											name="hanzi2"
											onChange={onChangeHanzi}
											value={state.hanzi2}
											sx={{ width: "15vw", maxWidth: "60px" }}
										/>
									</td>
									<td className="text-align-center w-bg">
										<span className="bihua">{state.bihua2}</span>
									</td>
								</tr>
								<tr>
									<td className="text-align-center w-bg">
										<TextField
											hiddenLabel
											inputProps={{ maxLength: 1, style: { textAlign: "center" } }}
											variant="filled"
											size="small"
											className="hanzi"
											name="hanzi3"
											onChange={onChangeHanzi}
											value={state.hanzi3}
											sx={{ width: "15vw", maxWidth: "60px" }}
										/>
									</td>
									<td className="text-align-center w-bg">
										<span className="bihua">{state.bihua3}</span>
									</td>
								</tr>
								<tr>
									<td className="text-align-center w-bg rounded-bottom-left">
										<TextField
											hiddenLabel
											inputProps={{ maxLength: 1, style: { textAlign: "center" } }}
											variant="filled"
											size="small"
											className="hanzi"
											name="hanzi4"
											onChange={onChangeHanzi}
											value={state.hanzi4}
											sx={{ width: "15vw", maxWidth: "60px" }}
										/>
									</td>
									<td className="text-align-center w-bg rounded-bottom-right">
										<span className="bihua">{state.bihua4}</span>
									</td>
								</tr>
								<tr>
									<td className="text-align-end" colSpan={2}>
										<Stack direction="row" spacing={1} sx={{ alignItems: "center", justifyContent: "flex-end" }}>
											<Typography>总格</Typography>
											{renderIcon(state.zonggeIcon)}
										</Stack>
									</td>
									<td className="text-align-center">
										<span className="bihua">{state.zonggeBihua}</span>
									</td>
								</tr>
							</tbody>
						</table>
						<Stack direction="column" spacing={4} style={{ marginTop: "45px" }}>
							<Stack direction="row" spacing={1} sx={{ justifyContent: "end" }}>
								<Typography>{state.tiangeBihua} 天格</Typography>
								{renderIcon(state.tiangeIcon)}
							</Stack>
							<Stack direction="row" spacing={1} sx={{ justifyContent: "end" }}>
								<Typography>{state.rengeBihua} 人格</Typography>
								{renderIcon(state.rengeIcon)}
							</Stack>
							<Stack direction="row" spacing={1} sx={{ justifyContent: "end" }}>
								<Typography>{state.digeBihua} 地格</Typography>
								{renderIcon(state.digeIcon)}
							</Stack>
							<Button variant="outlined" onClick={clear}>
								清空
							</Button>
						</Stack>
					</Stack>
					{/* <Stack
						direction="row"
						spacing={2}
						sx={{ borderTop: "2px solid #eef0f8", marginTop: "10px", paddingTop: "15px", justifyContent: "center" }}
					>
						<TableContainer sx={{ maxWidth: "350px", width: "55vw" }}>
							<Table size="small" className={`name-input ${theme}`}>
								<TableBody sx={{ backgroundColor: theme === "dark" ? "#393939" : "#f0f0f0" }}>
									<TableRow>
										<TableCell rowSpan={4}>
											<Stack direction="column" spacing={1} sx={{ textAlign: "center" }}>
												<span>{state.waigeBihua}</span>
												<Typography>外格</Typography>
												<span>{renderIcon(state.waigeIcon)}</span>
											</Stack>
										</TableCell>
										<TableCell className="text-align-center">
											<TextField
												hiddenLabel
												inputProps={{ maxLength: 1, style: { textAlign: "center" } }}
												variant="filled"
												size="small"
												className="hanzi"
												name="hanzi1"
												onChange={onChangeHanzi}
												value={state.hanzi1}
												sx={{ width: "10vw", maxWidth: "50px" }}
											/>
										</TableCell>
										<TableCell className="text-align-center">
											<span className="bihua">{state.bihua1}</span>
										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell className="text-align-center">
											<TextField
												hiddenLabel
												inputProps={{ maxLength: 1, style: { textAlign: "center" } }}
												variant="filled"
												size="small"
												className="hanzi"
												name="hanzi2"
												onChange={onChangeHanzi}
												value={state.hanzi2}
												sx={{ width: "10vw", maxWidth: "50px" }}
											/>
										</TableCell>
										<TableCell className="text-align-center">
											<span className="bihua">{state.bihua2}</span>
										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell className="text-align-center">
											<TextField
												hiddenLabel
												inputProps={{ maxLength: 1, style: { textAlign: "center" } }}
												variant="filled"
												size="small"
												className="hanzi"
												name="hanzi3"
												onChange={onChangeHanzi}
												value={state.hanzi3}
												sx={{ width: "10vw", maxWidth: "50px" }}
											/>
										</TableCell>
										<TableCell className="text-align-center">
											<span className="bihua">{state.bihua3}</span>
										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell className="text-align-center">
											<TextField
												hiddenLabel
												inputProps={{ maxLength: 1, style: { textAlign: "center" } }}
												variant="filled"
												size="small"
												className="hanzi"
												name="hanzi4"
												onChange={onChangeHanzi}
												value={state.hanzi4}
												sx={{ width: "10vw", maxWidth: "50px" }}
											/>
										</TableCell>
										<TableCell className="text-align-center">
											<span className="bihua">{state.bihua4}</span>
										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell colSpan={2} style={{ textAlign: "right" }}>
											<Stack direction="row" spacing={1} sx={{ alignItems: "center", justifyContent: "flex-end" }}>
												<Typography>总格</Typography>
												{renderIcon(state.zonggeIcon)}
											</Stack>
										</TableCell>
										<TableCell className="text-align-center">
											<span className="bihua">{state.zonggeBihua}</span>
										</TableCell>
									</TableRow>
								</TableBody>
							</Table>
						</TableContainer>
						<Stack direction="column" spacing={4} style={{ marginTop: "45px" }}>
							<Stack direction="row" spacing={1} sx={{ justifyContent: "end" }}>
								<Typography>{state.tiangeBihua} 天格</Typography>
								{renderIcon(state.tiangeIcon)}
							</Stack>
							<Stack direction="row" spacing={1} sx={{ justifyContent: "end" }}>
								<Typography>{state.rengeBihua} 人格</Typography>
								{renderIcon(state.rengeIcon)}
							</Stack>
							<Stack direction="row" spacing={1} sx={{ justifyContent: "end" }}>
								<Typography>{state.digeBihua} 地格</Typography>
								{renderIcon(state.digeIcon)}
							</Stack>
							<Button variant="outlined" onClick={clear}>
								清空
							</Button>
						</Stack>
					</Stack> */}
					<Stack
						direction="row"
						spacing={3}
						sx={{ borderTop: "2px solid #eef0f8", marginTop: "15px", paddingTop: "15px", justifyContent: "center" }}
						onClick={getBihuaGoodBad}
					>
						<Stack direction="row" spacing={1} sx={{ alignItems: "center" }}>
							<GoodIcon />
							<Typography>吉</Typography>
						</Stack>
						<Stack direction="row" spacing={1} sx={{ alignItems: "center" }}>
							<HalfIcon />
							<Typography>半吉半凶</Typography>
						</Stack>
						<Stack direction="row" spacing={1} sx={{ alignItems: "center" }}>
							<BadIcon />
							<Typography>凶</Typography>
						</Stack>
					</Stack>
				</Stack>
			</Paper>
			<BihuaResult wuge={state.wuge} />
		</>
	);
}

function BihuaResult(props) {
	const getWuXing = (num) => {
		switch (num % 10) {
			case 1:
			case 2:
				return "木";
			case 3:
			case 4:
				return "火";
			case 5:
			case 6:
				return "土";
			case 7:
			case 8:
				return "金";
			case 9:
			case 0:
				return "水";
			default:
				return "";
		}
	};

	return (
		<Paper elevation={4} sx={{ p: 2 }}>
			<Stack direction="column" spacing={2}>
				<Typography variant="h5">数理运势</Typography>
				{props.wuge.map((i, index) => (
					<p key={index}>
						{/* <Divider sx={{ mb: 2, borderTop: "2px solid #eef0f8" }} /> */}
						<Typography variant="h6" gutterBottom>
							{i.name} ({getWuXing(i.number)})
						</Typography>
						<Stack direction="column" spacing={1} justifyContent="flex-start">
							<Typography variant="h6">
								{i.description} ({i.result})
							</Typography>
							<TableContainer component={Paper}>
								<Table>
									<TableBody>
										<TableRow>
											<TableCell sx={{ textAlign: "center", width: { xs: "20%", md: "12%" } }}>基業</TableCell>
											<TableCell>{i.jiye}</TableCell>
										</TableRow>
										<TableRow>
											<TableCell sx={{ textAlign: "center" }}>家庭</TableCell>
											<TableCell>{i.jiating}</TableCell>
										</TableRow>
										<TableRow>
											<TableCell sx={{ textAlign: "center" }}>健康</TableCell>
											<TableCell>{i.jiankang}</TableCell>
										</TableRow>
										<TableRow>
											<TableCell sx={{ textAlign: "center" }}>含义</TableCell>
											<TableCell>{i.hanyi}</TableCell>
										</TableRow>
									</TableBody>
								</Table>
							</TableContainer>
						</Stack>
					</p>
				))}
				<Divider sx={{ mb: 2, borderTop: "2px solid #eef0f8" }} />
				<Typography variant="h5" sx={{ mt: 2 }}>
					三才配置
				</Typography>
			</Stack>
		</Paper>
	);
}

function ZodiacDialog(props) {
	const arrays = [
		{ nian: "鼠年", good: "宀、米、豆、鱼、廿、金、玉、人、木、月、田", bad: "月、刀、力、弓、土、穴、心、石、皮、木、马、酉、才、火、车" },
		{ nian: "牛年", good: "水、艹、豆、米、金、玉、宀、人、木", bad: "月、火、田、车、马、石、血、纟、刀、几" },
		{ nian: "虎年", good: "山、玉、金、木、示、水、月、芎、马", bad: "日、火、田、口、几、乡、石、刀、血、弓、父、足" },
		{ nian: "兔年", good: "月、艹、山、田、人、禾、木、宀、金、白、玉、豆", bad: "马、车、石、刀、力、皮、水、川" },
		{ nian: "龙年", good: "水、金、玉、白、赤、月、鱼、酉、人", bad: "土、田、木、禾、示、心、日、石、艹、力、刀、纟，犭、火" },
		{ nian: "蛇年", good: "艹、虫、豆、鱼、酉、木、田、山、金、玉、月、土", bad: "小、石、刀、血、弓、火、人、犭、父、乡" },
		{ nian: "马年", good: "艹、金、玉、木、禾、虫、米、人、月、土、才", bad: "田、日、火、水、车、石、力、刀、酉、马" },
		{ nian: "羊年", good: "金、白、玉、月、田、豆、米、马、禾、木、人、艹、鱼", bad: "小、犭、乡、车、山、水、日、火" },
		{ nian: "猴年", good: "本、禾、金、玉、豆、米、田、山、月、水、人", bad: "火、石、口、一、纟、刀、力、皮、犭" },
		{ nian: "鸡年", good: "米、豆、虫、木、禾、玉、月、山、艹、金", bad: "石、犭、刀、力、日、酉、血、弓、才、宝、车、马" },
		{ nian: "狗年", good: "鱼、豆、米、山、马、金、玉、艹、田、木、月、禾、水、人", bad: "火、石、纟、山、才、日、酉、车、刀、父、言" },
		{ nian: "猪年", good: "豆、米、鱼、水、金、玉、月、木、禾、人、山、土、界", bad: "纟、石、刀、力、血、弓、几、皮、父" },
	];

	return (
		<Dialog open={props.openZodiacDialog} fullWidth={true} maxWidth="lg" onClose={props.handleCloseZodiacDialog}>
			<DialogTitle>生肖 宜忌</DialogTitle>
			<DialogContent sx={{ p: 2 }}>
				<TableContainer>
					<Table>
						{arrays.map((arr, index) => (
							<Fragment key={index}>
								<TableHead>
									<TableRow>
										<TableCell colSpan={2}>
											<Typography variant="h6">{arr.nian}</Typography>
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									<TableRow>
										<TableCell>宜</TableCell>
										<TableCell>{arr.good}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>忌</TableCell>
										<TableCell>{arr.bad}</TableCell>
									</TableRow>
								</TableBody>
							</Fragment>
						))}
					</Table>
				</TableContainer>
			</DialogContent>
			<DialogActions>
				<Tooltip title="Close" TransitionComponent={Zoom} arrow>
					<Button variant="outlined" onClick={props.handleCloseZodiacDialog}>
						<CloseIcon />
					</Button>
				</Tooltip>
			</DialogActions>
		</Dialog>
	);
}

function MatchDialog(props) {
	return (
		<Dialog open={props.openMatchDialog} fullWidth={true} maxWidth="lg" onClose={props.handleCloseMatchDialog}>
			<DialogTitle>姓名配合吉数</DialogTitle>
			<DialogContent></DialogContent>
			<DialogActions>
				<Tooltip title="Close" TransitionComponent={Zoom} arrow>
					<Button variant="outlined" onClick={props.handleCloseMatchDialog}>
						<CloseIcon />
					</Button>
				</Tooltip>
			</DialogActions>
		</Dialog>
	);
}

export default XingMingXue;
