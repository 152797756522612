import "bootstrap/dist/css/bootstrap.min.css";

import { defineCustomElements } from "@duetds/date-picker/dist/loader";
import { Paper, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { createRoot } from "react-dom/client";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import AdminPanel from "./admin";
import AllSubmission from "./admin/AllSubmission";
import Modules from "./admin/Modules";
import Users from "./admin/Users";
import App from "./App";
import { SnackBar } from "./components/common";
import Header from "./components/Header";
import { Main, MainContainer } from "./components/Main";
import { AuthProvider } from "./hooks/useAuth";
import { ThemeProvider, useTheme } from "./hooks/useTheme";
import Login from "./Login";
import { BaGua, Calendar, ChengGu, LiuRen, Mine, NewStuff, TaLuoMiMaShu, TotalNumbers, XingMingXue } from "./modules";
import TaLuoZhanBu from "./modules/taluozhanbu";
import TarotCards from "./modules/tarotcards";
import UserPanel, { Submission, UserProfile } from "./profile";
import { ProtectedRoute } from "./protected_route";
import Register from "./Register";

defineCustomElements(window);

const queryClient = new QueryClient();

function Index() {
	const [snackbar, setSnackbar] = useState({ open: false, vertical: "bottom", horizontal: "center", type: "info", message: "" });
	const showSnackbarMessage = (type, message) => setSnackbar({ ...snackbar, open: true, type, message });
	const handleCloseSnackbar = () => setSnackbar({ ...snackbar, open: false });

	return (
		<React.StrictMode>
			<ThemeProvider>
				<BrowserRouter>
					<QueryClientProvider client={queryClient}>
						<SnackBar snackbar={snackbar} handleCloseSnackbar={handleCloseSnackbar} />
						<IndexPage showSnackbarMessage={showSnackbarMessage} />
					</QueryClientProvider>
				</BrowserRouter>
			</ThemeProvider>
		</React.StrictMode>
	);
}

function IndexPage(props) {
	const { theme } = useTheme();

	return (
		<div className={theme === "dark" ? "d-flex flex-column min-vh-100 bg-black" : "d-flex flex-column min-vh-100 bg-white"}>
			<AuthProvider>
				<Header />
				<Main>
					<MainContainer>
						<Routes>
							<Route index element={<Login />} />
							<Route exact path="register" element={<Register />} />
							<Route element={<ProtectedRoute />}>
								<Route path="adminpanel" element={<AdminPanel />}>
									<Route path="users" element={<Users />} />
									<Route path="allsubmission" element={<AllSubmission />} />
									<Route path="modules" element={<Modules />} />
								</Route>
								<Route path="userpanel" element={<UserPanel />}>
									<Route path="profile" element={<UserProfile />} />
									<Route path="submission" element={<Submission />} />
								</Route>
								<Route path="dashboard" element={<App />}>
									<Route path="mine" element={<Mine />} />
									<Route path="xingmingxue" element={<XingMingXue />} />
									<Route path="chenggu" element={<ChengGu />} />
									<Route path="liuren" element={<LiuRen />} />
									<Route path="tarotcards" element={<TarotCards />} />
									<Route path="taluomimashu" element={<TaLuoMiMaShu />} />
									<Route path="taluozhanbu" element={<TaLuoZhanBu />} />
									<Route path="totalnumbers" element={<TotalNumbers />} />
									<Route path="new" element={<NewStuff />} />
									<Route path="bagua" element={<BaGua />} />
									<Route path="calendar" element={<Calendar />} />
								</Route>
							</Route>
							<Route
								path="*"
								element={
									<Stack direction="column" alignItems="center" justifyContent="flex-start">
										<Paper elevation={4} sx={{ p: 3 }}>
											<Stack direction="column" spacing={3} alignItems="center">
												<Typography variant="h4">Page not found</Typography>
												<Typography>Kindly contact Administrator</Typography>
											</Stack>
										</Paper>
									</Stack>
								}
							/>
						</Routes>
					</MainContainer>
				</Main>
			</AuthProvider>
		</div>
	);
}

const container = document.getElementById("root");
const root = createRoot(container);
root.render(<Index />);
