import { Paper, Stack, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Lunar, Solar } from "lunar-javascript/lunar";
import { Component } from "react";

class LiuRen extends Component {
	state = {
		pickedDate: new Date(),
		lunarYear1: "",
		lunarYear2: "",
		lunarYear: "",
		lunarMonth: "",
		lunarMonthGanZhi: "",
		lunarDay: "",
		lunarDayGanZhi: "",
		dayResult: "",
		dayResultColor: "",
		timeResult: [
			{ value: "", color: "" },
			{ value: "", color: "" },
			{ value: "", color: "" },
			{ value: "", color: "" },
			{ value: "", color: "" },
			{ value: "", color: "" },
			{ value: "", color: "" },
			{ value: "", color: "" },
			{ value: "", color: "" },
			{ value: "", color: "" },
			{ value: "", color: "" },
			{ value: "", color: "" },
		],
	};

	arrYear1 = [
		{ gan: "甲", value: 1 },
		{ gan: "乙", value: 2 },
		{ gan: "丙", value: 3 },
		{ gan: "丁", value: 4 },
		{ gan: "戊", value: 5 },
		{ gan: "己", value: 6 },
		{ gan: "庚", value: 7 },
		{ gan: "辛", value: 8 },
		{ gan: "壬", value: 9 },
		{ gan: "癸", value: 10 },
	];

	arrYear2 = [
		{ zhi: "子", value: 0 },
		{ zhi: "丑", value: 1 },
		{ zhi: "寅", value: 2 },
		{ zhi: "卯", value: 3 },
		{ zhi: "辰", value: 4 },
		{ zhi: "巳", value: 5 },
		{ zhi: "午", value: 6 },
		{ zhi: "未", value: 7 },
		{ zhi: "申", value: 8 },
		{ zhi: "酉", value: 9 },
		{ zhi: "戌", value: 10 },
		{ zhi: "亥", value: 11 },
	];

	arrMonth = [
		{ yue: "正", value: 0 },
		{ yue: "二", value: 1 },
		{ yue: "三", value: 2 },
		{ yue: "四", value: 3 },
		{ yue: "五", value: 4 },
		{ yue: "六", value: 5 },
		{ yue: "七", value: 6 },
		{ yue: "八", value: 7 },
		{ yue: "九", value: 8 },
		{ yue: "十", value: 9 },
		{ yue: "冬", value: 10 },
		{ yue: "腊", value: 11 },
	];

	arrDay = [
		{ ri: "初一", value: 0 },
		{ ri: "初二", value: 1 },
		{ ri: "初三", value: 2 },
		{ ri: "初四", value: 3 },
		{ ri: "初五", value: 4 },
		{ ri: "初六", value: 5 },
		{ ri: "初七", value: 6 },
		{ ri: "初八", value: 7 },
		{ ri: "初九", value: 8 },
		{ ri: "初十", value: 9 },
		{ ri: "十一", value: 10 },
		{ ri: "十二", value: 11 },
		{ ri: "十三", value: 12 },
		{ ri: "十四", value: 13 },
		{ ri: "十五", value: 14 },
		{ ri: "十六", value: 15 },
		{ ri: "十七", value: 16 },
		{ ri: "十八", value: 17 },
		{ ri: "十九", value: 18 },
		{ ri: "二十", value: 19 },
		{ ri: "廿一", value: 20 },
		{ ri: "廿二", value: 21 },
		{ ri: "廿三", value: 22 },
		{ ri: "廿四", value: 23 },
		{ ri: "廿五", value: 24 },
		{ ri: "廿六", value: 25 },
		{ ri: "廿七", value: 26 },
		{ ri: "廿八", value: 27 },
		{ ri: "廿九", value: 28 },
		{ ri: "三十", value: 29 },
	];

	arrResult = [
		{ liuren: 1, value: "大安", textColor: "green" },
		{ liuren: 2, value: "流連", textColor: "red" },
		{ liuren: 3, value: "速喜", textColor: "green" },
		{ liuren: 4, value: "赤口", textColor: "red" },
		{ liuren: 5, value: "小吉", textColor: "green" },
		{ liuren: 0, value: "空亡", textColor: "red" },
	];

	handleChangeDate = (e) => {
		let solar = Solar.fromYmd(e.getFullYear(), e.getMonth() + 1, e.getDate());
		let lunar = Lunar.fromSolar(solar);
		// let lunarYear = LunarYear.fromYear(e.getFullYear());

		let arr1 = this.arrYear1.find((obj) => obj.gan === lunar.getYearGan());
		let arr2 = this.arrYear2.find((obj) => obj.zhi === lunar.getYearZhi());
		let arr3 = this.arrMonth.find((obj) => obj.yue === lunar.getMonthInChinese().replace("闰", ""));
		let arr4 = this.arrDay.find((obj) => obj.ri === lunar.getDayInChinese());

		let val1 = arr1.value;
		let val2 = arr2.value;
		let val3 = lunar.getMonthInChinese().includes("闰") ? arr3.value + 1 : arr3.value;
		let val4 = arr4.value;

		let total = val1 + val2 + val3 + val4;
		let result = total % 6;

		let temp = [
			{ value: "", color: "" },
			{ value: "", color: "" },
			{ value: "", color: "" },
			{ value: "", color: "" },
			{ value: "", color: "" },
			{ value: "", color: "" },
			{ value: "", color: "" },
			{ value: "", color: "" },
			{ value: "", color: "" },
			{ value: "", color: "" },
			{ value: "", color: "" },
			{ value: "", color: "" },
		];

		for (let i = 0; i < 12; i++) {
			let t = this.arrResult.find((obj) => obj.liuren === (result + i) % 6);
			temp[i].value = t.value;
			temp[i].color = t.textColor;
		}

		this.setState({
			...this.state,
			pickedDate: e,
			lunarYear1: lunar.getYearGan(),
			lunarYear2: lunar.getYearZhi(),
			lunarYear: lunar.getYearInGanZhi(),
			lunarMonth: lunar.getMonthInChinese(),
			lunarMonthGanZhi: lunar.getMonthInGanZhi(),
			lunarDay: lunar.getDayInChinese(),
			lunarDayGanZhi: lunar.getDayInGanZhi(),
			dayResult: this.arrResult.find((obj) => obj.liuren === result).value,
			dayResultColor: this.arrResult.find((obj) => obj.liuren === result).textColor,
			timeResult: temp,
		});
	};

	componentDidMount() {
		let today = new Date();

		this.handleChangeDate(today);
	}

	render() {
		return (
			<Stack direction="column" spacing={2}>
				<Paper elevation={4} sx={{ p: 2, mb: 2 }}>
					<Stack direction="column" spacing={2}>
						<Typography sx={{ textAlign: "center" }}>六壬</Typography>
						<LocalizationProvider dateAdapter={AdapterDateFns}>
							<DatePicker label="选择日期" format="dd/MM/yyyy" value={this.state.pickedDate} onChange={this.handleChangeDate} />
						</LocalizationProvider>
						<Typography>
							农历: {this.state.lunarYear}年 {this.state.lunarMonth}月 {this.state.lunarDay} (
							<span style={{ color: this.state.dayResultColor }}>{this.state.dayResult}</span>){" "}
						</Typography>
						<Typography>
							{this.state.lunarYear}年 {this.state.lunarMonthGanZhi}月 {this.state.lunarDayGanZhi}日
						</Typography>
						<TableContainer component={Paper}>
							<Table>
								<TableBody>
									<TableRow>
										<TableCell>11 - 1</TableCell>
										<TableCell>子时</TableCell>
										<TableCell sx={{ color: this.state.timeResult[0].color }}>{this.state.timeResult[0].value}</TableCell>
										<TableCell>午时</TableCell>
										<TableCell sx={{ color: this.state.timeResult[6].color }}>{this.state.timeResult[6].value}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>1 - 3</TableCell>
										<TableCell>丑时</TableCell>
										<TableCell sx={{ color: this.state.timeResult[1].color }}>{this.state.timeResult[1].value}</TableCell>
										<TableCell>未时</TableCell>
										<TableCell sx={{ color: this.state.timeResult[7].color }}>{this.state.timeResult[7].value}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>3 - 5</TableCell>
										<TableCell>寅时</TableCell>
										<TableCell sx={{ color: this.state.timeResult[2].color }}>{this.state.timeResult[2].value}</TableCell>
										<TableCell>申时</TableCell>
										<TableCell sx={{ color: this.state.timeResult[8].color }}>{this.state.timeResult[8].value}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>5 - 7</TableCell>
										<TableCell>卯时</TableCell>
										<TableCell sx={{ color: this.state.timeResult[3].color }}>{this.state.timeResult[3].value}</TableCell>
										<TableCell>酉时</TableCell>
										<TableCell sx={{ color: this.state.timeResult[9].color }}>{this.state.timeResult[9].value}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>7 - 9</TableCell>
										<TableCell>辰时</TableCell>
										<TableCell sx={{ color: this.state.timeResult[4].color }}>{this.state.timeResult[4].value}</TableCell>
										<TableCell>戌时</TableCell>
										<TableCell sx={{ color: this.state.timeResult[10].color }}>{this.state.timeResult[10].value}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>9 - 11</TableCell>
										<TableCell>巳时</TableCell>
										<TableCell sx={{ color: this.state.timeResult[5].color }}>{this.state.timeResult[5].value}</TableCell>
										<TableCell>亥时</TableCell>
										<TableCell sx={{ color: this.state.timeResult[11].color }}>{this.state.timeResult[11].value}</TableCell>
									</TableRow>
								</TableBody>
							</Table>
						</TableContainer>
					</Stack>
				</Paper>
				<Paper elevation={4} sx={{ p: 2, mb: 2 }}>
					<Stack direction="column" spacing={2}>
						<Typography>
							<b>大安</b>事事昌 求財在坤方 失物去不遠 宅舍保安康
							<br />
							行人身未動 病者主無妨 將軍回田野 仔細更推詳
						</Typography>
						<hr />
						<Typography>
							<b>留連</b>事難成 求謀日未明 官事凡宜緩 去者未回程
							<br />
							失物南方見 急討方心稱 更須防口舌 人口且平平
						</Typography>
						<hr />
						<Typography>
							<b>速喜</b>喜來臨 求財向南行 失物申未午 逢人路上尋
							<br />
							官事有福德 病者無禍侵 田宅六畜吉 行人有信音
						</Typography>
						<hr />
						<Typography>
							<b>赤口</b>主口舌 官非切宜防 失物速速討 行人有驚慌
							<br />
							六畜多作怪 病者出西方 更須防咀咒 誠恐染瘟疫
						</Typography>
						<hr />
						<Typography>
							<b>小吉</b>最吉昌 路上好商量 陰人來報喜 失物在坤方
							<br />
							行人即便至 交關甚是強 凡事皆和合 病者叩穹蒼
						</Typography>
						<hr />
						<Typography>
							<b>空亡</b>事不詳 陰人多乖張 求財無利益 行人有災殃
							<br />
							失物尋不見 官事有刑傷 病人逢暗鬼 解禳保安康
						</Typography>
					</Stack>
				</Paper>
			</Stack>
		);
	}
}

export default LiuRen;
