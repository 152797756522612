import { ThemeProvider as MuiThemeProvider, createTheme } from "@mui/material/styles";
import { createContext, useContext, useMemo } from "react";

import { useLocalStorage } from "./useLocalStorage";

const ThemeContext = createContext();
const ThemeUpdateContext = createContext();

export const ThemeProvider = ({ children }) => {
	const darkDataGridCss = `https://cdn3.devexpress.com/jslib/${process.env.REACT_APP_DEVEXTREME_VERSION}/css/dx.material.blue.dark.compact.css`;
	const lightDataGridCss = `https://cdn3.devexpress.com/jslib/${process.env.REACT_APP_DEVEXTREME_VERSION}/css/dx.material.blue.light.compact.css`;

	const [theme, setTheme] = useLocalStorage("theme", "light");

	function switchToDarkMode() {
		setTheme("dark");
	}

	function switchToLightMode() {
		setTheme("light");
	}

	const value = useMemo(
		() => ({
			theme,
			switchToDarkMode,
			switchToLightMode,
		}),
		[theme]
	);

	const muiTheme = createTheme({
		palette: { mode: theme },
	});

	return (
		<ThemeContext.Provider value={value}>
			<ThemeUpdateContext.Provider value={theme === "dark" ? switchToLightMode : switchToDarkMode}>
				<link rel="stylesheet" type="text/css" href={theme === "dark" ? darkDataGridCss : lightDataGridCss} />
				<MuiThemeProvider theme={muiTheme}>{children}</MuiThemeProvider>
			</ThemeUpdateContext.Provider>
		</ThemeContext.Provider>
	);
};

export const useTheme = () => {
	const context = useContext(ThemeContext);

	if (context === undefined) {
		throw new Error("useTheme must be used within an ThemeProvider");
	}

	return context;
};
