import img1 from "../images/taluomima/1.jpg";
import img2 from "../images/taluomima/2.jpg";
import img3 from "../images/taluomima/3.jpg";
import img4 from "../images/taluomima/4.jpg";
import img5 from "../images/taluomima/5.jpg";
import img6 from "../images/taluomima/6.jpg";
import img7 from "../images/taluomima/7.jpg";
import img8 from "../images/taluomima/8.jpg";
import img9 from "../images/taluomima/9.jpg";
import img00 from "../images/taluozhanbu/major_arcana/00-the-fool.jpg";
import img01 from "../images/taluozhanbu/major_arcana/01-the-magician.jpg";
import img02 from "../images/taluozhanbu/major_arcana/02-the-high-priestess.jpg";
import img03 from "../images/taluozhanbu/major_arcana/03-the-empress.jpg";
import img04 from "../images/taluozhanbu/major_arcana/04-the-emperor.jpg";
import img05 from "../images/taluozhanbu/major_arcana/05-the-hierophant.jpg";
import img06 from "../images/taluozhanbu/major_arcana/06-the-lovers.jpg";
import img07 from "../images/taluozhanbu/major_arcana/07-the-chariot.jpg";
import img08 from "../images/taluozhanbu/major_arcana/08-strength.jpg";
import img09 from "../images/taluozhanbu/major_arcana/09-the-hermit.jpg";
import img10 from "../images/taluozhanbu/major_arcana/10-wheel-of-fortune.jpg";
import img11 from "../images/taluozhanbu/major_arcana/11-justice.jpg";
import img12 from "../images/taluozhanbu/major_arcana/12-the-hanged-man.jpg";
import img13 from "../images/taluozhanbu/major_arcana/13-death.jpg";
import img14 from "../images/taluozhanbu/major_arcana/14-temperance.jpg";
import img15 from "../images/taluozhanbu/major_arcana/15-the-devil.jpg";
import img16 from "../images/taluozhanbu/major_arcana/16-the-tower.jpg";
import img17 from "../images/taluozhanbu/major_arcana/17-the-star.jpg";
import img18 from "../images/taluozhanbu/major_arcana/18-the-moon.jpg";
import img19 from "../images/taluozhanbu/major_arcana/19-the-sun.jpg";
import img20 from "../images/taluozhanbu/major_arcana/20-judgement.jpg";
import img21 from "../images/taluozhanbu/major_arcana/21-the-world.jpg";
import cupsImg01 from "../images/taluozhanbu/minor_arcana/cups/01-cups.jpg";
import cupsImg02 from "../images/taluozhanbu/minor_arcana/cups/02-cups.jpg";
import cupsImg03 from "../images/taluozhanbu/minor_arcana/cups/03-cups.jpg";
import cupsImg04 from "../images/taluozhanbu/minor_arcana/cups/04-cups.jpg";
import cupsImg05 from "../images/taluozhanbu/minor_arcana/cups/05-cups.jpg";
import cupsImg06 from "../images/taluozhanbu/minor_arcana/cups/06-cups.jpg";
import cupsImg07 from "../images/taluozhanbu/minor_arcana/cups/07-cups.jpg";
import cupsImg08 from "../images/taluozhanbu/minor_arcana/cups/08-cups.jpg";
import cupsImg09 from "../images/taluozhanbu/minor_arcana/cups/09-cups.jpg";
import cupsImg10 from "../images/taluozhanbu/minor_arcana/cups/10-cups.jpg";
import cupsImg11 from "../images/taluozhanbu/minor_arcana/cups/11-page-cups.jpg";
import cupsImg12 from "../images/taluozhanbu/minor_arcana/cups/12-knight-cups.jpg";
import cupsImg13 from "../images/taluozhanbu/minor_arcana/cups/13-queen-cups.jpg";
import cupsImg14 from "../images/taluozhanbu/minor_arcana/cups/14-king-cups.jpg";
import pentaclesImg01 from "../images/taluozhanbu/minor_arcana/pentacles/01-pentacles.jpg";
import pentaclesImg02 from "../images/taluozhanbu/minor_arcana/pentacles/02-pentacles.jpg";
import pentaclesImg03 from "../images/taluozhanbu/minor_arcana/pentacles/03-pentacles.jpg";
import pentaclesImg04 from "../images/taluozhanbu/minor_arcana/pentacles/04-pentacles.jpg";
import pentaclesImg05 from "../images/taluozhanbu/minor_arcana/pentacles/05-pentacles.jpg";
import pentaclesImg06 from "../images/taluozhanbu/minor_arcana/pentacles/06-pentacles.jpg";
import pentaclesImg07 from "../images/taluozhanbu/minor_arcana/pentacles/07-pentacles.jpg";
import pentaclesImg08 from "../images/taluozhanbu/minor_arcana/pentacles/08-pentacles.jpg";
import pentaclesImg09 from "../images/taluozhanbu/minor_arcana/pentacles/09-pentacles.jpg";
import pentaclesImg10 from "../images/taluozhanbu/minor_arcana/pentacles/10-pentacles.jpg";
import pentaclesImg11 from "../images/taluozhanbu/minor_arcana/pentacles/11-page-pentacles.jpg";
import pentaclesImg12 from "../images/taluozhanbu/minor_arcana/pentacles/12-knight-pentacles.jpg";
import pentaclesImg13 from "../images/taluozhanbu/minor_arcana/pentacles/13-queen-pentacles.jpg";
import pentaclesImg14 from "../images/taluozhanbu/minor_arcana/pentacles/14-king-pentacles.jpg";
import swordsImg01 from "../images/taluozhanbu/minor_arcana/swords/01-swords.jpg";
import swordsImg02 from "../images/taluozhanbu/minor_arcana/swords/02-swords.jpg";
import swordsImg03 from "../images/taluozhanbu/minor_arcana/swords/03-swords.jpg";
import swordsImg04 from "../images/taluozhanbu/minor_arcana/swords/04-swords.jpg";
import swordsImg05 from "../images/taluozhanbu/minor_arcana/swords/05-swords.jpg";
import swordsImg06 from "../images/taluozhanbu/minor_arcana/swords/06-swords.jpg";
import swordsImg07 from "../images/taluozhanbu/minor_arcana/swords/07-swords.jpg";
import swordsImg08 from "../images/taluozhanbu/minor_arcana/swords/08-swords.jpg";
import swordsImg09 from "../images/taluozhanbu/minor_arcana/swords/09-swords.jpg";
import swordsImg10 from "../images/taluozhanbu/minor_arcana/swords/10-swords.jpg";
import swordsImg11 from "../images/taluozhanbu/minor_arcana/swords/11-page-swords.jpg";
import swordsImg12 from "../images/taluozhanbu/minor_arcana/swords/12-knight-swords.jpg";
import swordsImg13 from "../images/taluozhanbu/minor_arcana/swords/13-queen-swords.jpg";
import swordsImg14 from "../images/taluozhanbu/minor_arcana/swords/14-king-swords.jpg";
import wandsImg01 from "../images/taluozhanbu/minor_arcana/wands/01-wands.jpg";
import wandsImg02 from "../images/taluozhanbu/minor_arcana/wands/02-wands.jpg";
import wandsImg03 from "../images/taluozhanbu/minor_arcana/wands/03-wands.jpg";
import wandsImg04 from "../images/taluozhanbu/minor_arcana/wands/04-wands.jpg";
import wandsImg05 from "../images/taluozhanbu/minor_arcana/wands/05-wands.jpg";
import wandsImg06 from "../images/taluozhanbu/minor_arcana/wands/06-wands.jpg";
import wandsImg07 from "../images/taluozhanbu/minor_arcana/wands/07-wands.jpg";
import wandsImg08 from "../images/taluozhanbu/minor_arcana/wands/08-wands.jpg";
import wandsImg09 from "../images/taluozhanbu/minor_arcana/wands/09-wands.jpg";
import wandsImg10 from "../images/taluozhanbu/minor_arcana/wands/10-wands.jpg";
import wandsImg11 from "../images/taluozhanbu/minor_arcana/wands/11-page-wands.jpg";
import wandsImg12 from "../images/taluozhanbu/minor_arcana/wands/12-knight-wands.jpg";
import wandsImg13 from "../images/taluozhanbu/minor_arcana/wands/13-queen-wands.jpg";
import wandsImg14 from "../images/taluozhanbu/minor_arcana/wands/14-king-wands.jpg";

// import img1 from "../images/taluomima/01-the-magician.jpg";

export const admin_roles = ["administrator", "sifu"];
export const advance_roles = ["administrator", "sifu", "tutor"];

export const monthMap = {
	1: "Jan",
	2: "Feb",
	3: "Mar",
	4: "Apr",
	5: "May",
	6: "Jun",
	7: "Jul",
	8: "Aug",
	9: "Sep",
	10: "Oct",
	11: "Nov",
	12: "Dec",
};

export const dateConfig = {
	date: {
		format: "DD",
		caption: "日",
		step: 1,
	},
	month: {
		format: (value) => monthMap[value.getMonth() + 1],
		caption: "月",
		step: 1,
	},
	year: {
		format: "YYYY",
		caption: "年",
		step: 1,
	},
};

export const dateTimeConfig = {
	date: {
		format: "DD",
		caption: "日",
		step: 1,
	},
	month: {
		format: (value) => monthMap[value.getMonth() + 1],
		caption: "月",
		step: 1,
	},
	year: {
		format: "YYYY",
		caption: "年",
		step: 1,
	},
	hour: {
		format: "hh",
		caption: "小時",
		step: 1,
	},
	minute: {
		format: "mm",
		caption: "分鐘",
		step: 1,
	},
};

export const taluomimaDesc = [
	{
		number: 1,
		name_zh: "魔术师",
		name: "The Magician",
		image: img1,
		title: "独立创意的潜能",
		gongke: "自信、学习放开心胸、无私分享与付出。",
		mubiao: "忠于自己，努力让梦想成真，方能独立。",
		goutong: "个人好处、尽量单纯、戏剧效果、非常在意价格，“为什么不问我？”。",
		qianneng: "领导、黑白分明、容易做决定、创意独特。",
		guihua: "独立作业的行业。",
		xiaohai: "独立、霸道",
	},
	{
		number: 2,
		name_zh: "女祭司",
		name: "The High Priestess",
		image: img2,
		title: "调和依赖的潜能",
		gongke: "两类：不快乐也依赖爱情事业，抱怨放弃。或建立方式，一切正轨运行。要学独立、主动。",
		mubiao: "乐于分享、与人紧密结合、自由批评改善。",
		goutong: "喜欢由你决定，需要解释，妥善协调，在意价格，除非提出好解释。“你决定啦!“。",
		qianneng: "眼光锐利、善于协调、懂搭配、喜心理知识。",
		guihua: "团队合作或研究的行业。",
		xiaohai: "感情丰富、依赖",
	},
	{
		number: 3,
		name_zh: "女皇",
		name: "The Empress",
		image: img3,
		title: "完美理想的潜能",
		gongke: "接受没有绝对完美、自我挑剔、接受意见。",
		mubiao: "发挥创意、追求目标。",
		goutong: "不要批评、形象至上、先让他得到他要的、价格高低不是问题，“我知道我要什么”。",
		qianneng: "好才华，沟通高手、带来治疗与激励、创造力强、追求美。",
		guihua: "运用沟通与创造力的行业。",
		xiaohai: "爱美、理想化",
	},
	{
		number: 4,
		name_zh: "皇帝",
		name: "The Emperor",
		image: img4,
		title: "安全稳定的潜能",
		gongke: "藉物质、爱情达到安全感或全力建造自己。接受改变、承担风险。",
		mubiao: "感情有安全感后，就完全转到爱情。",
		goutong: "追求安全，证据证明、专业、不要离题、非常在意价格，“先让我看到！”。",
		qianneng: "信用可靠、重复性高、解决问题、舒服安全。",
		guihua: "讲求规律的行业。",
		xiaohai: "稳定、固执",
	},
	{
		number: 5,
		name_zh: "教皇",
		name: "The Hierophant",
		image: img5,
		title: "轻松自由的潜能",
		gongke: "随遇而安、假无忧内不乐、工作爱情不能兼顾。",
		mubiao: "偷闲、喜谈判不愿经营、需要阅读、聆听。",
		goutong: "不喜欢说话被挑战、不要压逼、要解释清楚、在意价格，喜欢弹性。“看看怎样才决定”。",
		qianneng: "能与人沟通、善于交谈、关心自由平等政治。",
		guihua: "自由且可以自行设定工作的行业。",
		xiaohai: "开朗、善变",
	},
	{
		number: 6,
		name_zh: "恋人",
		name: "The Lovers",
		image: img6,
		title: "取舍责任的潜能",
		gongke: "学会选择、解决问题。",
		mubiao: "别过于热心付出、解决问题！",
		goutong: "要求肯定、感激、被需要、被尊重、价格不是问题！，”我没办法说不！“。",
		qianneng: "想象力丰富。",
		guihua: "与别人合作愉快、要别人觉得需要他的行业。",
		xiaohai: "敏感、受外影响",
	},
	{
		number: 7,
		name_zh: "战车",
		name: "The Chariot",
		image: img7,
		title: "真理追求的潜能",
		gongke: "觉得船到桥头自然直。",
		mubiao: "保持好奇心与求知欲。",
		goutong: "喜欢发问、质疑、受到威胁会逃避、价格不重要，品质才是。”真的吗？“。",
		qianneng: "好奇心重。",
		guihua: "需要思考的工作。",
		xiaohai: "好问独特、懒散",
	},
	{
		number: 8,
		name_zh: "力量",
		name: "Strength",
		image: img8,
		title: "延续成长的潜能",
		gongke: "许多梦想及欲望、尤其金钱。",
		mubiao: "会发生太想成功而不诚实、个人成长。",
		goutong: "个性刚强，不可强逼他同意你，在意价格，除非产品高贵或有利。”哪里有钱？“。",
		qianneng: "早一步看到潜能、不怕风险、为了成功不计一切投入。",
		guihua: "让他们根据自己直觉与理想自由行事。",
		xiaohai: "有正义感、封闭",
	},
	{
		number: 9,
		name_zh: "隐士",
		name: "The Hermit",
		image: img9,
		title: "慈善奉献的潜能",
		gongke: "无我奉献、满足别人而导致忧郁。有梦想。不能被利用、要取得平衡。",
		mubiao: "人道主义者改善别人的人生、带来希望。",
		goutong: "喜欢远大的梦想、不会担心价格，“没问题！”。",
		qianneng: "服务高手、让人感到温馨。",
		guihua: "共事愉快、不喜欢单打独斗的行业。",
		xiaohai: "爱发白日梦、不实际",
	},
];

export const xingZuoForNum3 = [
	{ name: "白羊", num: 1 },
	{ name: "摩羯", num: 1 },
	{ name: "金牛", num: 2 },
	{ name: "水瓶", num: 2 },
	{ name: "双子", num: 3 },
	{ name: "双鱼", num: 3 },
	{ name: "巨蟹", num: 4 },
	{ name: "狮子", num: 5 },
	{ name: "处女", num: 6 },
	{ name: "天秤", num: 7 },
	{ name: "天蝎", num: 8 },
	{ name: "射手", num: 9 },
];

export const allXingZuo = [
	{ value: 1, name_zh: "白羊座", name: "Aries", date: "3月21日 - 4月19日" },
	{ value: 2, name_zh: "金牛座", name: "Taurus", date: "4月20日 - 5月20日" },
	{ value: 3, name_zh: "双子座", name: "Gemini", date: "5月21日 - 6月21日" },
	{ value: 4, name_zh: "巨蟹座", name: "Cancer", date: "6月22日 - 7月22日" },
	{ value: 5, name_zh: "狮子座", name: "Leo", date: "7月23日 - 8月22日" },
	{ value: 6, name_zh: "处女座", name: "Virgo", date: "8月23日 - 9月22日" },
	{ value: 7, name_zh: "天秤座", name: "Libra", date: "9月23日 - 10月23日" },
	{ value: 8, name_zh: "天蝎座", name: "Scorpius", date: "10月24日 - 11月22日" },
	{ value: 9, name_zh: "射手座", name: "Sagittarius", date: "11月23日 - 12月21日" },
	{ value: 1, name_zh: "摩羯座", name: "Capricornus", date: "12月22日 - 1月19日" },
	{ value: 2, name_zh: "水瓶座", name: "Aquarius", date: "1月20日 - 2月18日" },
	{ value: 3, name_zh: "双鱼座", name: "Pisces", date: "2月19日 - 3月20日" },
];

export const majorArcanaImages = [
	img00,
	img01,
	img02,
	img03,
	img04,
	img05,
	img06,
	img07,
	img08,
	img09,
	img10,
	img11,
	img12,
	img13,
	img14,
	img15,
	img16,
	img17,
	img18,
	img19,
	img20,
	img21,
];

export const majorArcanaDesc = [
	{
		number: "00",
		name_zh: "愚者",
		name: "The Fool",
		image: img00,
		desc: "愚人正位表示在人事物中，潜意识认为应该这么做并做了某事。愚人逆位表示在人事物中，潜意识想做就做。正逆位的区别在于潜意识是否对人事物进行甄别。其特点是主观上没有想法，客观上没有目的。",
		upright:
			"新的起点，不能再等了，自由，好奇，本能，无意识，现在正是好时机，开始，开端，起源，清白，无辜，无罪，天真，纯真，单纯，自发性，自然，自由精神，不拘一格",
		reverse:
			"情绪低落，时机不对，陷入停顿，遭受失败，漫无目的的四处流浪，行为散漫，愚昧的行动退缩，自负，固执，轻率，鲁莽，大意，不顾一切，粗心大意，冒险，承担风险",
	},
	{
		number: "01",
		name_zh: "魔术师",
		name: "The Magician",
		image: img01,
		desc: "魔术师表示发生的人事物具有需要长时间的准备，但是表现的时间却很短，这是人事物所存在重要的特点。关键含义之一有正位表示准备充分，逆位表示不做准备或准备不充分，其他的含义在此基础上去拓展去思考。",
		upright:
			"受好奇心驱使展开调查研究，具有独创性，具备发展潜力，展开新的计划并获得成功，幸福的开始，显现，表现，显化，表征，机智，足智多谋，智慧，丰富资源，控制力，影响力，操纵力，统治，政权，能力，机会，驱动，推动，迅速移动，快速前进，灵感",
		reverse:
			"犹豫未定，因学艺不精而败北，看待事物过于消极，易受骗上当，遇事言而不行，操作，操纵，操控，推拿，处理，计划不周，人算不如天算，百密一疏，怀才不遇，有潜力",
	},
	{
		number: "02",
		name_zh: "女祭司",
		name: "The High Priestess",
		image: img02,
		desc: "这是描述有女性内在特质的人事物，这里并不一定指的是人，也可以形容在处理问题上的风格。正位像是大家闺秀，逆位有些刁蛮任性。",
		upright:
			"有知己的，文静的，知性的，理性的，富有研究精神，具备敏锐的洞察力，有正确的时机或人将出现的预感，直觉，第六感，天启，启示，天象，圣女，气质，女神，潜意识",
		reverse:
			"粗心大意，易紧张，神经质，意气用事，因精神压抑以致歇斯底里，缺乏理性思维能力，秘密，机密，诀窍，秘诀，奥秘，奥妙，没感觉，撤走，收回，取回，不再参加，退出，提款，取款，寂静，安全，无声，沉默，缄默，默不作声，缄口不谈，拒绝回答，压制，冷场",
	},
	{
		number: "03",
		name_zh: "女皇",
		name: "The Empress",
		image: img03,
		desc: "这是描述有女性外在特质的人事物，并不一定单指人，正位表示具有母性光辉的独立女性的品质，逆位表示不具备传统道德观念的非独立女性，在事物上可以代表人的行事风格。",
		upright:
			"优雅，富贵，女权主义，女性化，女人味，女性特质，美人，美物，好榜样，典型的例子，自然界，自在坦然，天性，本性，性格，养育，养护，培养，扶持，帮助，支持，滋长，助长，量产，丰盛，充裕，丰富",
		reverse: "困惑，轻浮，损失，缺乏上进心，爱慕虚荣，无灵感，依靠，依赖，依存，上瘾，性关系，炮友，堕落，附庸，跟班，被动",
	},
	{
		number: "04",
		name_zh: "皇帝",
		name: "The Emperor",
		image: img04,
		desc: "这是描述有男性内在特质的人事物，这里并不一定指的是人，也可以形容在处理问题上的风格。正位像是英明神武，逆位有些昏庸无能。",
		upright:
			"魄力十足，万众瞩目，男性化，基本功扎实，富有责任感，充满阳刚之气，出众的领导才能，权力，威权，地位，职权，批准，授权，机构，大型组织，企业，旅馆，当权派，权势集团，权威人士，建立，创立，确立，结构，构造，精心组织，周密安排，形成体系，系统安排，父亲角色",
		reverse:
			"幼稚，寡欲，不负责任，率性妄为，蛮横霸道，缺乏治理能力，统治，支配，主宰，占有欲强，没有训练，没有训导，无纪律，风纪差，没有训练方法，行为准则，符合准则的行为，无自制力，不遵守纪律，不接受惩罚，没有处罚，自我控制能力差，没有严格要求自己，僵硬，缺乏灵活性，呆滞，较真",
	},
	{
		number: "05",
		name_zh: "教皇",
		name: "The Hierophant",
		image: img05,
		desc: "教育的最高境界是润物细无声。这是描述有男性外在特质的人事物，并不一定单指人，正位表示具有智慧胸怀的成熟男性的品质，逆位表示心胸狭隘小黠大痴的男性品质，在事物上可以代表人的行事风格。",
		upright:
			"值得信赖的，得到援助的，良好的建言者，有贡献的，心胸宽广的，有宗教情绪的，精神内在，智慧，才智，精明，明智，知识，学问，修士，修女，出家人，宗教信仰，遵从，遵守，传统，慈善机构",
		reverse: "失去信赖，多管闲事，过于依靠他人，心胸狭隘，被强迫倾销，孤立无援，个人信仰，看法，想法，自由，质疑权威，挑战现况，改变现在",
	},
	{
		number: "06",
		name_zh: "恋人",
		name: "The Lovers",
		image: img06,
		desc: "人类自有阶级以来，纯粹的恋爱关系世间鲜有，正位表示这段关系不受世俗世界的阶级关系影响，逆位表示这段关系受到世俗世界划分的阶级影响。影响的关系属于交付性的关系，因为你，所以我，也称缘。例：当你成为妻子的那一刻，你的丈夫也就出现了。单独的你不可能成为妻子或丈夫。这种你与客体的关系同时产生也同时消失。",
		upright:
			"幸福的相遇，同心协力，情投意合，感情丰富，前途光明，坠入爱河，整理思绪，爱，热爱，慈爱，爱情，恋爱，喜好，喜爱，喜欢，很愿意，融洽，和睦，和声，和谐，协调，关系，联系，情爱关系，性爱关系，价值观，价值取向，选择，挑选，抉择，选择权，入选者，被选中",
		reverse:
			"伤离别，关系结束，无助，遭遇险阻，转移目标，爱好没有办法持久，无精打采，表里不一，易受诱惑，自爱，自恋，不协调，不和谐，不一致，失衡，不平衡，不公平，价值观不一致，价值观错位",
	},
	{
		number: "07",
		name_zh: "战车",
		name: "The Chariot",
		image: img07,
		desc: "判断的题型是非对即错，战车所表示并不是判断题，表示的是选择题，正位表示任何的选择结果都不错，逆位表示无论选择哪个，结果都不太理想。",
		upright:
			"高效率，把握先机，以积极的心态面对事物，获得胜利，取得驾照，搬家，指挥，控制，掌管，支配，限制，限定，阻止蔓延，意志，成功，胜利，发财，成名，成功的人，必须要做，务必完成，决心，果断，坚定，决定，确定，规定，查明，测定，计算",
		reverse:
			"失控，发生意外，胡作非为，失败，止步不前，固执己见，注意力散漫，遇突发事件而不知所措，自律，自我约束，律己，自律能力，反对，反抗，对抗，对手，敌人，竞争者，没有方向，没有领导，反对派，在野党",
	},
	{
		number: "08",
		name_zh: "力量",
		name: "Strength",
		image: img08,
		desc: "这里的力量并非自己的力量，而是自身所控制的能量，在人事物中是主体对客体的掌握与否，在关系中是否占据主导地位。在行事风格中要以柔克刚，任何想通过蛮力强行推动事物的发展都会失败。",
		upright:
			"精神抖擞，不屈不挠，意志坚定，潜力十足，胆识过人，信念坚定，体力，力气，力量，强度，毅力，坚强决心，意志力量，勇气，勇敢，无畏，胆量，说服力，劝说，影响力，同情，怜悯",
		reverse:
			"精力不足，急躁不安，丧失信心，忍耐力不足，自私自大，内功，内在力量，自我怀疑，自我否定，不自信，精神不振，能力低下，兽性，原欲，靠本能处理问题，做事凭感觉",
	},
	{
		number: "09",
		name_zh: "隐士",
		name: "The Hermit",
		image: img09,
		desc: "成为隐士之前，一定是入世的，而且经历了世俗世界的种种，隐士描述的主体主动的远离世俗，出世在清静的地方对自己所经历人事物的反思，处在去其糟粕，取其精华的反思状态。正位表示对过往经历的思考使自身的优秀品质得到了提高，逆位表示受过往经历的影响，自己逐渐变成了伤害过自己的人。",
		upright:
			"自信，理智，脚踏实地，不喜形于色，中肯的建议，坚持不懈，沉着冷静，闭门静思，养精蓄锐，深刻反省，自我检查，自我纠正，自省，孤独，独自，独居，独立，单独，单身，孤苦伶仃，无依无靠，孤独，寂寞，内在引导行为，精神引导行为，谋定而后动",
		reverse: "口无遮拦，俗气，神经质，为人刻薄，行事不够理智，多疑，隔离状态，孤立状态，撤走，收回，取回，不再参加，退出组织",
	},
	{
		number: "10",
		name_zh: "命运之轮",
		name: "Wheel of Fortune",
		image: img10,
		desc: "人事物不以个人意志为转移，既充满了偶然性，同时又拥有必然性。",
		upright:
			"把控时机，机会降临，转变期，意外的收获，当机立断，幸运降临，恢复原状，好运，因果报应，善恶有报，生命周期，生活周期，命运，天理，天命，天数，主宰事物的力量，命运之神，转折点",
		reverse:
			"判定错误，情绪低落，运气不佳，左右为难，稍安毋躁，一时的幸福，爽约，倒霉，霉运，真倒霉，运气不好，拒绝改变，反对改变，抗拒变革的心理，抗拒改革，变革阻力，循环终止，循环断裂",
	},
	{
		number: "11",
		name_zh: "正义",
		name: "Justice",
		image: img11,
		desc: "正义是有前提条件的，没有前提条件的正义不是正义。例如法律面前人人平等，前提是在法律面前。迟到的正义根本不是正义，只有及时的正义才是正义，前提是及时的正义。正位表示在判断人事物是否正义之前引入了前提条件，逆位表示在判断人事物是否正义之前不引入前提条件。",
		upright:
			"老实，公证，为他人调解争端，维系平衡，兼顾事业与家庭（学业与恋情），爱好和平，公平，公正，公道，合理，公平合理，司法制度，法律制裁，审判，真相，实情，事实，真实情况，真实，真实性，真理，因果关系",
		reverse: "不公平，有始无终，以自己的意见去断定，事事不能两全，不均衡，不公平性，显失公平，不公，没有责任感，没有义务，不诚实，欺诈",
	},
	{
		number: "12",
		name_zh: "倒吊人",
		name: "The Hanged Man",
		image: img12,
		desc: "正位今日之恶是明日之善，坏心办好事。逆位今日之善是明日之恶，好心办坏事。体现倒吊人思想的话语有以战争求和平，则和平存；以妥协求和平，则和平亡。思考一下现金扶贫是善是恶？辩证的看待问题是倒吊人所表示核心含义。",
		upright:
			"进退两难，牺牲，因祸得福，遭遇困难磨练以致心智成熟，以不同的角度看待事物，暂停，停顿，延长记号，暂停键，投降，被迫放弃，交出，屈服，屈从，交出，解雇，解聘，被动离职，新视野，新观点，新视角",
		reverse:
			"希望破灭，因私欲而受罚，做无用功，没有必要的付出，自我毁灭，缺乏实施能力，事如泡影，延时，延迟，延误，误点，反对，抵制，抗拒，抵抗，反抗，熄火，抛锚，故意拖延，拖住，拉锯战，无决断力，优柔寡断，自私自利",
	},
	{
		number: "13",
		name_zh: "死神",
		name: "Death",
		image: img13,
		desc: "死神表示面对绝境采取的不同态度，正位表示人事物的终结，到此为止。逆位表示人事物的终结，从头再来。",
		upright:
			"失败，恶运连连，走进死胡同，绝望，遗失，没有办法逃离，无疾而终，努力成为泡影，有苦说不出，结尾，结局，结束，终结，最后部分，词尾，改变，变化，变更，变革，替代，转变，变迁，更迭，新阵代谢，替代物，过渡",
		reverse:
			"改变计划，变更形象，东山再起，走出低谷，脱胎换骨，勇于开拓，精神抖擞，拒绝改变，反对变革，不想结束，强行续命，内心净化，念头通达，境界提升",
	},
	{
		number: "14",
		name_zh: "节制",
		name: "Temperance",
		image: img14,
		desc: "天之道，其犹张弓欤？高者抑之，下者举之；有馀者损之，不足者补之。天之道，损有馀而补不足。正位表示在人事物中程度把握的洽到好处，逆位表示在人事物中做的过头或着做的不够。",
		upright:
			"进展顺利，为他人排解争端，整理思路与情绪，净化心灵，反复调研找寻关键所在，关注自我需求的同时兼顾他人意愿，勤于节俭，相互协助，保持平衡，立稳，相抵，抵消，同等重视，适中，合理，耐心，忍耐，毅力，坚忍，恒心，意图，目的，用途，目标，情势的需要，重要意义，有价值的意义，有意，打算，企图，决意",
		reverse:
			"缺乏自我调节的能力，开销过大，遗忘初衷，精力消耗殆尽，毫无节制，烦闷的说教，失衡，不平衡，心态失衡，不均衡，失调，超过，过度，过分，过多的量，超过的量，超额，额外，附加，自我修复，自愈，自疗，自我愈合，校验，调整",
	},
	{
		number: "15",
		name_zh: "恶魔",
		name: "The Devil",
		image: img15,
		desc: "如果这件事不符合客观规律，那么必然符合某些人的利益。正位表示看着相关的人事物是非常美好的，以看到的美好继而采取行动。逆位表示看着相关的人事物是非常美好的，但摆脱美好的诱惑而理智的行动。",
		upright:
			"受到拘束深感不安，卑躬屈膝，沦为欲望的俘虏，颓废的生活，隐瞒事实真相，遭遇诱惑的陷阱，固执己见，贪婪成性，自我投影，依恋，爱慕，信念，信仰，忠诚，拥护，上瘾，入迷，鬼迷心窍，限制，约束，性欲，性行为，约炮",
		reverse:
			"摆脱不良诱惑，与酒肉朋友断交，抛弃欲望，有走出困境的机会，摆脱束缚，重获自由，解除负担，能够表达自己的意志，释放，探索思想，贤者时间，思考内心中的阴暗，超然，超脱，冷漠，公正，客观，独立",
	},
	{
		number: "16",
		name_zh: "破塔",
		name: "The Tower",
		image: img16,
		desc: "引发内部剧烈动荡的原因是不可预料并是在系统运行体系之外，这个原因不以系统内部成员的意志为转移。所有处在剧烈动荡的人事物都无法改变引发内部动荡的人事物。",
		upright:
			"麻烦不断，遭遇逆境，遭受打击，原有信念崩溃，遭遇突发事件，多管闲事，与人发生纷争，骤变，剧变，激变，动乱，动荡，突然，没有征兆，混乱，乱套，启示，内幕显现，揭露秘密，苏醒，醒悟，觉醒，开悟，感到",
		reverse:
			"悬崖勒马，遭遇口舌之灾，发生内部纠纷，风暴前的寂静，个人转变，害怕转变，避灾，逃难，远离是非之地，远离是非之人，不立危墙之下，择良木而栖，情况紧急",
	},
	{
		number: "17",
		name_zh: "星星",
		name: "The Star",
		image: img17,
		desc: "表示人事物发展的阶段处在初期。正逆位的区别在于是否能度过初期。",
		upright:
			"愿望得以实现，充满创造力，萌发灵感，理想主义者，前途光明，希望，期望，希望的东西，期望的事情，被寄予希望的，信任，相信，信心，意图，目的，用途，目标，情势的需要，意义重大，恢复，更新，重新开始，续约，续订，改进，复兴，振兴，灵性",
		reverse:
			"缺乏想象力，幻想幻灭，好高骛远，错失良机，固执己见，理想与现实没有办法兼顾，缺乏信心，信任不足，不可相信，绝望，失去希望，失去信心，切断，舍弃",
	},
	{
		number: "18",
		name_zh: "月亮",
		name: "The Moon",
		image: img18,
		desc: "表示人事物发展的阶段处在中期。正逆位的区别在于是否能度过中期，度过则有相应的奖励并进入后期，没有度过则中止。",
		upright:
			"幻想，谎言，不安，动摇，迷惑，暧昧不清，重伤他人或为人中伤，顾及两边，优柔寡断，受人感化，没有办法发挥潜能，幻想，错觉，意淫，害怕，畏惧，惧怕，担心，担忧，恐怕，焦虑，忧虑，渴望，潜意识，直觉",
		reverse: "状况逐渐好转，时间可以冲淡一切，疑虑渐消，幸免遇害，排解恐惧，释放压力，镇定，心里没底，想不通，猜不透，看不清",
	},
	{
		number: "19",
		name_zh: "太阳",
		name: "The Sun",
		image: img19,
		desc: "表示人事物发展的阶段处在后期。正逆位的区别在于是否能度过后期，度过则收获相应的奖励并中止，没有度过则中止。",
		upright:
			"远景明朗，活力充沛，欲望强烈，得贵人相助，阳光普照，积极，确实如此，享乐，乐趣，快乐，嬉戏，逗乐，玩笑，温暖，热情，友情，成功，胜利，发财，成名，活力，激情",
		reverse:
			"意志消沉，约会取消，情绪低落，事事没有办法持久，性格不开朗，感到无助，生活不稳定，孩子气，情绪低落，消极，慵懒，冷漠，无所谓，过度乐观",
	},
	{
		number: "20",
		name_zh: "审判",
		name: "Judgement",
		image: img20,
		desc: "审判描述了人事物已经处在完结的阶段，这个阶段是对已经发生的人事物进行总结，总结是客观的，并非主观上感受。其特点是不可对已经发生的人事物进行更改。",
		upright:
			"复活的喜悦，命运好转，作品公然发表，得到好消息，信仰宗教，自信，判断，识别，看法，意见，评价，判决，裁决，新生，复活，复兴，再生，心声，赦罪，赦免，无罪",
		reverse:
			"一蹶不振，尚未开始便已结束，犹豫未定，行为不妥，生活散漫，良心发现内心颇感罪恶，自我怀疑，没有把握，不确实，良心受到谴责，不予理睬，佯装未见，坏消息",
	},
	{
		number: "21",
		name_zh: "世界",
		name: "The World",
		image: img21,
		desc: "世界描述了人事物已经处在完结的阶段，这个阶段是对已经发生的人事物进行总结，总结是主观的感受。其特点是不可对已经发生的人事物进行更改。",
		upright:
			"愿望达成，精神高昂，幸福时光，达到巅峰，到达目的地，获得成功，完成，结束，完成交易，完成交割，结合，整合，一体化，混合，融合，成就，成绩，才艺，技艺，专长，完成，长途行走，旅行，游历，转送，传播，不变质，长途运输",
		reverse:
			"没有办法全身心地投入，杞人忧天，事事不顺，不安现状，情绪低迷，思维颇显幼稚，寻求自我完整，晚点，迟到，延误，耽误，延迟，延期，推迟，走捷径，跳过，未完成，失败",
	},
];

export const cupsImages = [
	cupsImg01,
	cupsImg02,
	cupsImg03,
	cupsImg04,
	cupsImg05,
	cupsImg06,
	cupsImg07,
	cupsImg08,
	cupsImg09,
	cupsImg10,
	cupsImg11,
	cupsImg12,
	cupsImg13,
	cupsImg14,
];

export const pentaclesImages = [
	pentaclesImg01,
	pentaclesImg02,
	pentaclesImg03,
	pentaclesImg04,
	pentaclesImg05,
	pentaclesImg06,
	pentaclesImg07,
	pentaclesImg08,
	pentaclesImg09,
	pentaclesImg10,
	pentaclesImg11,
	pentaclesImg12,
	pentaclesImg13,
	pentaclesImg14,
];

export const swordsImages = [
	swordsImg01,
	swordsImg02,
	swordsImg03,
	swordsImg04,
	swordsImg05,
	swordsImg06,
	swordsImg07,
	swordsImg08,
	swordsImg09,
	swordsImg10,
	swordsImg11,
	swordsImg12,
	swordsImg13,
	swordsImg14,
];

export const wandsImages = [
	wandsImg01,
	wandsImg02,
	wandsImg03,
	wandsImg04,
	wandsImg05,
	wandsImg06,
	wandsImg07,
	wandsImg08,
	wandsImg09,
	wandsImg10,
	wandsImg11,
	wandsImg12,
	wandsImg13,
	wandsImg14,
];
