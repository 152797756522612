import { AppBar, Stack, Toolbar, Typography } from "@mui/material";
import { useNavigate } from "react-router";

import { useAuth } from "../hooks/useAuth";
import { useTheme } from "../hooks/useTheme";
import { admin_roles } from "../utils/constant";
import { MuiButton } from "./common";
import { AccountIcon, AdminPanelIcon, DarkModeIcon, LightModeIcon, LogoutIcon } from "./Icon";

function Header() {
	const { theme, switchToDarkMode, switchToLightMode } = useTheme();
	const { user, logout } = useAuth();
	const navigate = useNavigate();

	const toggleTheme = () => {
		theme === "light" ? switchToDarkMode() : switchToLightMode();
	};

	const handleLogout = () => logout();

	return (
		<AppBar position="fixed" color="inherit">
			<Toolbar variant="dense">
				<Stack direction="column" alignItems="flex-start" sx={{ flexGrow: 1 }}>
					<Typography variant="h6" component="h6" sx={{ "&:hover": { cursor: "pointer" } }} onClick={() => navigate("/dashboard")}>
						天书
					</Typography>
				</Stack>
				{user && admin_roles.some((role) => user.role.includes(role)) && (
					<MuiButton tooltip="Admin Panel" mode="icon" content={<AdminPanelIcon />} onClick={() => navigate("/adminpanel")} />
				)}
				{user && <MuiButton tooltip="User Panel" mode="icon" content={<AccountIcon />} onClick={() => navigate("/userpanel")} />}
				<MuiButton
					tooltip={theme === "dark" ? "Light Mode" : "Dark Mode"}
					mode="icon"
					content={theme === "dark" ? <LightModeIcon /> : <DarkModeIcon />}
					onClick={toggleTheme}
					spanStyle={{ width: "fit-content" }}
				/>
				{user && <MuiButton tooltip="Logout" mode="icon" content={<LogoutIcon />} onClick={handleLogout} />}
			</Toolbar>
		</AppBar>
	);
}

export default Header;
