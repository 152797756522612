import { LoadingButton } from "@mui/lab";
import { Alert, Autocomplete, CircularProgress, IconButton, Snackbar, Stack, TextField, Tooltip, Typography, Zoom } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import MaterialUiPhoneNumber from "material-ui-phone-number";
import { useEffect, useRef } from "react";

import { QuestionMarkIcon } from "./Icon";

export function RedAsterisk() {
	return <span style={{ color: "red", fontWeight: "bold" }}>*</span>;
}

export function LoadingSpinner({ className = "", size = 16, text = true }) {
	return (
		<div className={className}>
			{text ? (
				<>
					<CircularProgress size={size} sx={{ marginRight: "5px" }} />
					Loading...
				</>
			) : (
				<CircularProgress size={size} />
			)}
		</div>
	);
}

export function SnackBar(props) {
	return (
		<Snackbar
			anchorOrigin={{ vertical: props.snackbar.vertical, horizontal: props.snackbar.horizontal }}
			open={props.snackbar.open}
			autoHideDuration={1500}
			onClose={props.handleCloseSnackbar}
			key={props.snackbar.vertical + props.snackbar.horizontal}
		>
			<Alert severity={props.snackbar.type} variant="filled" style={{ color: "white", fontSize: "1.25rem" }}>
				{props.snackbar.message}
			</Alert>
		</Snackbar>
	);
}

export function MuiButton({
	tooltip = null,
	loading = false,
	variant,
	size = "medium",
	color = "primary",
	disabled = false,
	onClick,
	content = <QuestionMarkIcon />,
	mode,
	spanStyle = {},
	buttonStyle = {},
}) {
	return tooltip !== null ? (
		<Tooltip title={tooltip} TransitionComponent={Zoom} arrow>
			<span style={spanStyle}>
				{mode === "icon" ? (
					<IconButton disabled={disabled} onClick={onClick}>
						{content}
					</IconButton>
				) : (
					<LoadingButton
						loading={loading}
						variant={variant}
						size={size}
						color={color}
						disabled={disabled}
						onClick={onClick}
						sx={buttonStyle}
					>
						{content}
					</LoadingButton>
				)}
			</span>
		</Tooltip>
	) : (
		<>
			{mode === "icon" ? (
				<IconButton disabled={disabled} onClick={onClick}>
					{content}
				</IconButton>
			) : (
				<LoadingButton loading={loading} variant={variant} size={size} color={color} disabled={disabled} onClick={onClick} sx={buttonStyle}>
					{content}
				</LoadingButton>
			)}
		</>
	);
}

export function AutoComplete({
	options,
	value,
	onChange,
	optionLabel = "Label",
	optionValue = "Value",
	name,
	label,
	error = false,
	required = false,
	width = "100%",
	getOptionLabel = null,
}) {
	return options === undefined ? null : JSON.stringify(options) === JSON.stringify([]) ? (
		<Autocomplete
			options={options}
			value={null}
			renderInput={(params) => <TextField {...params} label={label} error={error} autoComplete="new-password" required={required} />}
			noOptionsText="No Option"
			sx={{ width: width }}
			size="small"
		/>
	) : (typeof options === "object" && options.error) || typeof options[0] === "object" ? (
		<Autocomplete
			clearOnEscape
			options={options.error ? [{ Label: options.error, Value: "" }] : options}
			getOptionLabel={getOptionLabel !== null ? getOptionLabel : (option) => (option.Label ? option.Label || "" : option[optionLabel] || "")}
			isOptionEqualToValue={(option, value) => (value !== null ? option[optionValue] === value[optionValue] : null)}
			value={value !== null && !options.error ? options.find((option) => option[optionValue] === value) : null}
			onChange={(event, newValue) => {
				onChange({ target: { name: name, value: newValue !== null ? newValue[optionValue] : null } });
			}}
			renderInput={(params) => <TextField {...params} label={label} error={error} autoComplete="new-password" required={required} />}
			noOptionsText="No Option"
			sx={{ width: width }}
			size="small"
		/>
	) : typeof options[0] === "string" ? (
		<Autocomplete
			clearOnEscape
			options={options}
			value={value}
			onChange={
				onChange?.name && onChange.name.length > 0 ? onChange : (event, newValue) => onChange({ target: { name: name, value: newValue } })
			}
			renderInput={(params) => <TextField {...params} label={label} error={error} autoComplete="new-password" required={required} />}
			noOptionsText="No Option"
			sx={{ width: width }}
			size="small"
		/>
	) : null;
}

export function MuiPhoneNumber({ size = "small", variant = "filled", defaultCountry = "my", value = "", error = false, onChange }) {
	return (
		<MaterialUiPhoneNumber
			fullWidth
			size={size}
			variant={variant}
			defaultCountry={defaultCountry}
			className="phone-input"
			value={value}
			error={error}
			onChange={onChange}
		/>
	);
}

export function MuiDatePicker({ id, name, minDate = null, value, onChange, error = false, required = false }) {
	return (
		<LocalizationProvider dateAdapter={AdapterDateFns}>
			{minDate !== null ? (
				<DatePicker
					id={id}
					name={name}
					inputFormat="dd/MM/yyyy"
					minDate={minDate}
					value={value ? value : null}
					onChange={onChange}
					renderInput={(params) => (
						<TextField {...params} hiddenLabel variant="filled" size="small" fullWidth={true} error={error} required={required} />
					)}
				/>
			) : (
				<DatePicker
					id={id}
					name={name}
					inputFormat="dd/MM/yyyy"
					value={value ? value : null}
					onChange={onChange}
					renderInput={(params) => (
						<TextField {...params} hiddenLabel variant="filled" size="small" fullWidth={true} error={error} required={required} />
					)}
				/>
			)}
		</LocalizationProvider>
	);
}

function useListener(ref, eventName, handler) {
	useEffect(() => {
		if (ref.current) {
			const element = ref.current;
			element.addEventListener(eventName, handler);
			return () => element.removeEventListener(eventName, handler);
		}
	}, [eventName, handler, ref]);
}

export function DuetDatePicker({ onChange, onFocus, onBlur, onOpen, onClose, dateAdapter, localization, ...props }) {
	const ref = useRef(null);

	useListener(ref, "duetChange", onChange);
	useListener(ref, "duetFocus", onFocus);
	useListener(ref, "duetBlur", onBlur);
	useListener(ref, "duetOpen", onOpen);
	useListener(ref, "duetClose", onClose);

	useEffect(() => {
		ref.current.localization = localization;
		ref.current.dateAdapter = dateAdapter;
	}, [localization, dateAdapter]);

	return <duet-date-picker ref={ref} {...props}></duet-date-picker>;
}

export function CardDetails({ title, gongke, mubiao, goutong, qianneng, guihua, xiaohai }) {
	return (
		<Stack direction="column" spacing={2}>
			<Typography variant="h5" align="center" sx={{ fontWeight: "bold" }}>
				{title}
			</Typography>
			<Stack direction="column">
				<Typography sx={{ fontWeight: "bold" }}>人生要做的功课：</Typography>
				<Typography>{gongke}</Typography>
			</Stack>
			<Stack direction="column">
				<Typography sx={{ fontWeight: "bold" }}>人生要设的目标：</Typography>
				<Typography>{mubiao}</Typography>
			</Stack>
			<Stack direction="column">
				<Typography sx={{ fontWeight: "bold" }}>沟通技巧：</Typography>
				<Typography>{goutong}</Typography>
			</Stack>
			<Stack direction="column">
				<Typography sx={{ fontWeight: "bold" }}>天生潜能：</Typography>
				<Typography>{qianneng}</Typography>
			</Stack>
			<Stack direction="column">
				<Typography sx={{ fontWeight: "bold" }}>生涯规划：</Typography>
				<Typography>{guihua}</Typography>
			</Stack>
			<Stack direction="column">
				<Typography sx={{ fontWeight: "bold" }}>小孩：</Typography>
				<Typography>{xiaohai}</Typography>
			</Stack>
		</Stack>
	);
}
