import { Button, Paper, Stack, TextField, Typography } from "@mui/material";
import $ from "jquery";
import React, { Component } from "react";

class TotalNumbers extends Component {
	state = {
		numbers: "",
		totalCount: 0,
	};

	onChangeNumbers = async (e) => {
		this.setState({ ...this.state, numbers: e.target.value }, function () {
			var total = 0;
			for (let i = 0; i < e.target.value.length; i++) {
				total += parseInt(e.target.value[i]);
			}

			while (total >= 10) {
				let temp = 0;
				for (let j = 0; j < String(total).length; j++) {
					temp += parseInt(String(total)[j]);
				}
				total = temp;
			}

			this.setState({ ...this.state, totalCount: total });
		});
	};

	clearNumbersAndTotal = () => {
		this.setState({ ...this.state, numbers: "", totalCount: 0 }, function () {
			$("#numbers").focus();
		});
	};

	render() {
		return (
			<Paper elevation={4} sx={{ p: 2 }}>
				<Stack direction="row" alignItems="center" justifyContent="space-between" spacing={3}>
					<TextField
						hiddenLabel
						variant="filled"
						size="small"
						id="numbers"
						type="number"
						onChange={this.onChangeNumbers}
						value={this.state.numbers}
						sx={{ width: "70%" }}
					/>
					<Typography>{this.state.totalCount}</Typography>
					<Button variant="outlined" onClick={this.clearNumbersAndTotal}>
						清空
					</Button>
				</Stack>
			</Paper>
		);
	}
}

export default TotalNumbers;
