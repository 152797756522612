import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	Paper,
	Stack,
	TextField,
	Tooltip,
	Typography,
	Zoom,
} from "@mui/material";
import { Solar } from "lunar-javascript";
import { useState } from "react";
import DatePicker from "react-mobile-datepicker";

import { AutoComplete, MuiButton, MuiPhoneNumber, RedAsterisk } from "../../components/common";
import { CloseIcon, InfoIcon, RotateIcon } from "../../components/Icon";
import { useAuth } from "../../hooks/useAuth";
import { useTheme } from "../../hooks/useTheme";
import { displayDate } from "../../utils";
import { dateConfig, majorArcanaDesc, majorArcanaImages } from "../../utils/constant";

function TaLuoZhanBu() {
	const { theme } = useTheme();
	const { user } = useAuth();

	const [state, setState] = useState({
		name: "",
		nameZh: "",
		email: "",
		contact: "",
		dob: new Date(),
		occupation: "",
		address: "",
		divine_date: new Date(),
		question: "",
		answer: "",
		paizhen: null,
		nameError: false,
		nameZhError: false,
		emailError: false,
		contactError: false,
		questionError: false,
		answerError: false,
		paizhenError: false,
		openDobModal: false,
		openDivineDateModal: false,
		openCardDeckDialog: false,
		openCardInfoDialog: false,
		cardIndex: null,
		selectingCardIndex: null,
		isSubmitting: false,
	});

	const emptyBoxSx = {
		width: { xs: "23vw", sm: "20vw", md: "18vw", lg: "15vw", xl: "12vw" },
		aspectRatio: 607 / 1000,
		backgroundColor: theme === "light" ? "#f0f0f0" : "#393939",
		borderRadius: "0.75rem",
		"&:hover": {
			cursor: "pointer",
		},
	};

	const tarotImgSx = {
		width: "100%",
		cursor: "pointer",
		border: theme === "light" ? "1px solid #b0b0b0" : "transparent",
		borderRadius: "0.75rem",
		transition: "rotate 0.5s",
	};

	const [tarotCards, setTarotCards] = useState(null);
	const [tarotCardsFlip, setTarotCardsFlip] = useState(null);

	const paizhenOptions = [
		{ name: "过去，现在，未来", value: "past_present_future" },
		{ name: "三石阵", value: "three_stone" },
		{ name: "是与否", value: "yes_no" },
	];

	const onChangePaizhen = (e) => {
		setState({ ...state, [e.target.name]: e.target.value });

		if (e.target.value === "past_present_future" || e.target.value === "three_stone") {
			setTarotCards([null, null, null]);
			setTarotCardsFlip([false, false, false]);
		} else if (e.target.value === "yes_no") {
			setTarotCards([null, null, null, null]);
			setTarotCardsFlip([false, false, false, false]);
		}
	};

	const handleOpenCardDeckDialog = (index) => {
		setState({ ...state, openCardDeckDialog: true, selectingCardIndex: index });
	};

	const handleCloseCardDeckDialog = () => {
		setState({ ...state, openCardDeckDialog: false, selectingCardIndex: null });
	};

	const setTarotCard = (selectedCardIndex) => {
		handleCloseCardDeckDialog();
		setTarotCards([...tarotCards.slice(0, state.selectingCardIndex), selectedCardIndex, ...tarotCards.slice(state.selectingCardIndex + 1)]);
	};

	const rotateCard = (index) => {
		setTarotCardsFlip([...tarotCardsFlip.slice(0, index), !tarotCardsFlip[index], ...tarotCardsFlip.slice(index + 1)]);
	};

	const handleOpenCardInfoDialog = (card_num) => {
		setState({ ...state, openCardInfoDialog: true, cardIndex: card_num });
	};

	const handleCloseCardInfoDialog = () => {
		setState({ ...state, openCardInfoDialog: false, cardIndex: null });
	};

	const handleSubmit = () => {
		console.log(state.name);
		console.log(state.nameZh);
		console.log(state.email);
		console.log(state.contact);
		console.log(state.dob);
		console.log(state.occupation);
		console.log(state.address);
		console.log(state.divine_date);
		console.log(state.question);
		console.log(state.answer);
		console.log(tarotCards);
		console.log(tarotCardsFlip);

		if (
			state.name !== "" &&
			state.nameZh !== "" &&
			state.email !== "" &&
			state.contact !== "" &&
			state.dob !== null &&
			state.divine_date !== null &&
			state.question !== "" &&
			state.answer !== "" &&
			tarotCards !== null &&
			tarotCardsFlip !== null
		) {
			const dob_year = state.dob.getFullYear();
			const dob_month = state.dob.getMonth() + 1;
			const dob_date = state.dob.getDate();

			var solar = Solar.fromDate(new Date(parseInt(dob_year), parseInt(dob_month) - 1, parseInt(dob_date)));
			const xingzuo = solar.getXingZuo();

			const divine_year = state.divine_date.getFullYear();
			const divine_month = state.divine_date.getMonth() + 1;
			const divine_date = state.divine_date.getDate();

			fetch("https://tianshu.estoneweb.com/api/taluozhanbu/submit.php", {
				method: "POST",
				body: JSON.stringify({
					name: state.name,
					nameZh: state.nameZh,
					email: state.email,
					contact: state.contact,
					dob_year: dob_year,
					dob_month: dob_month,
					dob_date: dob_date,
					xingzuo: xingzuo,
					occupation: state.occupation,
					address: state.address,
					divine_year: divine_year,
					divine_month: divine_month,
					divine_date: divine_date,
					question: state.question,
					answer: state.answer,
					paizhen: state.paizhen,
					tarot_cards: tarotCards,
					tarot_cards_flip: tarotCardsFlip,
					submitBy: user.username,
				}),
			})
				.then((res) => res.json())
				.then((result) => console.log(result))
				.catch((error) => console.error(error));
		} else {
			setState({
				...state,
				nameError: state.name === "",
				nameZhError: state.nameZhError === "",
				emailError: state.email === "",
				contactError: state.contact === "",
				questionError: state.question === "",
				answerError: state.answer === "",
				paizhenError: state.paizhen === "",
			});
		}
	};

	return (
		<Stack direction="column" spacing={2}>
			<Paper elevation={4} sx={{ p: 2 }}>
				<Stack direction="column" spacing={2}>
					<Typography sx={{ textAlign: "center" }}>塔罗占卜</Typography>
					<div className="row align-items-center">
						<div className="col-lg-3 col-md-4 col-sm-12 text-md-end text-sm-start">
							英文名字 <RedAsterisk />
						</div>
						<div className="col-lg-9 col-md-8 col-sm-12">
							<TextField
								fullWidth
								variant="outlined"
								size="small"
								value={state.name}
								error={state.nameError}
								onChange={(e) => setState({ ...state, name: e.target.value })}
							/>
						</div>
					</div>
					<div className="row align-items-center">
						<div className="col-lg-3 col-md-4 col-sm-12 text-md-end text-sm-start">
							中文名字 <RedAsterisk />
						</div>
						<div className="col-lg-9 col-md-8 col-sm-12">
							<TextField
								fullWidth
								variant="outlined"
								size="small"
								value={state.nameZh}
								error={state.nameZhError}
								onChange={(e) => setState({ ...state, nameZh: e.target.value })}
							/>
						</div>
					</div>
					<div className="row align-items-center">
						<div className="col-lg-3 col-md-4 col-sm-12 text-md-end text-sm-start">
							邮件地址 <RedAsterisk />
						</div>
						<div className="col-lg-9 col-md-8 col-sm-12">
							<TextField
								fullWidth
								variant="outlined"
								size="small"
								value={state.email}
								error={state.emailError}
								onChange={(e) => setState({ ...state, email: e.target.value })}
							/>
						</div>
					</div>
					<div className="row align-items-center">
						<div className="col-lg-3 col-md-4 col-sm-12 text-md-end text-sm-start">
							电话号码 <RedAsterisk />
						</div>
						<div className="col-lg-9 col-md-8 col-sm-12">
							<MuiPhoneNumber
								variant="outlined"
								value={state.contact}
								error={state.contactError}
								onChange={(value) => setState({ ...state, contact: value, contactError: false })}
							/>
						</div>
					</div>
					<div className="row align-items-center">
						<div className="col-lg-3 col-md-4 col-sm-12 text-md-end text-sm-start">
							生日日期 <RedAsterisk />
						</div>
						<div className="col-lg-9 col-md-8 col-sm-12">
							<Stack direction="row" alignItems="center" justifyContent="space-between">
								<Typography>{displayDate(state.dob)}</Typography>
								<MuiButton variant="outlined" content="选择日期" onClick={() => setState({ ...state, openDobModal: true })} />
								<DatePicker
									theme={theme === "dark" ? "android-dark" : "android"}
									showCaption={true}
									min={new Date(1900, 0, 1)}
									dateConfig={dateConfig}
									headerFormat="DD/MMM/YYYY"
									isOpen={state.openDobModal}
									value={state.dob}
									onSelect={(e) => setState({ ...state, openDobModal: false, dob: e })}
									onCancel={() => setState({ ...state, openDobModal: false })}
								/>
							</Stack>
						</div>
					</div>
					<div className="row align-items-center">
						<div className="col-lg-3 col-md-4 col-sm-12 text-md-end text-sm-start">职业</div>
						<div className="col-lg-9 col-md-8 col-sm-12">
							<TextField
								fullWidth
								variant="outlined"
								size="small"
								value={state.occupation}
								onChange={(e) => setState({ ...state, occupation: e.target.value })}
							/>
						</div>
					</div>
					<div className="row align-items-center">
						<div className="col-lg-3 col-md-4 col-sm-12 text-md-end text-sm-start">地址</div>
						<div className="col-lg-9 col-md-8 col-sm-12">
							<TextField
								fullWidth
								variant="outlined"
								size="small"
								value={state.address}
								onChange={(e) => setState({ ...state, address: e.target.value })}
							/>
						</div>
					</div>
				</Stack>
				<Stack direction="column" spacing={2} sx={{ borderTop: "2px solid #eef0f8", marginTop: "15px", paddingTop: "15px" }}>
					<div className="row align-items-center">
						<div className="col-lg-3 col-md-4 col-sm-12 text-md-end text-sm-start">
							占卜日期 <RedAsterisk />
						</div>
						<div className="col-lg-9 col-md-8 col-sm-12">
							<Stack direction="row" alignItems="center" justifyContent="space-between">
								<Typography>{displayDate(state.divine_date)}</Typography>
								<MuiButton variant="outlined" content="选择日期" onClick={() => setState({ ...state, openDivineDateModal: true })} />
								<DatePicker
									theme={theme === "dark" ? "android-dark" : "android"}
									showCaption={true}
									min={new Date(1900, 0, 1)}
									dateConfig={dateConfig}
									headerFormat="DD/MMM/YYYY"
									isOpen={state.openDivineDateModal}
									value={state.divine_date}
									onSelect={(e) => setState({ ...state, openDivineDateModal: false, divine_date: e })}
									onCancel={() => setState({ ...state, openDivineDateModal: false })}
								/>
							</Stack>
						</div>
					</div>
					<div className="row align-items-center">
						<div className="col-lg-3 col-md-4 col-sm-12 text-md-end text-sm-start">
							占卜问题 <RedAsterisk />
						</div>
						<div className="col-lg-9 col-md-8 col-sm-12">
							<TextField
								fullWidth
								variant="outlined"
								value={state.question}
								onChange={(e) => setState({ ...state, question: e.target.value })}
								multiline
								rows={3}
								error={state.questionError}
							/>
						</div>
					</div>
					<div className="row align-items-center">
						<div className="col-lg-3 col-md-4 col-sm-12 text-md-end text-sm-start">
							占卜结果讲解 <RedAsterisk />
						</div>
						<div className="col-lg-9 col-md-8 col-sm-12">
							<TextField
								fullWidth
								variant="outlined"
								value={state.answer}
								onChange={(e) => setState({ ...state, answer: e.target.value })}
								multiline
								rows={3}
								error={state.answerError}
							/>
						</div>
					</div>
					<div className="row align-items-center">
						<div className="col-lg-3 col-md-4 col-sm-12 text-md-end text-sm-start">
							牌阵 <RedAsterisk />
						</div>
						<div className="col-lg-9 col-md-8 col-sm-12">
							<AutoComplete
								options={paizhenOptions}
								value={state.paizhen}
								onChange={onChangePaizhen}
								optionLabel="name"
								optionValue="value"
								name="paizhen"
								error={state.paizhenError}
							/>
						</div>
					</div>

					{state.paizhen === "past_present_future" && (
						<PastPresentFuture
							emptyBoxSx={emptyBoxSx}
							tarotImgSx={tarotImgSx}
							rotateCard={rotateCard}
							tarotCards={tarotCards}
							tarotCardsFlip={tarotCardsFlip}
							handleOpenCardInfoDialog={handleOpenCardInfoDialog}
							handleOpenCardDeckDialog={handleOpenCardDeckDialog}
						/>
					)}
					{state.paizhen === "three_stone" && (
						<ThreeStone
							emptyBoxSx={emptyBoxSx}
							tarotImgSx={tarotImgSx}
							rotateCard={rotateCard}
							tarotCards={tarotCards}
							tarotCardsFlip={tarotCardsFlip}
							handleOpenCardInfoDialog={handleOpenCardInfoDialog}
							handleOpenCardDeckDialog={handleOpenCardDeckDialog}
						/>
					)}
					{state.paizhen === "yes_no" && (
						<YesNo
							emptyBoxSx={emptyBoxSx}
							tarotImgSx={tarotImgSx}
							rotateCard={rotateCard}
							tarotCards={tarotCards}
							tarotCardsFlip={tarotCardsFlip}
							handleOpenCardInfoDialog={handleOpenCardInfoDialog}
							handleOpenCardDeckDialog={handleOpenCardDeckDialog}
						/>
					)}

					<CardDeckDialog
						openCardDeckDialog={state.openCardDeckDialog}
						handleCloseCardDeckDialog={handleCloseCardDeckDialog}
						setTarotCard={setTarotCard}
					/>
					<CardInfoDialog
						openCardInfoDialog={state.openCardInfoDialog}
						handleCloseCardInfoDialog={handleCloseCardInfoDialog}
						cardIndex={state.cardIndex}
					/>

					<Stack direction="row" spacing={2} justifyContent="center">
						<MuiButton variant="contained" content="提交" loading={state.isSubmitting} onClick={handleSubmit} />
					</Stack>
				</Stack>
			</Paper>
		</Stack>
	);
}

function PastPresentFuture(props) {
	return (
		<Stack direction="row" justifyContent="space-around">
			<Stack direction="column" spacing={1} alignItems="center" sx={{ width: { xs: "23vw", sm: "20vw", md: "18vw", lg: "15vw", xl: "12vw" } }}>
				<Stack direction="row" spacing={1}>
					<MuiButton mode="icon" content={<RotateIcon />} onClick={() => props.rotateCard(0)} />
					<MuiButton
						mode="icon"
						content={<InfoIcon />}
						onClick={() => props.handleOpenCardInfoDialog(props.tarotCards[0])}
						disabled={props.tarotCards[0] === null}
					/>
				</Stack>
				{props.tarotCards[0] === null ? (
					<Box sx={props.emptyBoxSx} onClick={() => props.handleOpenCardDeckDialog(0)} />
				) : (
					<img
						src={majorArcanaImages[props.tarotCards[0]]}
						alt="tarotImg"
						style={{ ...props.tarotImgSx, rotate: props.tarotCardsFlip[0] ? "180deg" : "0deg" }}
						onClick={() => props.handleOpenCardDeckDialog(0)}
					/>
				)}
				<Typography>过去</Typography>
			</Stack>
			<Stack direction="column" spacing={1} alignItems="center" sx={{ width: { xs: "23vw", sm: "20vw", md: "18vw", lg: "15vw", xl: "12vw" } }}>
				<Stack direction="row" spacing={1}>
					<MuiButton mode="icon" content={<RotateIcon />} onClick={() => props.rotateCard(1)} />
					<MuiButton
						mode="icon"
						content={<InfoIcon />}
						onClick={() => props.handleOpenCardInfoDialog(props.tarotCards[1])}
						disabled={props.tarotCards[1] === null}
					/>
				</Stack>
				{props.tarotCards[1] === null ? (
					<Box sx={props.emptyBoxSx} onClick={() => props.handleOpenCardDeckDialog(1)} />
				) : (
					<img
						src={majorArcanaImages[props.tarotCards[1]]}
						alt="tarotImg"
						style={{ ...props.tarotImgSx, rotate: props.tarotCardsFlip[1] ? "180deg" : "0deg" }}
						onClick={() => props.handleOpenCardDeckDialog(1)}
					/>
				)}
				<Typography>现在</Typography>
			</Stack>
			<Stack direction="column" spacing={1} alignItems="center" sx={{ width: { xs: "23vw", sm: "20vw", md: "18vw", lg: "15vw", xl: "12vw" } }}>
				<Stack direction="row" spacing={1}>
					<MuiButton mode="icon" content={<RotateIcon />} onClick={() => props.rotateCard(2)} />
					<MuiButton
						mode="icon"
						content={<InfoIcon />}
						onClick={() => props.handleOpenCardInfoDialog(props.tarotCards[2])}
						disabled={props.tarotCards[2] === null}
					/>
				</Stack>
				{props.tarotCards[2] === null ? (
					<Box sx={props.emptyBoxSx} onClick={() => props.handleOpenCardDeckDialog(2)} />
				) : (
					<img
						src={majorArcanaImages[props.tarotCards[2]]}
						alt="tarotImg"
						style={{ ...props.tarotImgSx, rotate: props.tarotCardsFlip[2] ? "180deg" : "0deg" }}
						onClick={() => props.handleOpenCardDeckDialog(2)}
					/>
				)}
				<Typography>未来</Typography>
			</Stack>
		</Stack>
	);
}

function ThreeStone(props) {
	return (
		<Stack direction="column" spacing={2}>
			<Stack direction="row" justifyContent="center">
				<Stack direction="column" alignItems="center" sx={{ width: { xs: "23vw", sm: "20vw", md: "18vw", lg: "15vw", xl: "12vw" } }}>
					<Stack direction="row" spacing={1}>
						<MuiButton mode="icon" content={<RotateIcon />} onClick={() => props.rotateCard(0)} />
						<MuiButton
							mode="icon"
							content={<InfoIcon />}
							onClick={() => props.handleOpenCardInfoDialog(props.tarotCards[0])}
							disabled={props.tarotCards[0] === null}
						/>
					</Stack>
					{props.tarotCards[0] === null ? (
						<Box sx={props.emptyBoxSx} onClick={() => props.handleOpenCardDeckDialog(0)} />
					) : (
						<img
							src={majorArcanaImages[props.tarotCards[0]]}
							alt="tarotImg"
							style={{ ...props.tarotImgSx, rotate: props.tarotCardsFlip[0] ? "180deg" : "0deg" }}
							onClick={() => props.handleOpenCardDeckDialog(0)}
						/>
					)}
				</Stack>
			</Stack>
			<Stack direction="row" spacing={2} justifyContent="space-around">
				<Stack direction="column" alignItems="center" sx={{ width: { xs: "23vw", sm: "20vw", md: "18vw", lg: "15vw", xl: "12vw" } }}>
					<Stack direction="row" spacing={1}>
						<MuiButton mode="icon" content={<RotateIcon />} onClick={() => props.rotateCard(1)} />
						<MuiButton
							mode="icon"
							content={<InfoIcon />}
							onClick={() => props.handleOpenCardInfoDialog(props.tarotCards[1])}
							disabled={props.tarotCards[1] === null}
						/>
					</Stack>
					{props.tarotCards[1] === null ? (
						<Box sx={props.emptyBoxSx} onClick={() => props.handleOpenCardDeckDialog(1)} />
					) : (
						<img
							src={majorArcanaImages[props.tarotCards[1]]}
							alt="tarotImg"
							style={{ ...props.tarotImgSx, rotate: props.tarotCardsFlip[1] ? "180deg" : "0deg" }}
							onClick={() => props.handleOpenCardDeckDialog(1)}
						/>
					)}
				</Stack>
				<Stack direction="column" alignItems="center" sx={{ width: { xs: "23vw", sm: "20vw", md: "18vw", lg: "15vw", xl: "12vw" } }}>
					<Stack direction="row" spacing={1}>
						<MuiButton mode="icon" content={<RotateIcon />} onClick={() => props.rotateCard(2)} />
						<MuiButton
							mode="icon"
							content={<InfoIcon />}
							onClick={() => props.handleOpenCardInfoDialog(props.tarotCards[2])}
							disabled={props.tarotCards[2] === null}
						/>
					</Stack>
					{props.tarotCards[2] === null ? (
						<Box sx={props.emptyBoxSx} onClick={() => props.handleOpenCardDeckDialog(2)} />
					) : (
						<img
							src={majorArcanaImages[props.tarotCards[2]]}
							alt="tarotImg"
							style={{ ...props.tarotImgSx, rotate: props.tarotCardsFlip[2] ? "180deg" : "0deg" }}
							onClick={() => props.handleOpenCardDeckDialog(2)}
						/>
					)}
				</Stack>
			</Stack>
		</Stack>
	);
}

function YesNo(props) {
	return (
		<Stack direction="column" spacing={2}>
			<Stack direction="row" spacing={{ xs: "20vw", md: "15vw", lg: "12vw" }} justifyContent="center">
				<Stack
					direction="column"
					alignItems="center"
					sx={{ rotate: "330deg", width: { xs: "23vw", sm: "20vw", md: "18vw", lg: "15vw", xl: "12vw" } }}
				>
					<Stack direction="row" spacing={1}>
						<MuiButton mode="icon" content={<RotateIcon />} onClick={() => props.rotateCard(0)} />
						<MuiButton
							mode="icon"
							content={<InfoIcon />}
							onClick={() => props.handleOpenCardInfoDialog(props.tarotCards[0])}
							disabled={props.tarotCards[0] === null}
						/>
					</Stack>
					{props.tarotCards[0] === null ? (
						<Box sx={props.emptyBoxSx} onClick={() => props.handleOpenCardDeckDialog(0)} />
					) : (
						<img
							src={majorArcanaImages[props.tarotCards[0]]}
							alt="tarotImg"
							style={{ ...props.tarotImgSx, rotate: props.tarotCardsFlip[0] ? "180deg" : "0deg" }}
							onClick={() => props.handleOpenCardDeckDialog(0)}
						/>
					)}
				</Stack>
				<Stack
					direction="column"
					alignItems="center"
					sx={{ rotate: "30deg", width: { xs: "23vw", sm: "20vw", md: "18vw", lg: "15vw", xl: "12vw" } }}
				>
					<Stack direction="row" spacing={1}>
						<MuiButton mode="icon" content={<RotateIcon />} onClick={() => props.rotateCard(1)} />
						<MuiButton
							mode="icon"
							content={<InfoIcon />}
							onClick={() => props.handleOpenCardInfoDialog(props.tarotCards[1])}
							disabled={props.tarotCards[1] === null}
						/>
					</Stack>
					{props.tarotCards[1] === null ? (
						<Box sx={props.emptyBoxSx} onClick={() => props.handleOpenCardDeckDialog(1)} />
					) : (
						<img
							src={majorArcanaImages[props.tarotCards[1]]}
							alt="tarotImg"
							style={{ ...props.tarotImgSx, rotate: props.tarotCardsFlip[1] ? "180deg" : "0deg" }}
							onClick={() => props.handleOpenCardDeckDialog(1)}
						/>
					)}
				</Stack>
			</Stack>
			<Stack direction="column" spacing={2} alignItems="center">
				<Stack direction="column" alignItems="center" sx={{ width: { xs: "23vw", sm: "20vw", md: "18vw", lg: "15vw", xl: "12vw" } }}>
					<Stack direction="row" spacing={1}>
						<MuiButton mode="icon" content={<RotateIcon />} onClick={() => props.rotateCard(2)} />
						<MuiButton
							mode="icon"
							content={<InfoIcon />}
							onClick={() => props.handleOpenCardInfoDialog(props.tarotCards[2])}
							disabled={props.tarotCards[2] === null}
						/>
					</Stack>
					{props.tarotCards[2] === null ? (
						<Box sx={props.emptyBoxSx} onClick={() => props.handleOpenCardDeckDialog(2)} />
					) : (
						<img
							src={majorArcanaImages[props.tarotCards[2]]}
							alt="tarotImg"
							style={{ ...props.tarotImgSx, rotate: props.tarotCardsFlip[2] ? "180deg" : "0deg" }}
							onClick={() => props.handleOpenCardDeckDialog(2)}
						/>
					)}
				</Stack>
				<Stack direction="column" alignItems="center" sx={{ width: { xs: "23vw", sm: "20vw", md: "18vw", lg: "15vw", xl: "12vw" } }}>
					<Stack direction="row" spacing={1}>
						<MuiButton mode="icon" content={<RotateIcon />} onClick={() => props.rotateCard(3)} />
						<MuiButton
							mode="icon"
							content={<InfoIcon />}
							onClick={() => props.handleOpenCardInfoDialog(props.tarotCards[3])}
							disabled={props.tarotCards[3] === null}
						/>
					</Stack>
					{props.tarotCards[3] === null ? (
						<Box sx={props.emptyBoxSx} onClick={() => props.handleOpenCardDeckDialog(3)} />
					) : (
						<img
							src={majorArcanaImages[props.tarotCards[3]]}
							alt="tarotImg"
							style={{ ...props.tarotImgSx, rotate: props.tarotCardsFlip[3] ? "180deg" : "0deg" }}
							onClick={() => props.handleOpenCardDeckDialog(3)}
						/>
					)}
				</Stack>
			</Stack>
		</Stack>
	);
}

function CardDeckDialog(props) {
	return (
		<Dialog open={props.openCardDeckDialog} fullWidth={true} maxWidth="md" onClose={props.handleCloseCardDeckDialog}>
			<DialogTitle>选择塔罗牌</DialogTitle>
			<DialogContent>
				<Grid container spacing={2} justifyContent="center">
					{majorArcanaImages.map((tarot, index) => (
						<Grid item key={index} sx={{ width: { xs: "50%", sm: "30%", md: "25%", lg: "20%" } }}>
							<img
								src={tarot}
								alt={`tarot${index}`}
								style={{ width: "100%", cursor: "pointer" }}
								onClick={() => props.setTarotCard(index)}
							/>
						</Grid>
					))}
				</Grid>
			</DialogContent>
			<DialogActions>
				<Tooltip title="关闭" TransitionComponent={Zoom} arrow>
					<Button variant="outlined" onClick={props.handleCloseCardDeckDialog}>
						<CloseIcon />
					</Button>
				</Tooltip>
			</DialogActions>
		</Dialog>
	);
}

function CardInfoDialog(props) {
	return (
		<Dialog open={props.openCardInfoDialog} fullWidth={true} maxWidth="md" onClose={props.handleCloseCardInfoDialog}>
			<DialogTitle>塔罗牌</DialogTitle>
			<DialogContent sx={{ px: 3 }}>
				<Stack direction={{ xs: "column", md: "row" }} spacing={2} alignItems="center">
					{props.cardIndex !== null && (
						<>
							<Stack direction="row" justifyContent="center">
								<img src={majorArcanaDesc[props.cardIndex]["image"]} alt="" style={{ width: "100%" }} />
							</Stack>
							<CardDetails info={majorArcanaDesc[props.cardIndex]} />
						</>
					)}
				</Stack>
			</DialogContent>
			<DialogActions>
				<Tooltip title="关闭" TransitionComponent={Zoom} arrow>
					<Button variant="outlined" onClick={props.handleCloseCardInfoDialog}>
						<CloseIcon />
					</Button>
				</Tooltip>
			</DialogActions>
		</Dialog>
	);
}

function CardDetails(props) {
	return (
		<Stack direction="column" spacing={2}>
			<Typography variant="h5" align="center" sx={{ fontWeight: "bold" }}>
				{props.info.name_zh} {props.info.name}
			</Typography>
			<Stack direction="column">
				<Typography>{props.info.desc}</Typography>
			</Stack>
			<Stack direction="column">
				<Typography sx={{ fontWeight: "bold" }}>正：</Typography>
				<Typography>{props.info.upright}</Typography>
			</Stack>
			<Stack direction="column">
				<Typography sx={{ fontWeight: "bold" }}>反：</Typography>
				<Typography>{props.info.reverse}</Typography>
			</Stack>
		</Stack>
	);
}

export default TaLuoZhanBu;
