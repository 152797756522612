import { Stack, Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";
import { Component } from "react";

class BaGua extends Component {
	render() {
		return (
			<Stack direction="column" spacing={2}>
				<TableContainer>
					<Table>
						<TableBody>
							<TableRow>
								<TableCell>乾</TableCell>
								<TableCell>兌</TableCell>
								<TableCell>離</TableCell>
								<TableCell>震</TableCell>
								<TableCell>巽</TableCell>
								<TableCell>坎</TableCell>
								<TableCell>艮</TableCell>
								<TableCell>坤</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>天</TableCell>
								<TableCell>澤</TableCell>
								<TableCell>火</TableCell>
								<TableCell>雷</TableCell>
								<TableCell>風</TableCell>
								<TableCell>水</TableCell>
								<TableCell>山</TableCell>
								<TableCell>地</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</TableContainer>
				<TableContainer>
					<Table>
						<TableBody>
							<TableRow>
								<TableCell>先天八卦</TableCell>
								<TableCell>
									<table>
										<tbody>
											<tr>
												<td>兌</td>
												<td>乾</td>
												<td>巽</td>
											</tr>
											<tr>
												<td>離</td>
												<td></td>
												<td>坎</td>
											</tr>
											<tr>
												<td>震</td>
												<td>坤</td>
												<td>艮</td>
											</tr>
										</tbody>
									</table>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>后天八卦</TableCell>
								<TableCell>
									<table>
										<tbody>
											<tr>
												<td>巽 4</td>
												<td>離 9</td>
												<td>坤 2</td>
											</tr>
											<tr>
												<td>震 3</td>
												<td>中 5</td>
												<td>兌 7</td>
											</tr>
											<tr>
												<td>艮 8</td>
												<td>坎 1</td>
												<td>乾 6</td>
											</tr>
										</tbody>
									</table>
								</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</TableContainer>
			</Stack>
		);
	}
}

export default BaGua;
