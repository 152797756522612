import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Paper, Stack, Tooltip, Typography, Zoom } from "@mui/material";
import { useEffect, useState } from "react";

import { CardDetails } from "../components/common";
import { CloseIcon } from "../components/Icon";
import { useAuth } from "../hooks/useAuth";
import { useTheme } from "../hooks/useTheme";
import eyeblack from "../images/taluomima/eye-black.png";
import eyewhite from "../images/taluomima/eye-white.png";
import { displayDate } from "../utils";
import { allXingZuo, taluomimaDesc } from "../utils/constant";

function Mine() {
	const { theme } = useTheme();
	const { user } = useAuth();

	const [state, setState] = useState({
		result1: 0,
		result2: 0,
		result3: 0,
		count: [0, 0, 0, 0, 0, 0, 0, 0, 0],
		xingzuo: "",
		openDialog: false,
		numberToShow: 0,
	});

	const stackStyleSx = {
		borderRadius: 3.5,
		px: 1.5,
		py: 0.5,
		transition: "all 0.5s ease",
		"&:hover": {
			cursor: "pointer",
			boxShadow: "0px 0px 15px #aaaaaa",
		},
	};

	const getBackgroundColor = (index, count) => {
		return count >= index ? (theme === "dark" ? "grey" : "darkslategrey") : "transparent";
	};

	const handleCloseDialog = () => {
		setState({ ...state, openDialog: false });
	};

	const showCard = (num) => {
		setState({ ...state, openDialog: true, numberToShow: num });
	};

	useEffect(() => {
		fetch("https://tianshu.estoneweb.com/api/user/get_taluomima.php", {
			method: "POST",
			body: JSON.stringify({
				username: user.username,
			}),
		})
			.then((res) => res.json())
			.then((result) => {
				setState({
					...state,
					dob: result["dob"],
					result1: result["result"][0],
					result2: result["result"][1],
					result3: result["result"][2],
					count: result["result"][3],
					xingzuo: result["xingzuo"] + "座 " + allXingZuo.find(({ name_zh }) => name_zh === result["xingzuo"] + "座").name,
				});
			})
			.catch((error) => console.error(error));
	}, [user]);

	return (
		<Stack direction="column" spacing={2}>
			<Paper elevation={4} sx={{ px: 1, py: 2 }}>
				<Stack direction="column" spacing={2} alignItems="center">
					<Typography>我的塔罗密码</Typography>
					<Typography>{displayDate(state.dob)}</Typography>
					<Stack direction="column" spacing={0} alignItems="center">
						{state.result1 !== 0 && (
							<Stack direction="column" alignItems="center" sx={stackStyleSx} onClick={() => showCard(state.result1)}>
								<Typography variant="h6">生命</Typography>
								<Typography variant="h5">{state.result1}</Typography>
							</Stack>
						)}
						<Stack direction="row" alignItems="flex-end" justifyContent="center" spacing={0}>
							{state.result3 !== 0 && (
								<Stack direction="column" alignItems="center" sx={stackStyleSx} onClick={() => showCard(state.result3)}>
									<Typography variant="h6">行为</Typography>
									<Typography variant="h5">{state.result3}</Typography>
								</Stack>
							)}
							<img src={theme === "dark" ? eyewhite : eyeblack} className="eye-logo" alt="logo" />
							{state.result2 !== 0 && (
								<Stack direction="column" alignItems="center" sx={stackStyleSx} onClick={() => showCard(state.result2)}>
									<Typography variant="h6">后天</Typography>
									<Typography variant="h5">{state.result2}</Typography>
								</Stack>
							)}
						</Stack>
					</Stack>
					{state.xingzuo !== "" && <Typography>{state.xingzuo}</Typography>}
					<table className="table-cell-outlined">
						<tbody>
							{state.count.map((cnt, index) => (
								<tr key={index}>
									<td style={{ textAlign: "center", cursor: "pointer" }} onClick={() => showCard(index + 1)}>
										{index + 1}
									</td>
									<td style={{ backgroundColor: getBackgroundColor(1, state.count[index]) }}></td>
									<td style={{ backgroundColor: getBackgroundColor(2, state.count[index]) }}></td>
									<td style={{ backgroundColor: getBackgroundColor(3, state.count[index]) }}></td>
									<td style={{ backgroundColor: getBackgroundColor(4, state.count[index]) }}></td>
									<td style={{ backgroundColor: getBackgroundColor(5, state.count[index]) }}></td>
									<td style={{ backgroundColor: getBackgroundColor(6, state.count[index]) }}></td>
									<td style={{ backgroundColor: getBackgroundColor(7, state.count[index]) }}></td>
									<td style={{ backgroundColor: getBackgroundColor(8, state.count[index]) }}></td>
									<td style={{ backgroundColor: getBackgroundColor(9, state.count[index]) }}></td>
								</tr>
							))}
						</tbody>
					</table>
				</Stack>
			</Paper>
			<CardDialog openDialog={state.openDialog} handleCloseDialog={handleCloseDialog} numberToShow={state.numberToShow} />
		</Stack>
	);
}

function CardDialog(props) {
	const [state, setState] = useState({
		number: 0,
		name_zh: "",
		name: "",
		image: null,
		title: "",
		gongke: "",
		mubiao: "",
		goutong: "",
		qianneng: "",
		guihua: "",
		xiaohai: "",
	});

	useEffect(() => {
		if (props.numberToShow !== 0) {
			const result = taluomimaDesc.find(({ number }) => number === props.numberToShow);
			setState(result);
		}
	}, [props]);

	return (
		<Dialog open={props.openDialog} fullWidth={true} maxWidth="md" onClose={props.handleCloseDialog}>
			<DialogTitle>
				{state.number} - {state.name_zh} {state.name}
			</DialogTitle>
			<DialogContent sx={{ px: 3 }}>
				<Stack direction={{ xs: "column", md: "row" }} spacing={2} alignItems="center">
					<Stack direction="row" justifyContent="center">
						{state.image !== null && state.image !== "" && (
							<img src={state.image} className={`image-${props.numberToShow}`} alt="logo" style={{ xs: { width: "50%" } }} />
						)}
					</Stack>
					<CardDetails
						title={state.title}
						gongke={state.gongke}
						mubiao={state.mubiao}
						goutong={state.goutong}
						qianneng={state.qianneng}
						guihua={state.guihua}
						xiaohai={state.xiaohai}
					/>
				</Stack>
			</DialogContent>
			<DialogActions>
				<Tooltip title="关闭" TransitionComponent={Zoom} arrow>
					<Button variant="outlined" onClick={props.handleCloseDialog}>
						<CloseIcon />
					</Button>
				</Tooltip>
			</DialogActions>
		</Dialog>
	);
}

export default Mine;
