import { Button, Divider, FormControl, FormHelperText, Paper, Stack, TextField, Typography } from "@mui/material";
import { Solar } from "lunar-javascript";
import { useState } from "react";
import DatePicker from "react-mobile-datepicker";
import { useNavigate } from "react-router";

import { MuiButton, MuiPhoneNumber, RedAsterisk, SnackBar } from "./components/common";
import { useTheme } from "./hooks/useTheme";
import { displayDate } from "./utils";
import { dateConfig } from "./utils/constant";

function Register(props) {
	const navigate = useNavigate();
	const { theme } = useTheme();

	const [snackbar, setSnackbar] = useState({ open: false, vertical: "bottom", horizontal: "center", type: "info", message: "" });
	const showSnackbarMessage = (type, message) => setSnackbar({ ...snackbar, open: true, type, message });
	const handleCloseSnackbar = () => setSnackbar({ ...snackbar, open: false });

	const [state, setState] = useState({
		name: "",
		nameZh: "",
		email: "",
		contact: "",
		dob: new Date(),
		username: "",
		password: "",
		confirmPassword: "",
		usernameHelperText: "",
		confirmPasswordHelperText: "",
		nameError: false,
		nameZhError: false,
		emailError: false,
		contactError: false,
		dobError: false,
		usernameError: false,
		passwordError: false,
		confirmPasswordError: false,
		openDateModal: false,
	});

	const checkEmail = () => {
		fetch("https://tianshu.estoneweb.com/api/auth/check_email_existence.php", {
			method: "POST",
			body: JSON.stringify({ email: state.email }),
		})
			.then((res) => res.json())
			.then((result) => {
				if (result["success"]) {
					setState({ ...state, emailHelperText: "Username is valid" });
				} else if (result["error"]) {
					setState({ ...state, emailHelperText: "Username has been taken" });
				}
			})
			.catch((error) => console.error(error));
	};

	const handleChangeDate = (e) => {
		setState({ ...state, openDateModal: false, dob: e });
	};

	const checkUsername = () => {
		fetch("https://tianshu.estoneweb.com/api/auth/check_username_existence.php", {
			method: "POST",
			body: JSON.stringify({ username: state.username }),
		})
			.then((res) => res.json())
			.then((result) => {
				if (result["success"]) {
					setState({ ...state, usernameHelperText: "Username is valid" });
				} else if (result["error"]) {
					setState({ ...state, usernameHelperText: "Username has been taken" });
				}
			})
			.catch((error) => console.error(error));
	};

	const checkConfirmPassword = (password, confirmPassword) => {
		if (password !== "" && confirmPassword !== "") {
			if (password === confirmPassword) {
				setState({ ...state, password, confirmPassword, passwordError: false, confirmPasswordError: false, confirmPasswordHelperText: "" });
			} else {
				setState({
					...state,
					password,
					confirmPassword,
					passwordError: false,
					confirmPasswordError: true,
					confirmPasswordHelperText: "Password doesn't match",
				});
			}
		} else {
			setState({ ...state, password, confirmPassword, passwordError: false, confirmPasswordError: false, confirmPasswordHelperText: "" });
		}
	};

	const register = () => {
		if (
			state.name !== "" &&
			state.nameZh !== "" &&
			state.email !== "" &&
			state.contact !== "" &&
			state.dob !== null &&
			state.username !== "" &&
			state.password !== "" &&
			state.confirmPassword !== ""
		) {
			const year = state.dob.getFullYear();
			const month = state.dob.getMonth() + 1;
			const date = state.dob.getDate();

			var solar = Solar.fromDate(new Date(year, month - 1, date));
			const xingzuo = solar.getXingZuo();

			fetch("https://tianshu.estoneweb.com/api/auth/register.php", {
				method: "POST",
				body: JSON.stringify({
					name: state.name,
					nameZh: state.nameZh,
					email: state.email,
					contact: state.contact.replace(/-/g, "").replace(/ /g, ""),
					dob: state.dob.getFullYear() + "-" + (state.dob.getMonth() + 1) + "-" + state.dob.getDate(),
					xingzuo: xingzuo,
					username: state.username,
					password: state.password,
				}),
			})
				.then((res) => res.json())
				.then((result) => {
					if (result["success"]) {
						showSnackbarMessage("success", result["success"]);
						navigate("/");
					} else if (result["failed"]) {
						showSnackbarMessage("error", result["failed"]);
					} else {
						console.log(result);
					}
				})
				.catch((error) => console.error(error));
		} else {
			setState({
				...state,
				nameError: state.name === "",
				nameZhError: state.nameZh === "",
				emailError: state.email === "",
				contactError: state.contact === "",
				dobError: state.dob === null || state.dob === "",
				usernameError: state.username === "",
				passwordError: state.password === "",
				confirmPasswordError: state.confirmPassword === "",
			});
		}
	};

	return (
		<Stack direction="column" alignItems="center" justifyContent="center" spacing={3} paddingX={3}>
			<SnackBar snackbar={snackbar} handleCloseSnackbar={handleCloseSnackbar} />
			<Paper elevation={4} sx={{ p: 3 }}>
				<Stack direction="column" spacing={2}>
					<div className="row align-items-center">
						<div className="col-md-4 col-sm-12 text-md-end text-sm-start">
							英文名字 <RedAsterisk />
						</div>
						<div className="col-md-8 col-sm-12">
							<TextField
								fullWidth={true}
								variant="outlined"
								size="small"
								value={state.name}
								error={state.nameError}
								onChange={(e) => setState({ ...state, name: e.target.value, nameError: false })}
							/>
						</div>
					</div>
					<div className="row align-items-center">
						<div className="col-md-4 col-sm-12 text-md-end text-sm-start">
							中文名字 <RedAsterisk />
						</div>
						<div className="col-md-8 col-sm-12">
							<TextField
								fullWidth={true}
								variant="outlined"
								size="small"
								value={state.nameZh}
								error={state.nameZhError}
								onChange={(e) => setState({ ...state, nameZh: e.target.value, nameZhError: false })}
							/>
						</div>
					</div>
					<div className="row align-items-center">
						<div className="col-md-4 col-sm-12 text-md-end text-sm-start">
							邮件地址 <RedAsterisk />
						</div>
						<div className="col-md-8 col-sm-12">
							<TextField
								fullWidth={true}
								variant="outlined"
								size="small"
								value={state.email}
								error={state.emailError}
								onChange={(e) => setState({ ...state, email: e.target.value, emailError: false })}
								onBlur={checkEmail}
							/>
						</div>
					</div>
					<div className="row align-items-center">
						<div className="col-md-4 col-sm-12 text-md-end text-sm-start">
							电话号码 <RedAsterisk />
						</div>
						<div className="col-md-8 col-sm-12">
							<MuiPhoneNumber
								variant="outlined"
								value={state.contact}
								error={state.contactError}
								onChange={(value) => setState({ ...state, contact: value, contactError: false })}
							/>
						</div>
					</div>
					<div className="row align-items-center">
						<div className="col-md-4 col-sm-12 text-md-end text-sm-start">
							生日日期 <RedAsterisk />
						</div>
						<div className="col-md-8 col-sm-12">
							<Stack direction="row" alignItems="center" justifyContent="space-between">
								<Typography>{displayDate(state.dob)}</Typography>
								<MuiButton variant="outlined" content="选择日期" onClick={() => setState({ ...state, openDateModal: true })} />
								<DatePicker
									theme={theme === "dark" ? "android-dark" : "android"}
									showCaption={true}
									min={new Date(1900, 0, 1)}
									dateConfig={dateConfig}
									headerFormat="DD/MMM/YYYY"
									isOpen={state.openDateModal}
									value={state.dob}
									onSelect={handleChangeDate}
									onCancel={() => setState({ ...state, openDateModal: false })}
								/>
							</Stack>
						</div>
					</div>
					<Divider sx={{ borderWidth: "1px", borderColor: theme === "dark" ? "#ddd" : "#333" }} />
					<div className="row align-items-center">
						<div className="col-md-4 col-sm-12 text-md-end text-sm-start">
							用户名 <RedAsterisk />
						</div>
						<div className="col-md-8 col-sm-12">
							<FormControl fullWidth={true}>
								<TextField
									fullWidth={true}
									variant="outlined"
									size="small"
									value={state.username}
									error={state.usernameError}
									onChange={(e) => setState({ ...state, username: e.target.value, usernameError: false })}
									onBlur={checkUsername}
								/>
								<FormHelperText>{state.usernameHelperText}</FormHelperText>
							</FormControl>
						</div>
					</div>
					<div className="row align-items-center">
						<div className="col-md-4 col-sm-12 text-md-end text-sm-start">
							密码 <RedAsterisk />
						</div>
						<div className="col-md-8 col-sm-12">
							<TextField
								fullWidth={true}
								variant="outlined"
								type="password"
								size="small"
								value={state.password}
								error={state.passwordError}
								onChange={(e) => checkConfirmPassword(e.target.value, state.confirmPassword)}
							/>
						</div>
					</div>
					<div className="row align-items-center">
						<div className="col-md-4 col-sm-12 text-md-end text-sm-start">
							确认密码 <RedAsterisk />
						</div>
						<div className="col-md-8 col-sm-12">
							<FormControl fullWidth={true}>
								<TextField
									fullWidth={true}
									variant="outlined"
									type="password"
									size="small"
									value={state.confirmPassword}
									error={state.confirmPasswordError}
									onChange={(e) => checkConfirmPassword(state.password, e.target.value)}
								/>
								<FormHelperText>{state.confirmPasswordHelperText}</FormHelperText>
							</FormControl>
						</div>
					</div>
					<div className="row align-items-center">
						<div className="col-12">
							<Button fullWidth={true} variant="contained" onClick={register}>
								注册
							</Button>
						</div>
					</div>
					<div className="row align-items-center">
						<div className="col-12">
							<Button fullWidth={true} variant="outlined" onClick={() => navigate("/")}>
								返回登入
							</Button>
						</div>
					</div>
				</Stack>
			</Paper>
		</Stack>
	);
}

export default Register;
