import { Paper, Stack, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";

import { MuiButton, SnackBar } from "../components/common";

function Modules(props) {
	const [modules, setModules] = useState([]);

	const [snackbar, setSnackbar] = useState({ open: false, vertical: "bottom", horizontal: "center", type: "info", message: "" });
	const showSnackbarMessage = (type, message) => setSnackbar({ ...snackbar, open: true, type, message });
	const handleCloseSnackbar = () => setSnackbar({ ...snackbar, open: false });

	const handleEditModule = (index, name, value) => {
		setModules([
			...modules.slice(0, index),
			{
				...modules[index],
				[name]: value,
			},
			...modules.slice(index + 1),
		]);
	};

	const updateModules = () => {
		fetch("https://tianshu.estoneweb.com/api/admin/update_all_modules.php", {
			method: "POST",
			body: JSON.stringify({
				modules: modules,
			}),
		})
			.then((res) => res.json())
			.then((result) => showSnackbarMessage("success", "Updated"))
			.catch((error) => console.error(error));
	};

	const getAllModules = () => {
		fetch("https://tianshu.estoneweb.com/api/admin/get_all_modules.php", {
			method: "POST",
		})
			.then((res) => res.json())
			.then((result) => setModules(result))
			.catch((error) => console.error(error));
	};

	useEffect(() => {
		getAllModules();
	}, []);

	return (
		<Paper elevation={4} sx={{ p: 2, mb: 2 }}>
			<SnackBar snackbar={snackbar} handleCloseSnackbar={handleCloseSnackbar} />
			<TableContainer sx={{ mb: 2 }}>
				<Table size="small">
					<TableHead>
						<TableRow>
							<TableCell>Name (ZH)</TableCell>
							<TableCell>Order</TableCell>
							<TableCell>Access</TableCell>
							<TableCell>Active</TableCell>
							<TableCell></TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{modules.map((module, index) => (
							<TableRow key={index}>
								<TableCell>
									<TextField
										name="nameZh"
										value={module.nameZh}
										size="small"
										onChange={(e) => handleEditModule(index, e.target.name, e.target.value)}
										sx={{ minWidth: "120px" }}
									/>
								</TableCell>
								<TableCell>
									<TextField
										name="order_sequence"
										value={module.order_sequence}
										type="number"
										InputProps={{ inputProps: { min: 1 } }}
										size="small"
										onChange={(e) => handleEditModule(index, e.target.name, e.target.value)}
										sx={{ minWidth: "50px" }}
									/>
								</TableCell>
								<TableCell>
									<TextField
										name="access"
										value={module.access}
										size="small"
										onChange={(e) => handleEditModule(index, e.target.name, e.target.value)}
										sx={{ minWidth: "200px" }}
									/>
								</TableCell>
								<TableCell>
									<Stack direction="row" alignItems="center">
										<Typography>Off</Typography>
										<Switch
											size="small"
											name="active"
											checked={module.active === 1}
											onChange={(e) => handleEditModule(index, e.target.name, module.active === 0 ? 1 : 0)}
										/>
										<Typography>On</Typography>
									</Stack>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
			<Stack direction="row" spacing={2} justifyContent="center">
				<MuiButton variant="contained" content="保存" onClick={updateModules} />
				<MuiButton variant="outlined" content="取消" onClick={getAllModules} />
			</Stack>
		</Paper>
	);
}

export default Modules;
