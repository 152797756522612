import { ArrowLeft as ArrowLeftIcon, ArrowRight as ArrowRightIcon, Close as CloseIcon } from "@mui/icons-material";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	FormControl,
	IconButton,
	InputLabel,
	MenuItem,
	Paper,
	Select,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	Tooltip,
	Typography,
	Zoom,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Lunar, Solar } from "lunar-javascript/lunar";
import React, { Component } from "react";

import { AutoComplete, MuiButton } from "../components/common";
import { useTheme } from "../hooks/useTheme";

class ChengGu extends Component {
	state = {
		openDialog1: false,
		openDialog2: false,
		nianOptions: [],
		yueOptions: [],
		riOptions: [],
		shichenOptions: [],
		isLoadingNianOptions: true,
		isLoadingYueOptions: true,
		isLoadingRiOptions: true,
		isLoadingShichenOptions: true,
		nian_ID: null,
		yue_ID: null,
		ri_ID: null,
		shichen_ID: null,
		error: {
			nian_ID: false,
			yue_ID: false,
			ri_ID: false,
			shichen_ID: false,
		},
		liangshu: "",
		ming: "",
		gejue1: "",
		gejue2: "",
		gejue: "",
		zhujie: "",
		result_index: 0,
		showChengguResult: false,
		chenggu_results: null,
		pickedDate: new Date(),
		lunarYear: "",
		lunarMonth: "",
		lunarDay: "",
	};

	handleCloseDialog1 = () => {
		this.setState({ ...this.state, openDialog1: false });
	};

	handleCloseDialog2 = () => {
		this.setState({ ...this.state, openDialog2: false });
	};

	showChenggu = async () => {
		fetch("https://tianshu.estoneweb.com/api/chenggu/get_all_chenggu_result.php", {
			method: "POST",
		})
			.then((res) => res.json())
			.then((result) => this.setState({ ...this.state, openDialog1: true, chenggu_results: result }))
			.catch((error) => console.error(error));
	};

	onChangeLiangshu = (e) => {
		this.setState({ ...this.state, result_index: e.target.value });
	};

	onAddLiangshu = () => {
		if (this.state.result_index < 51) {
			this.setState((prevState) => ({ ...prevState, result_index: prevState.result_index + 1 }));
		}
	};

	onDeductLiangshu = () => {
		if (this.state.result_index > 0) {
			this.setState((prevState) => ({ ...prevState, result_index: prevState.result_index - 1 }));
		}
	};

	onChangeDate = (e) => {
		this.setState({ ...this.state, [e.target.name]: e.target.value, error: { ...this.state.error, [e.target.name]: false } });
	};

	calculateLiangshu = async () => {
		if (this.state.nian_ID !== null && this.state.yue_ID !== null && this.state.ri_ID !== null && this.state.shichen_ID !== null) {
			fetch("https://tianshu.estoneweb.com/api/chenggu/get_chenggu_result.php", {
				method: "POST",
				body: JSON.stringify({
					nian_ID: this.state.nian_ID,
					yue_ID: this.state.yue_ID,
					ri_ID: this.state.ri_ID,
					shichen_ID: this.state.shichen_ID,
				}),
			})
				.then((res) => res.json())
				.then((result) => {
					this.setState({
						...this.state,
						liangshu: result.liangshu,
						ming: result.ming,
						gejue1: result.gejue1,
						gejue2: result.gejue2,
						gejue: result.gejue,
						zhujie: result.zhujie,
						showChengguResult: true,
					});
				})
				.catch((error) => console.error(error));
		} else {
			this.setState({
				...this.state,
				error: {
					...this.state.error,
					nian_ID: this.state.nian_ID ? false : true,
					yue_ID: this.state.yue_ID ? false : true,
					ri_ID: this.state.ri_ID ? false : true,
					shichen_ID: this.state.shichen_ID ? false : true,
				},
			});
		}
	};

	clearLiangshu = () => {
		this.setState({
			...this.state,
			nian_ID: null,
			yue_ID: null,
			ri_ID: null,
			shichen_ID: null,
			showChengguResult: false,
			liangshu: "",
			ming: "",
			gejue1: "",
			gejue2: "",
			gejue: "",
			zhujie: "",
			error: {
				...this.state.error,
				nian_ID: false,
				yue_ID: false,
				ri_ID: false,
				shichen_ID: false,
			},
		});
	};

	handleChangeSolarDate = async (e) => {
		let solar = Solar.fromYmd(e.getFullYear(), e.getMonth() + 1, e.getDate());
		let lunar = Lunar.fromSolar(solar);

		let nian_ID = await fetch("https://tianshu.estoneweb.com/api/chenggu/get_nian_by_name.php", {
			method: "POST",
			body: JSON.stringify({
				nian: lunar.getYearInGanZhi(),
				year: e.getFullYear(),
			}),
		})
			.then((res) => res.json())
			.then((result) => {
				return "ID" in result ? result["ID"] : "";
			})
			.catch((error) => console.error(error));

		let yue_ID = await fetch("https://tianshu.estoneweb.com/api/chenggu/get_yue_by_name.php", {
			method: "POST",
			body: JSON.stringify({
				yue: lunar.getMonthInChinese(),
			}),
		})
			.then((res) => res.json())
			.then((result) => {
				return "ID" in result ? result["ID"] : "";
			})
			.catch((error) => console.error(error));

		// get ri_ID
		let ri_ID = await fetch("https://tianshu.estoneweb.com/api/chenggu/get_ri_by_name.php", {
			method: "POST",
			body: JSON.stringify({
				ri: lunar.getDayInChinese(),
			}),
		})
			.then((res) => res.json())
			.then((result) => {
				return "ID" in result ? result["ID"] : "";
			})
			.catch((error) => console.error(error));

		this.setState({
			...this.state,
			nian_ID,
			yue_ID,
			ri_ID,
			pickedDate: e,
			lunarYear: lunar.getYearInGanZhi(),
			lunarMonth: lunar.getMonthInChinese(),
			lunarDay: lunar.getDayInChinese(),
		});
	};

	async componentDidMount() {
		await fetch("https://tianshu.estoneweb.com/api/chenggu/get_nian_options.php", {
			method: "POST",
		})
			.then((res) => res.json())
			.then((result) => this.setState((prevState) => ({ ...prevState, nianOptions: result, isLoadingNianOptions: false })))
			.catch((error) => console.error(error));

		await fetch("https://tianshu.estoneweb.com/api/chenggu/get_yue_options.php", {
			method: "POST",
		})
			.then((res) => res.json())
			.then((result) => this.setState((prevState) => ({ ...prevState, yueOptions: result, isLoadingYueOptions: false })))
			.catch((error) => console.error(error));

		await fetch("https://tianshu.estoneweb.com/api/chenggu/get_ri_options.php", {
			method: "POST",
		})
			.then((res) => res.json())
			.then((result) => this.setState((prevState) => ({ ...prevState, riOptions: result, isLoadingRiOptions: false })))
			.catch((error) => console.error(error));

		await fetch("https://tianshu.estoneweb.com/api/chenggu/get_shichen_options.php", {
			method: "POST",
		})
			.then((res) => res.json())
			.then((result) => this.setState((prevState) => ({ ...prevState, shichenOptions: result, isLoadingShichenOptions: false })))
			.catch((error) => console.error(error));

		let today = new Date();

		this.handleChangeSolarDate(today);
	}

	render() {
		return (
			<Stack direction="column" spacing={2}>
				<Paper elevation={4} sx={{ p: 2 }}>
					<Stack direction="column" spacing={2}>
						<Typography sx={{ textAlign: "center" }}>稱骨算命</Typography>
						<Stack direction="row" spacing={2} justifyContent="center">
							<MuiButton variant="outlined" content="所有骨量" onClick={this.showChenggu} />
						</Stack>
						<ChengguDialog
							state={this.state}
							handleCloseDialog1={this.handleCloseDialog1}
							onDeductLiangshu={this.onDeductLiangshu}
							onChangeLiangshu={this.onChangeLiangshu}
							onAddLiangshu={this.onAddLiangshu}
						/>
						<ZhongLiangDialog state={this.state} handleCloseDialog2={this.handleCloseDialog2} />
						<Stack
							direction="column"
							alignItems="center"
							justifyContent="center"
							spacing={2}
							sx={{
								borderTop: "2px solid #eef0f8",
								borderBottom: "2px solid #eef0f8",
								margin: "10px 0 20px 0",
								padding: "15px 0",
							}}
						>
							<Typography>农历</Typography>
							<Stack direction="row" spacing={2} sx={{ width: "100%" }}>
								{!this.state.isLoadingNianOptions && (
									<AutoComplete
										options={this.state.nianOptions}
										value={this.state.nian_ID}
										onChange={this.onChangeDate}
										optionLabel="nian"
										optionValue="ID"
										name="nian_ID"
										label="年"
										error={this.state.error.nian_ID}
									/>
								)}
								{!this.state.isLoadingYueOptions && (
									<AutoComplete
										options={this.state.yueOptions}
										value={this.state.yue_ID}
										onChange={this.onChangeDate}
										optionLabel="yue"
										optionValue="ID"
										name="yue_ID"
										label="月"
										error={this.state.error.yue_ID}
									/>
								)}
							</Stack>
							<Stack direction="row" spacing={2} sx={{ width: "100%" }}>
								{!this.state.isLoadingRiOptions && (
									<AutoComplete
										options={this.state.riOptions}
										value={this.state.ri_ID}
										onChange={this.onChangeDate}
										optionLabel="ri"
										optionValue="ID"
										name="ri_ID"
										label="日"
										error={this.state.error.ri_ID}
									/>
								)}
								{!this.state.isLoadingShichenOptions && (
									<AutoComplete
										options={this.state.shichenOptions}
										value={this.state.shichen_ID}
										onChange={this.onChangeDate}
										optionLabel="shichen"
										optionValue="ID"
										name="shichen_ID"
										label="时辰"
										error={this.state.error.shichen_ID}
									/>
								)}
							</Stack>
						</Stack>
						<Typography sx={{ marginBottom: "15px", textAlign: "center", width: "100%" }}>阳历</Typography>
						<Stack direction={{ xs: "column", sm: "row" }} spacing={3} alignItems="center" justifyContent="center">
							<LocalizationProvider dateAdapter={AdapterDateFns}>
								<DatePicker
									label="选择日期"
									format="dd/MM/yyyy"
									value={this.state.pickedDate}
									onChange={this.handleChangeSolarDate}
								/>
							</LocalizationProvider>
							<Typography>
								{this.state.lunarYear}年 {this.state.lunarMonth}月 {this.state.lunarDay}
							</Typography>
						</Stack>
					</Stack>
				</Paper>
				<Stack direction="row" justifyContent="center" spacing={2}>
					<Button variant="contained" onClick={this.calculateLiangshu}>
						结果
					</Button>
					<Button variant="outlined" onClick={this.clearLiangshu}>
						清空
					</Button>
				</Stack>
				<ChengguResult
					showChengguResult={this.state.showChengguResult}
					liangshu={this.state.liangshu}
					ming={this.state.ming}
					gejue1={this.state.gejue1}
					gejue2={this.state.gejue2}
					zhujie={this.state.zhujie}
				/>
			</Stack>
		);
	}
}

function ChengguResult(props) {
	const { theme } = useTheme();

	return (
		<>
			{props.showChengguResult && (
				// <Paper elevation={4} sx={{ p: 2 }}>
				<TableContainer component={Paper}>
					<Table className={`liangshu_table ${theme}`} size="small">
						<TableBody>
							<TableRow>
								<TableCell align="center" sx={{ width: "20%" }}>
									两数
								</TableCell>
								<TableCell>{props.liangshu}</TableCell>
							</TableRow>
							<TableRow>
								<TableCell align="center">命</TableCell>
								<TableCell>{props.ming}</TableCell>
							</TableRow>
							<TableRow>
								<TableCell align="center">歌诀</TableCell>
								<TableCell>
									{props.gejue1}
									<br />
									{props.gejue2}
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell align="center">注解</TableCell>
								<TableCell>{props.zhujie}</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</TableContainer>
				// </Paper>
			)}
		</>
	);
}

function ChengguDialog(props) {
	return (
		<Dialog open={props.state.openDialog1} fullWidth={true} maxWidth="sm" onClose={props.handleCloseDialog1}>
			<DialogContent sx={{ p: 2 }}>
				<Stack direction="column" spacing={2} sx={{ p: 2 }}>
					<Stack direction="row" spacing={1}>
						<IconButton variant="outlined" disabled={props.state.result_index === 0} onClick={props.onDeductLiangshu}>
							<ArrowLeftIcon />
						</IconButton>
						<FormControl fullWidth size="small">
							<InputLabel id="liangshu-select-label">两数</InputLabel>
							<Select labelId="liangshu-select-label" label="两数" value={props.state.result_index} onChange={props.onChangeLiangshu}>
								{props.state.openDialog1 &&
									props.state.chenggu_results.map((result, index) => (
										<MenuItem key={index} value={index}>
											{result.liangshu}
										</MenuItem>
									))}
							</Select>
						</FormControl>
						<IconButton variant="outlined" disabled={props.state.result_index === 51} onClick={props.onAddLiangshu}>
							<ArrowRightIcon />
						</IconButton>
					</Stack>
					<TableContainer component={Paper}>
						<Table className="liangshu_table">
							<TableBody>
								<TableRow>
									<TableCell sx={{ width: "5vh" }}>两数</TableCell>
									<TableCell>
										{props.state.chenggu_results ? props.state.chenggu_results[props.state.result_index].liangshu : ""}
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell>命</TableCell>
									<TableCell>
										{props.state.chenggu_results ? props.state.chenggu_results[props.state.result_index].ming : ""}
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell>歌诀</TableCell>
									<TableCell>
										{props.state.chenggu_results ? props.state.chenggu_results[props.state.result_index].gejue1 : ""}
										<br />
										{props.state.chenggu_results ? props.state.chenggu_results[props.state.result_index].gejue2 : ""}
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell>注解</TableCell>
									<TableCell>
										{props.state.chenggu_results ? props.state.chenggu_results[props.state.result_index].zhujie : ""}
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</TableContainer>
				</Stack>
			</DialogContent>
			<DialogActions>
				<Tooltip title="Close" TransitionComponent={Zoom} arrow>
					<Button variant="outlined" onClick={props.handleCloseDialog1}>
						<CloseIcon />
					</Button>
				</Tooltip>
			</DialogActions>
		</Dialog>
	);
}

function ZhongLiangDialog(props) {
	return (
		<Dialog open={props.state.openDialog2}>
			<DialogContent></DialogContent>
			<DialogActions>
				<Tooltip title="Close" TransitionComponent={Zoom} arrow>
					<Button variant="outlined" onClick={props.handleCloseDialog2}>
						<CloseIcon />
					</Button>
				</Tooltip>
			</DialogActions>
		</Dialog>
	);
}

export default ChengGu;
