class Format {
	left_pad(value, length, char) {
		return value.toString().padStart(length, char);
	}

	right_pad(value, length, char) {
		return value.toString().padEnd(length, char);
	}
}

export default new Format();
