import { Paper, Stack, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import { useState } from "react";

function NewStuff() {
	const [state, setState] = useState({
		pickedDateTime: new Date(),
		value1: 0,
		value2: 0,
		value3: 0,
		value4: 0,
		value1combi: "",
		value2combi: "",
		value3combi: "",
		value4combi: "",
		numberResult: ["魔法师", "女魔法师", "皇后", "皇帝", "男教皇", "恋人", "战车", "力量", "隐士"],
	});

	const handleChangeDateTime = (e) => {
		const year = e.getFullYear().toString();
		const month = (e.getMonth() + 1).toString();
		const date = e.getDate().toString();
		const time = e.getHours().toString() + e.getMinutes().toString();

		var val1combi = "";
		var val2combi = "";
		var val3combi = "";
		var val4combi = "";

		var val1 = 0;
		var val2 = 0;
		var val3 = 0;
		var val4 = 0;

		// val1
		for (var a = 0; a < year.length; a++) {
			val1 += parseInt(year[a]);
		}

		if (val1 < 10) {
			val1combi = val1.toString().padStart(2, "0");
		}

		while (val1.toString().length > 1) {
			const temp = val1.toString();
			val1combi = temp;
			val1 = 0;

			for (var a2 = 0; a2 < temp.length; a2++) {
				val1 += parseInt(temp[a2]);
			}
		}

		// val2
		for (var b = 0; b < month.length; b++) {
			val2 += parseInt(month[b]);
		}

		if (val2 < 10) {
			val2combi = val2.toString().padStart(2, "0");
		}

		while (val2.toString().length > 1) {
			const temp = val2.toString();
			val2combi = temp;
			val2 = 0;

			for (var b2 = 0; b2 < temp.length; b2++) {
				val2 += parseInt(temp[b2]);
			}
		}

		// val3
		for (var c = 0; c < date.length; c++) {
			val3 += parseInt(date[c]);
		}

		if (val3 < 10) {
			val3combi = val3.toString().padStart(2, "0");
		}

		while (val3.toString().length > 1) {
			const temp = val3.toString();
			val3combi = temp;
			val3 = 0;

			for (var c2 = 0; c2 < temp.length; c2++) {
				val3 += parseInt(temp[c2]);
			}
		}

		// val4
		for (var d = 0; d < time.length; d++) {
			val4 += parseInt(time[d]);
		}

		if (val4 < 10) {
			val4combi = val4.toString().padStart(2, "0");
		}

		while (val4.toString().length > 1) {
			const temp = val4.toString();
			val4combi = temp;
			val4 = 0;

			for (var d2 = 0; d2 < temp.length; d2++) {
				val4 += parseInt(temp[d2]);
			}
		}

		setState({
			...state,
			pickedDateTime: e,
			value1: val1,
			value2: val2,
			value3: val3,
			value4: val4,
			value1combi: val1combi,
			value2combi: val2combi,
			value3combi: val3combi,
			value4combi: val4combi,
		});
	};

	return (
		<Stack direction="column" spacing={2}>
			<Paper elevation={4} sx={{ p: 2 }}>
				<Stack direction="column" spacing={2}>
					<Stack direction="row" spacing={2} justifyContent="center" alignItems="center">
						<Typography>生日日期和時間</Typography>
						<LocalizationProvider dateAdapter={AdapterDateFns}>
							<DateTimePicker
								format="dd/MM/yyyy hh:mm aa"
								viewRenderers={{
									hours: renderTimeViewClock,
									minutes: renderTimeViewClock,
									seconds: renderTimeViewClock,
								}}
								value={state.pickedDateTime}
								onChange={handleChangeDateTime}
							/>
						</LocalizationProvider>
					</Stack>
					<TableContainer component={Paper}>
						<Table>
							<TableBody>
								<TableRow>
									<TableCell sx={{ textAlign: "center" }}>{state.value1}</TableCell>
									<TableCell sx={{ textAlign: "center" }}>{state.numberResult[state.value1 - 1]}</TableCell>
									<TableCell sx={{ textAlign: "center" }}>
										{state.value1combi !== "" ? `${state.value1combi[0]} + ${state.value1combi[1]}` : ""}
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell sx={{ textAlign: "center" }}>{state.value2}</TableCell>
									<TableCell sx={{ textAlign: "center" }}>{state.numberResult[state.value2 - 1]}</TableCell>
									<TableCell sx={{ textAlign: "center" }}>
										{state.value2combi !== "" ? `${state.value2combi[0]} + ${state.value2combi[1]}` : ""}
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell sx={{ textAlign: "center" }}>{state.value3}</TableCell>
									<TableCell sx={{ textAlign: "center" }}>{state.numberResult[state.value3 - 1]}</TableCell>
									<TableCell sx={{ textAlign: "center" }}>
										{state.value3combi !== "" ? `${state.value3combi[0]} + ${state.value3combi[1]}` : ""}
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell sx={{ textAlign: "center" }}>{state.value4}</TableCell>
									<TableCell sx={{ textAlign: "center" }}>{state.numberResult[state.value4 - 1]}</TableCell>
									<TableCell sx={{ textAlign: "center" }}>
										{state.value4combi !== "" ? `${state.value4combi[0]} + ${state.value4combi[1]}` : ""}
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</TableContainer>
				</Stack>
			</Paper>
			{/* <Paper elevation={4} sx={{ p: 2 }}> */}
			<TableContainer component={Paper}>
				<Table size="small">
					<TableBody>
						{state.numberResult.map((val, index) => (
							<TableRow key={index}>
								<TableCell>{index + 1}</TableCell>
								<TableCell>{val}</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
			{/* </Paper> */}
		</Stack>
	);
}

export default NewStuff;
