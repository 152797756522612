import Format from "./Format";

export const displayDate = (e) => {
	try {
		const datestamp = new Date(Date.parse(e));

		var date = Format.left_pad(datestamp.getDate(), 2, "0");
		var month = Format.left_pad(datestamp.getMonth() + 1, 2, "0");
		var year = datestamp.getFullYear();

		return date + "/" + month + "/" + year;
	} catch (e) {
		return e;
	}
};

export const displayDateTime = (e) => {
	try {
		const datetimestamp = new Date(Date.parse(e));

		var date = Format.left_pad(datetimestamp.getDate(), 2, "0");
		var month = Format.left_pad(datetimestamp.getMonth() + 1, 2, "0");
		var year = datetimestamp.getFullYear();
		var hour = Format.left_pad(datetimestamp.getHours(), 2, "0");
		var minute = Format.left_pad(datetimestamp.getMinutes(), 2, "0");

		return date + "/" + month + "/" + year + " " + hour + ":" + minute;
	} catch (e) {
		return e;
	}
};
