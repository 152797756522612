import { Box, Divider, FormControl, FormHelperText, Paper, Stack, TextField, Typography } from "@mui/material";
import { Solar } from "lunar-javascript";
import { useEffect, useState } from "react";
import DatePicker from "react-mobile-datepicker";

import { MuiButton, MuiPhoneNumber, SnackBar } from "../components/common";
import { useAuth } from "../hooks/useAuth";
import { useTheme } from "../hooks/useTheme";
import { displayDate } from "../utils";
import { dateConfig } from "../utils/constant";

function UserProfile(props) {
	const { theme } = useTheme();
	const { user } = useAuth();

	const [refresh, setRefresh] = useState(false);
	const [state, setState] = useState({
		name: "",
		nameZh: "",
		email: "",
		contact: "",
		dob: new Date(),
		password: "",
		confirmPassword: "",
		confirmPasswordHelperText: "",
		nameError: false,
		nameZhError: false,
		emailError: false,
		contactError: false,
		dobError: false,
		passwordError: false,
		confirmPasswordError: false,
		openDateModal: false,
	});

	const [snackbar, setSnackbar] = useState({ open: false, vertical: "bottom", horizontal: "center", type: "info", message: "" });
	const showSnackbarMessage = (type, message) => setSnackbar({ ...snackbar, open: true, type, message });
	const handleCloseSnackbar = () => setSnackbar({ ...snackbar, open: false });

	const handleChangeDate = (e) => {
		setState({ ...state, openDateModal: false, dob: e });
	};

	const checkConfirmPassword = (password, confirmPassword) => {
		if (password !== "" && confirmPassword !== "") {
			if (password === confirmPassword) {
				setState({ ...state, password, confirmPassword, passwordError: false, confirmPasswordError: false, confirmPasswordHelperText: "" });
			} else {
				setState({
					...state,
					password,
					confirmPassword,
					passwordError: false,
					confirmPasswordError: true,
					confirmPasswordHelperText: "Password doesn't match",
				});
			}
		} else {
			setState({ ...state, password, confirmPassword, passwordError: false, confirmPasswordError: false, confirmPasswordHelperText: "" });
		}
	};

	const handleSaveProfile = () => {
		if (
			!state.nameError &&
			!state.nameZhError &&
			!state.emailError &&
			!state.contactError &&
			!state.dobError &&
			!state.passwordError &&
			!state.confirmPasswordError
		) {
			const year = state.dob.getFullYear();
			const month = state.dob.getMonth() + 1;
			const date = state.dob.getDate();

			var solar = Solar.fromDate(new Date(year, month - 1, date));
			const xingzuo = solar.getXingZuo();

			fetch("https://tianshu.estoneweb.com/api/user/update_user_profile.php", {
				method: "POST",
				body: JSON.stringify({
					username: user.username,
					role: user.role,
					name: state.name,
					nameZh: state.nameZh,
					email: state.email,
					contact: state.contact,
					dob_year: year,
					dob_month: month,
					dob_date: date,
					xingzuo: xingzuo,
					password: state.password === "" && state.confirmPassword === "" ? "" : state.password,
				}),
			})
				.then((res) => res.json())
				.then((result) => {
					console.log(result);
					if (result["success"]) {
						showSnackbarMessage("success", "资料已保存");
					} else {
						showSnackbarMessage("error", "Kindly contact administrator");
					}
				})
				.catch((error) => console.error(error));
		}
	};

	useEffect(() => {
		fetch("https://tianshu.estoneweb.com/api/user/get_user_profile.php", {
			method: "POST",
			body: JSON.stringify({
				username: user.username,
				email: user.email,
				role: user.role,
			}),
		})
			.then((res) => res.json())
			.then((result) => {
				let dob = new Date(result["dob"]);

				setState({
					...state,
					name: result["name"],
					nameZh: result["nameZh"],
					email: result["email"],
					contact: result["contact"],
					dob: dob,
				});
			})
			.catch((error) => console.error(error));
	}, [refresh, user]);

	return (
		<Box sx={{ px: 2 }}>
			<SnackBar snackbar={snackbar} handleCloseSnackbar={handleCloseSnackbar} />
			<Stack direction="column" alignItems="center" justifyContent="flex-start">
				<Stack direction="column" spcing={2} sx={{ width: { xs: "100%", sm: "85%", md: "75%", lg: "60%", xl: "50%" } }}>
					<Paper elevation={4} sx={{ p: 2, mb: 2 }}>
						<Stack direction="column" spacing={2}>
							<div className="row align-items-center">
								<div className="col-md-3 col-sm-12 text-md-end text-sm-start">英文名字</div>
								<div className="col-md-9 col-sm-12">
									<TextField
										fullWidth={true}
										variant="outlined"
										size="small"
										value={state.name}
										error={state.nameError}
										onChange={(e) => setState({ ...state, name: e.target.value, nameError: false })}
									/>
								</div>
							</div>
							<div className="row align-items-center">
								<div className="col-md-3 col-sm-12 text-md-end text-sm-start">中文名字</div>
								<div className="col-md-9 col-sm-12">
									<TextField
										fullWidth={true}
										variant="outlined"
										size="small"
										value={state.nameZh}
										error={state.nameZhError}
										onChange={(e) => setState({ ...state, nameZh: e.target.value, nameZhError: false })}
									/>
								</div>
							</div>
							<div className="row align-items-center">
								<div className="col-md-3 col-sm-12 text-md-end text-sm-start">邮件地址</div>
								<div className="col-md-9 col-sm-12">
									<TextField
										fullWidth={true}
										variant="outlined"
										size="small"
										value={state.email}
										error={state.emailError}
										onChange={(e) => setState({ ...state, email: e.target.value, emailError: false })}
									/>
								</div>
							</div>
							<div className="row align-items-center">
								<div className="col-md-3 col-sm-12 text-md-end text-sm-start">电话号码</div>
								<div className="col-md-9 col-sm-12">
									<MuiPhoneNumber
										variant="outlined"
										value={state.contact}
										error={state.contactError}
										onChange={(value) => setState({ ...state, contact: value, contactError: false })}
									/>
								</div>
							</div>
							<div className="row align-items-center">
								<div className="col-md-3 col-sm-12 text-md-end text-sm-start">生日日期</div>
								<div className="col-md-9 col-sm-12">
									<Stack direction="row" alignItems="center" justifyContent="space-between">
										<Typography>{displayDate(state.dob)}</Typography>
										<MuiButton
											variant="outlined"
											content="选择日期"
											onClick={() => setState({ ...state, openDateModal: true })}
										/>
										<DatePicker
											theme={theme === "dark" ? "android-dark" : "android"}
											showCaption={true}
											min={new Date(1900, 0, 1)}
											dateConfig={dateConfig}
											headerFormat="DD/MMM/YYYY"
											isOpen={state.openDateModal}
											value={state.dob}
											onSelect={handleChangeDate}
											onCancel={() => setState({ ...state, openDateModal: false })}
										/>
									</Stack>
								</div>
							</div>
							<Divider sx={{ borderWidth: "1px", borderColor: theme === "dark" ? "#ddd" : "#333" }} />
							<div className="row align-items-center">
								<div className="col-md-3 col-sm-12 text-md-end text-sm-start">密码</div>
								<div className="col-md-9 col-sm-12">
									<TextField
										fullWidth={true}
										variant="outlined"
										type="password"
										size="small"
										value={state.password}
										error={state.passwordError}
										onChange={(e) => checkConfirmPassword(e.target.value, state.confirmPassword)}
									/>
								</div>
							</div>
							<div className="row align-items-center">
								<div className="col-md-3 col-sm-12 text-md-end text-sm-start">确认密码</div>
								<div className="col-md-9 col-sm-12">
									<FormControl fullWidth={true}>
										<TextField
											fullWidth={true}
											variant="outlined"
											type="password"
											size="small"
											value={state.confirmPassword}
											error={state.confirmPasswordError}
											onChange={(e) => checkConfirmPassword(state.password, e.target.value)}
										/>
										<FormHelperText>{state.confirmPasswordHelperText}</FormHelperText>
									</FormControl>
								</div>
							</div>
							<Stack direction="row" spacing={2} justifyContent="center">
								<MuiButton variant="contained" content="保存" onClick={handleSaveProfile} />
								<MuiButton variant="outlined" content="取消" onClick={() => setRefresh(!refresh)} />
							</Stack>
						</Stack>
					</Paper>
				</Stack>
			</Stack>
		</Box>
	);
}

export default UserProfile;
