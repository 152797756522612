import { Box, Grid, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";

import { MuiButton, SnackBar } from "./components/common";
import { useAuth } from "./hooks/useAuth";
import { useTheme } from "./hooks/useTheme";

// import logo from "./logo.svg";

function App(props) {
	const navigate = useNavigate();
	const { theme } = useTheme();
	const { user } = useAuth();

	const [snackbar, setSnackbar] = useState({ open: false, vertical: "bottom", horizontal: "center", type: "info", message: "" });
	const showSnackbarMessage = (type, message) => setSnackbar({ ...snackbar, open: true, type, message });
	const handleCloseSnackbar = () => setSnackbar({ ...snackbar, open: false });

	const [modules, setModules] = useState([]);

	const buttonStyle = { fontSize: "18px", px: 2, width: "100%" };

	useEffect(() => {
		fetch("https://tianshu.estoneweb.com/api/get_all_active_modules.php", {
			method: "POST",
		})
			.then((res) => res.json())
			.then((result) => setModules(result))
			.catch((error) => console.error(error));
	}, []);

	return (
		<div className={theme === "dark" ? "bg-black" : "bg-white"}>
			<SnackBar snackbar={snackbar} handleCloseSnackbar={handleCloseSnackbar} />
			{/* <img src={logo} className="App-logo" alt="logo" style={{ margin: "10px 0" }} /> */}
			<Stack direction="column" alignItems="center" justifyContent="flex-start">
				<Stack direction="column" spacing={2} sx={{ width: { xs: "100%", sm: "85%", md: "75%", lg: "60%", xl: "50%" } }}>
					<Box sx={{ px: 1 }}>
						<Grid container spacing={2} justifyContent="center">
							<Grid item>
								<MuiButton
									variant={window.location.pathname.includes("/mine") ? "contained" : "outlined"}
									content="我的塔罗密码"
									buttonStyle={buttonStyle}
									onClick={() => navigate("/dashboard/mine")}
								/>
							</Grid>
							{modules.map((module, index) => {
								return module.access.includes(user.role) ? (
									<Grid item key={index}>
										<MuiButton
											variant={window.location.pathname.includes(module.link) ? "contained" : "outlined"}
											content={module.nameZh}
											buttonStyle={buttonStyle}
											onClick={() => navigate(module.link)}
										/>
									</Grid>
								) : null;
							})}
						</Grid>
					</Box>
					<Box sx={{ px: 2 }}>
						<Outlet />
					</Box>
				</Stack>
			</Stack>
		</div>
	);
}

export default App;
