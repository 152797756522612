import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Paper, Stack, Tooltip, Typography, Zoom } from "@mui/material";
import { Column, DataGrid, Pager, Paging, Selection, Sorting } from "devextreme-react/data-grid";
import { Solar } from "lunar-javascript";
import { useEffect, useState } from "react";

import { CardDetails } from "../components/common";
import { CloseIcon } from "../components/Icon";
import { useTheme } from "../hooks/useTheme";
import eyeblack from "../images/taluomima/eye-black.png";
import eyewhite from "../images/taluomima/eye-white.png";
import { displayDate, displayDateTime } from "../utils";
import { allXingZuo, taluomimaDesc } from "../utils/constant";

function Users(props) {
	const { theme } = useTheme();

	const [users, setUsers] = useState([]);
	const [result, setResult] = useState({
		name: "",
		nameZh: "",
		dob: new Date(),
		result1: 0,
		result2: 0,
		result3: 0,
		xingzuo: "",
		count: [0, 0, 0, 0, 0, 0, 0, 0, 0],
	});
	const [state, setState] = useState({
		openDialog: false,
	});

	const handleCloseDialog = () => {
		setState({ ...state, openDialog: false });
	};

	useEffect(() => {
		fetch("https://tianshu.estoneweb.com/api/admin/get_all_users.php", {
			method: "POST",
		})
			.then((res) => res.json())
			.then((result) => {
				// console.log(result);
				setUsers(result);
			})
			.catch((error) => console.error(error));
	}, []);

	const onRowClick = (e) => {
		let dob = new Date(e.key.dob);

		const year = dob.getFullYear();
		const month = dob.getMonth() + 1;
		const date = dob.getDate();

		var solar = Solar.fromDate(new Date(parseInt(year), parseInt(month) - 1, parseInt(date)));
		const xingzuo = solar.getXingZuo();

		fetch("https://tianshu.estoneweb.com/api/taluomima/calculate.php", {
			method: "POST",
			body: JSON.stringify({
				year: year,
				month: month,
				date: date,
				xingzuo: xingzuo,
			}),
		})
			.then((res) => res.json())
			.then((result) => {
				setState({ ...state, openDialog: true });
				setResult({
					name: e.key.name,
					nameZh: e.key.nameZh,
					dob: dob,
					result1: result[0],
					result2: result[1],
					result3: result[2],
					xingzuo: xingzuo + "座 " + allXingZuo.find(({ name_zh }) => name_zh === xingzuo + "座").name,
					count: result[3],
				});
			})
			.catch((error) => console.error(error));
	};

	return (
		<Paper elevation={4} sx={{ p: 2, mb: 2 }}>
			<DataGrid dataSource={users} showBorders={true} columnAutoWidth={true} hoverStateEnabled={true} onRowClick={onRowClick}>
				<Sorting mode="multiple" />
				<Selection mode="multiple" selectAllMode="page" />
				<Paging enabled={true} defaultPageSize={20} />
				<Column dataField="username" caption="用户名" />
				<Column dataField="name" caption="英文名字" />
				<Column dataField="nameZh" caption="中文名字" />
				<Column dataField="email" caption="邮件地址" />
				<Column dataField="contact" caption="电话号码" />
				<Column dataField="dob" caption="生日日期" cellRender={(cellInfo) => displayDate(cellInfo.value)} />
				<Column dataField="last_login" caption="最後登入时间" cellRender={(cellInfo) => displayDateTime(cellInfo.value)} />
				<Column dataField="registered" caption="注册时间" cellRender={(cellInfo) => displayDateTime(cellInfo.value)} />
				<Column dataField="role" caption="权限" />
				<Pager
					visible={true}
					showPageSizeSelector={true}
					showNavigationButtons={true}
					showInfo={true}
					infoText="Page {0} of {1} ({2} items)"
				/>
			</DataGrid>
			<ResultDialog openDialog={state.openDialog} handleCloseDialog={handleCloseDialog} theme={theme} result={result} />
		</Paper>
	);
}

function ResultDialog(props) {
	const [state, setState] = useState({
		openDialog: false,
		numberToShow: 0,
	});

	const stackStyleSx = {
		borderRadius: 3.5,
		px: 1.5,
		py: 0.5,
		transition: "all 0.5s ease",
		"&:hover": {
			cursor: "pointer",
			boxShadow: "0px 0px 15px #aaaaaa",
		},
	};

	const getBackgroundColor = (index, count) => {
		return count >= index ? (props.theme === "dark" ? "grey" : "darkslategrey") : "transparent";
	};

	const handleCloseDialog = () => {
		setState({ ...state, openDialog: false });
	};

	const showCard = (num) => {
		setState({ ...state, openDialog: true, numberToShow: num });
	};

	return (
		<Dialog open={props.openDialog} fullWidth={true} maxWidth="md" onClose={props.handleCloseDialog}>
			<DialogTitle>
				{props.result.name} {props.result.nameZh} ({displayDate(props.result.dob)})
			</DialogTitle>
			<DialogContent>
				<Stack direction="column" spacing={2} alignItems="center" sx={{ py: 1 }}>
					<Stack direction="column" spacing={0} alignItems="center">
						{props.result.result1 !== 0 && (
							<Stack direction="column" alignItems="center" sx={stackStyleSx} onClick={() => showCard(props.result.result1)}>
								<Typography variant="h6">生命</Typography>
								<Typography variant="h5">{props.result.result1}</Typography>
							</Stack>
						)}
						<Stack direction="row" alignItems="flex-end" justifyContent="center" spacing={0}>
							{props.result.result3 !== 0 && (
								<Stack direction="column" alignItems="center" sx={stackStyleSx} onClick={() => showCard(props.result.result3)}>
									<Typography variant="h6">行为</Typography>
									<Typography variant="h5">{props.result.result3}</Typography>
								</Stack>
							)}
							<img src={props.theme === "dark" ? eyewhite : eyeblack} className="eye-logo" alt="logo" />
							{props.result.result2 !== 0 && (
								<Stack direction="column" alignItems="center" sx={stackStyleSx} onClick={() => showCard(props.result.result2)}>
									<Typography variant="h6">后天</Typography>
									<Typography variant="h5">{props.result.result2}</Typography>
								</Stack>
							)}
						</Stack>
					</Stack>
					<CardDialog openDialog={state.openDialog} handleCloseDialog={handleCloseDialog} numberToShow={state.numberToShow} />
					{props.result.xingzuo !== "" && <Typography>{props.result.xingzuo}</Typography>}
					<table className="table-cell-outlined">
						<tbody>
							{props.result.count.map((cnt, index) => (
								<tr key={index}>
									<td style={{ textAlign: "center" }}>{index + 1}</td>
									<td style={{ backgroundColor: getBackgroundColor(1, props.result.count[index]) }}></td>
									<td style={{ backgroundColor: getBackgroundColor(2, props.result.count[index]) }}></td>
									<td style={{ backgroundColor: getBackgroundColor(3, props.result.count[index]) }}></td>
									<td style={{ backgroundColor: getBackgroundColor(4, props.result.count[index]) }}></td>
									<td style={{ backgroundColor: getBackgroundColor(5, props.result.count[index]) }}></td>
									<td style={{ backgroundColor: getBackgroundColor(6, props.result.count[index]) }}></td>
									<td style={{ backgroundColor: getBackgroundColor(7, props.result.count[index]) }}></td>
									<td style={{ backgroundColor: getBackgroundColor(8, props.result.count[index]) }}></td>
									<td style={{ backgroundColor: getBackgroundColor(9, props.result.count[index]) }}></td>
								</tr>
							))}
						</tbody>
					</table>
				</Stack>
			</DialogContent>
			<DialogActions>
				<Tooltip title="关闭" TransitionComponent={Zoom} arrow>
					<Button variant="outlined" onClick={props.handleCloseDialog}>
						<CloseIcon />
					</Button>
				</Tooltip>
			</DialogActions>
		</Dialog>
	);
}

function CardDialog(props) {
	const [state, setState] = useState({
		number: 0,
		name_zh: "",
		name: "",
		image: null,
		title: "",
		gongke: "",
		mubiao: "",
		goutong: "",
		qianneng: "",
		guihua: "",
		xiaohai: "",
	});

	useEffect(() => {
		if (props.numberToShow !== 0) {
			const result = taluomimaDesc.find(({ number }) => number === props.numberToShow);
			setState(result);
		}
	}, [props]);

	return (
		<Dialog open={props.openDialog} fullWidth={true} maxWidth="md" onClose={props.handleCloseDialog}>
			<DialogTitle>
				{state.number} - {state.name_zh} {state.name}
			</DialogTitle>
			<DialogContent sx={{ px: 3 }}>
				<Stack direction={{ xs: "column", md: "row" }} spacing={2} alignItems="center">
					<Stack direction="row" justifyContent="center">
						{state.image !== null && state.image !== "" && (
							<img src={state.image} className={`image-${props.numberToShow}`} alt="logo" style={{ xs: { width: "50%" } }} />
						)}
					</Stack>
					<CardDetails
						title={state.title}
						gongke={state.gongke}
						mubiao={state.mubiao}
						goutong={state.goutong}
						qianneng={state.qianneng}
						guihua={state.guihua}
						xiaohai={state.xiaohai}
					/>
				</Stack>
			</DialogContent>
			<DialogActions>
				<Tooltip title="关闭" TransitionComponent={Zoom} arrow>
					<Button variant="outlined" onClick={props.handleCloseDialog}>
						<CloseIcon />
					</Button>
				</Tooltip>
			</DialogActions>
		</Dialog>
	);
}

export default Users;
