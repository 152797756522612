import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, Paper, Stack, Tooltip, Typography, Zoom } from "@mui/material";
import { useState } from "react";

import { MuiButton } from "../../components/common";
import { CloseFullScreenIcon, CloseIcon, OpenFullScreenIcon, RotateIcon } from "../../components/Icon";
import { useTheme } from "../../hooks/useTheme";
import { cupsImages, majorArcanaDesc, majorArcanaImages, pentaclesImages, swordsImages, wandsImages } from "../../utils/constant";

function TarotCards() {
	const { theme } = useTheme();

	const [state, setState] = useState({
		openMajorArcanaDialog: false,
		openMinorArcanaDialog: false,
		majorCardIndex: null,
		minorCardIndex: null,
	});

	const openMajorArcana = (index) => setState({ ...state, openMajorArcanaDialog: true, majorCardIndex: index });
	const handleCloseMajorArcanaDialog = () => setState({ ...state, openMajorArcanaDialog: false, majorCardIndex: null });

	const openMinorArcana = (index) => setState({ ...state, openMinorArcanaDialog: true, minorCardIndex: index });
	const handleCloseMinorArcanaDialog = () => setState({ ...state, openMinorArcanaDialog: false, minorCardIndex: null });

	return (
		<Stack direction="column" spacing={2}>
			<Paper elevation={4} sx={{ p: 3 }}>
				<Stack direction="column">
					<Typography variant="h5" sx={{ textAlign: "center", mb: 2 }}>
						Major Arcana 大阿卡那
					</Typography>
					<Grid container spacing={2} justifyContent="center">
						{majorArcanaImages.map((tarot, index) => (
							<Grid item key={index} sx={{ width: { xs: "50%", sm: "30%", md: "25%", lg: "20%" } }}>
								<Stack direction="column" spacing={1} alignItems="center">
									<Typography>{index}</Typography>
									<img
										src={tarot}
										alt={`tarot${index}`}
										style={{ borderRadius: "0.75rem", width: "100%", cursor: "pointer" }}
										onClick={() => openMajorArcana(index)}
									/>
								</Stack>
							</Grid>
						))}
					</Grid>
				</Stack>
				<MajorArcanaDialog
					openMajorArcanaDialog={state.openMajorArcanaDialog}
					handleCloseMajorArcanaDialog={handleCloseMajorArcanaDialog}
					majorCardIndex={state.majorCardIndex}
				/>
			</Paper>
			<Paper elevation={4} sx={{ p: 3 }}>
				<Stack direction="column">
					<Typography variant="h5" sx={{ textAlign: "center", mb: 3 }}>
						Minor Arcana 小阿卡那
					</Typography>
					<Divider sx={{ borderWidth: "1px", borderColor: theme === "dark" ? "#ddd" : "#333" }} />
					<Typography variant="h6" sx={{ textAlign: "center", mt: 3, mb: 2 }}>
						圣杯 Cups
					</Typography>
					<Grid container spacing={2} justifyContent="center" sx={{ mb: 3 }}>
						{cupsImages.map((tarot, index) => (
							<Grid item key={index} sx={{ width: { xs: "50%", sm: "30%", md: "25%", lg: "20%" } }}>
								<Stack direction="column" spacing={1} alignItems="center">
									<Typography>{index + 1}</Typography>
									<img
										src={tarot}
										alt={`tarot${index}`}
										style={{ borderRadius: "0.75rem", width: "100%", cursor: "pointer" }}
										// onClick={() => openMajorArcana(index)}
									/>
								</Stack>
							</Grid>
						))}
					</Grid>
					<Divider sx={{ borderWidth: "1px", borderColor: theme === "dark" ? "#ddd" : "#333" }} />
					<Typography variant="h6" sx={{ textAlign: "center", mt: 3, mb: 2 }}>
						星币 Pentacles
					</Typography>
					<Grid container spacing={2} justifyContent="center" sx={{ mb: 3 }}>
						{pentaclesImages.map((tarot, index) => (
							<Grid item key={index} sx={{ width: { xs: "50%", sm: "30%", md: "25%", lg: "20%" } }}>
								<Stack direction="column" spacing={1} alignItems="center">
									<Typography>{index + 1}</Typography>
									<img
										src={tarot}
										alt={`tarot${index}`}
										style={{ borderRadius: "0.75rem", width: "100%", cursor: "pointer" }}
										// onClick={() => openMajorArcana(index)}
									/>
								</Stack>
							</Grid>
						))}
					</Grid>
					<Divider sx={{ borderWidth: "1px", borderColor: theme === "dark" ? "#ddd" : "#333" }} />
					<Typography variant="h6" sx={{ textAlign: "center", mt: 3, mb: 2 }}>
						宝剑 Swords
					</Typography>
					<Grid container spacing={2} justifyContent="center" sx={{ mb: 3 }}>
						{swordsImages.map((tarot, index) => (
							<Grid item key={index} sx={{ width: { xs: "50%", sm: "30%", md: "25%", lg: "20%" } }}>
								<Stack direction="column" spacing={1} alignItems="center">
									<Typography>{index + 1}</Typography>
									<img
										src={tarot}
										alt={`tarot${index}`}
										style={{ borderRadius: "0.75rem", width: "100%", cursor: "pointer" }}
										// onClick={() => openMajorArcana(index)}
									/>
								</Stack>
							</Grid>
						))}
					</Grid>
					<Divider sx={{ borderWidth: "1px", borderColor: theme === "dark" ? "#ddd" : "#333" }} />
					<Typography variant="h6" sx={{ textAlign: "center", mt: 3, mb: 2 }}>
						权杖 Wands
					</Typography>
					<Grid container spacing={2} justifyContent="center">
						{wandsImages.map((tarot, index) => (
							<Grid item key={index} sx={{ width: { xs: "50%", sm: "30%", md: "25%", lg: "20%" } }}>
								<Stack direction="column" spacing={1} alignItems="center">
									<Typography>{index + 1}</Typography>
									<img
										src={tarot}
										alt={`tarot${index}`}
										style={{ borderRadius: "0.75rem", width: "100%", cursor: "pointer" }}
										// onClick={() => openMajorArcana(index)}
									/>
								</Stack>
							</Grid>
						))}
					</Grid>
				</Stack>
				<MinorArcanaDialog
					openMinorArcanaDialog={state.openMinorArcanaDialog}
					handleCloseMinorArcanaDialog={handleCloseMinorArcanaDialog}
					minorCardIndex={state.minorCardIndex}
				/>
			</Paper>
		</Stack>
	);
}

function MajorArcanaDialog(props) {
	const [rotate, setRotate] = useState(false);
	const rotateCard = () => setRotate(!rotate);

	const [enlarged, setEnlarged] = useState(false);
	const switchEnlarge = () => setEnlarged(!enlarged);

	return (
		<Dialog open={props.openMajorArcanaDialog} fullWidth={true} maxWidth="md" onClose={props.handleCloseMajorArcanaDialog}>
			{!enlarged && <DialogTitle>塔罗牌</DialogTitle>}
			<DialogContent sx={{ px: 3 }}>
				<Stack direction={{ xs: "column", md: "row" }} spacing={2} justifyContent="center" alignItems="center">
					{props.majorCardIndex !== null && (
						<Stack direction="row" spacing={2} justifyContent="center" alignItems="center">
							<Stack direction="column" spacing={2} alignItems="center">
								<Stack direction="row" spacing={1}>
									<MuiButton mode="icon" content={<RotateIcon />} tooltip="Rotate" onClick={rotateCard} />
									<MuiButton
										mode="icon"
										content={enlarged ? <CloseFullScreenIcon /> : <OpenFullScreenIcon />}
										tooltip={enlarged ? "Shrink" : "Enlarge"}
										onClick={switchEnlarge}
									/>
								</Stack>
								<img
									src={majorArcanaDesc[props.majorCardIndex]["image"]}
									alt=""
									style={{
										width: "100%",
										borderRadius: "0.75rem",
										transition: "rotate 0.5s",
										rotate: rotate ? "180deg" : "0deg",
										height: enlarged ? "75vh" : "auto",
									}}
								/>
							</Stack>
							{!enlarged && <CardDetails info={majorArcanaDesc[props.majorCardIndex]} />}
						</Stack>
					)}
				</Stack>
			</DialogContent>
			<DialogActions>
				<Tooltip title="关闭" TransitionComponent={Zoom} arrow>
					<Button
						variant="outlined"
						onClick={() => {
							setRotate(false);
							props.handleCloseMajorArcanaDialog();
						}}
					>
						<CloseIcon />
					</Button>
				</Tooltip>
			</DialogActions>
		</Dialog>
	);
}

function MinorArcanaDialog(props) {
	const [rotate, setRotate] = useState(false);
	const rotateCard = () => setRotate(!rotate);

	const [enlarged, setEnlarged] = useState(false);
	const switchEnlarge = () => setEnlarged(!enlarged);

	return (
		<Dialog open={props.openMinorArcanaDialog} fullWidth={true} maxWidth="md" onClose={props.handleCloseMinorArcanaDialog}>
			{!enlarged && <DialogTitle>塔罗牌</DialogTitle>}
			<DialogContent></DialogContent>
			<DialogActions>
				<Tooltip title="关闭" TransitionComponent={Zoom} arrow>
					<Button
						variant="outlined"
						onClick={() => {
							setRotate(false);
							props.handleCloseMajorArcanaDialog();
						}}
					>
						<CloseIcon />
					</Button>
				</Tooltip>
			</DialogActions>
		</Dialog>
	);
}

function CardDetails(props) {
	return (
		<Stack direction="column" spacing={2}>
			<Typography variant="h5" align="center" sx={{ fontWeight: "bold" }}>
				{props.info.name_zh} {props.info.name}
			</Typography>
			<Stack direction="column">
				<Typography>{props.info.desc}</Typography>
			</Stack>
			<Stack direction="column">
				<Typography sx={{ fontWeight: "bold" }}>正：</Typography>
				<Typography>{props.info.upright}</Typography>
			</Stack>
			<Stack direction="column">
				<Typography sx={{ fontWeight: "bold" }}>反：</Typography>
				<Typography>{props.info.reverse}</Typography>
			</Stack>
		</Stack>
	);
}

export default TarotCards;
