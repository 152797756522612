import { Fragment } from "react";
import { useQuery } from "react-query";
import { Navigate, Outlet, useLocation } from "react-router-dom";

import { LoadingSpinner } from "./components/common";
import { useAuth } from "./hooks/useAuth";
import { advance_roles } from "./utils/constant";

export const ProtectedRoute = ({ redirectPath = "/" }) => {
	const { user } = useAuth();
	const location = useLocation();

	const ModuleAccess = async () => {
		const modules = await fetch("https://tianshu.estoneweb.com/api/admin/get_all_modules.php", {
			method: "POST",
		}).then((res) => res.json());

		for (let i = 0; i < modules.length; i++) {
			let module = modules[i];

			if (location.pathname === module.link && module.access.includes(user.role)) {
				return module.active === 1 ? true : false;
			} else if (location.pathname === module.link && !module.access.includes(user.role)) {
				return false;
			}
		}

		return true;
	};

	const isAllowed = useQuery("access_control", ModuleAccess, { retry: 0 });

	if (!user) {
		return <Navigate to={redirectPath} />;
	} else {
		let adminpage = location.pathname.includes("/adminpanel");

		if (adminpage) {
			return advance_roles.some((role) => user.role.includes(role)) ? <Outlet /> : <Navigate to="/dashboard" />;
		} else if (!adminpage) {
			return (
				<Fragment>
					{isAllowed.status === "loading" && <LoadingSpinner text={false} />}
					{isAllowed.status === "error" && <Navigate to="/dashboard" />}
					{isAllowed.status === "success" && !isAllowed.data && <Navigate to="/dashboard" />}
					{isAllowed.status === "success" && isAllowed.data && <Outlet />}
				</Fragment>
			);
		}
	}
};
