import { Box, Grid, Stack } from "@mui/material";
import { Outlet, useNavigate } from "react-router";

import { MuiButton } from "../components/common";
import Submission from "./Submission";
import UserProfile from "./UserProfile";

function UserPanel() {
	const navigate = useNavigate();

	const buttonStyle = { fontSize: "18px", px: 2, width: "100%" };

	const pages = [
		{ link: "/userpanel/profile", name: "个人资料" },
		{ link: "/userpanel/submission", name: "已提交的" },
	];

	return (
		<Stack direction="column" alignItems="center" justifyContent="flex-start">
			<Stack direction="column" spacing={2} sx={{ width: { xs: "100%", sm: "90%", md: "80%", lg: "70%", xl: "60%" } }}>
				<Box sx={{ p: 1 }}>
					<Grid container spacing={2} justifyContent="center">
						{pages.map((page, index) => (
							<Grid item key={index}>
								<MuiButton
									variant={window.location.pathname === page.link ? "contained" : "outlined"}
									content={page.name}
									buttonStyle={buttonStyle}
									onClick={() => navigate(page.link)}
								/>
							</Grid>
						))}
					</Grid>
				</Box>
				<Box sx={{ px: 2 }}>
					<Outlet />
				</Box>
			</Stack>
		</Stack>
	);
}

export default UserPanel;
export { UserProfile, Submission };
