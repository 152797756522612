import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Paper,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	TextField,
	Tooltip,
	Typography,
	Zoom,
} from "@mui/material";
import { Solar } from "lunar-javascript";
import React, { useEffect, useState } from "react";
import DatePicker from "react-mobile-datepicker";

import { CardDetails, MuiButton, MuiPhoneNumber, RedAsterisk, SnackBar } from "../../components/common";
import { CloseIcon, ExpandMoreIcon } from "../../components/Icon";
import { useAuth } from "../../hooks/useAuth";
import { useTheme } from "../../hooks/useTheme";
import eyeblack from "../../images/taluomima/eye-black.png";
import eyewhite from "../../images/taluomima/eye-white.png";
import { displayDate } from "../../utils";
import { advance_roles, allXingZuo, dateConfig, taluomimaDesc } from "../../utils/constant";
import {
	formula1,
	formula2,
	formula3,
	formula4,
	formula5,
	formula6,
	formula7,
	formula8_1,
	formula8_2,
	formula8_3,
	formula8_4,
	formula8_5,
	getCount,
	resetCount,
	tianfushu,
} from "./formula";

function TaLuoMiMaShu() {
	const { theme } = useTheme();
	const { user } = useAuth();

	const [state, setState] = useState({
		pickedDate: new Date(),
		tianfushu_num: 0,
		num1: 0,
		num2: 0,
		num3: 0,
		num4: 0,
		num5: 0,
		num6: 0,
		num7: 0,
		num8_1: 0,
		num8_2: 0,
		num8_3: 0,
		num8_4: 0,
		num8_5: 0,
		count: [0, 0, 0, 0, 0, 0, 0, 0, 0],
		xingZuo: "",
		result1: 0,
		result2: 0,
		result3: 0,
		openDateModal: false,
		openDialog: false,
		numberToShow: 0,
	});

	const stackStyleSx = {
		borderRadius: 3.5,
		px: 1.5,
		py: 0.5,
		transition: "all 0.5s ease",
		"&:hover": {
			cursor: "pointer",
			boxShadow: "0px 0px 15px #aaaaaa",
		},
	};

	const handleCloseDialog = () => {
		setState({ ...state, openDialog: false });
	};

	const monthMap = {
		1: "Jan",
		2: "Feb",
		3: "Mar",
		4: "Apr",
		5: "May",
		6: "Jun",
		7: "Jul",
		8: "Aug",
		9: "Sep",
		10: "Oct",
		11: "Nov",
		12: "Dec",
	};

	const dateConfig = {
		date: {
			format: "DD",
			caption: "日",
			step: 1,
		},
		month: {
			format: (value) => monthMap[value.getMonth() + 1],
			caption: "月",
			step: 1,
		},
		year: {
			format: "YYYY",
			caption: "年",
			step: 1,
		},
	};

	const handleChangeDate = (e) => {
		resetCount();

		const year = e.getFullYear().toString();
		const month = (e.getMonth() + 1).toString();
		const date = e.getDate().toString();

		var solar = Solar.fromDate(new Date(parseInt(year), parseInt(month) - 1, parseInt(date)));
		const xingzuo = solar.getXingZuo();

		var num1 = formula1(year, month, date);
		var num2 = date < 10 ? formula2(date) : 0;
		var num3 = formula3(xingzuo);
		var num4 = formula4(date);
		var num5 = formula5(year, month, date);
		var num6 = formula6(date);
		var num7 = formula7(month);
		var num8_1 = formula8_1(year);
		var num8_2 = formula8_2(year);
		var num8_3 = formula8_3(year);
		var num8_4 = formula8_4(year);
		var num8_5 = formula8_5(year);

		var tianfushu_num = tianfushu(year, month, date);

		var count = getCount();

		var result1 = 0;
		var result2 = 0;
		var result3 = 0;

		result1 = num1;

		if (num2 !== 0 && num2 !== result1) {
			result2 = num2;

			if (num3 !== result1 && num3 !== result2) {
				result3 = num3;
			} else if (num4 !== 0 && num4 !== result1 && num4 !== result2) {
				result3 = num4;
			} else if (num5 !== 0 && num5 !== result1 && num5 !== result2) {
				result3 = num5;
			} else if (num6 !== result1 && num6 !== result2) {
				result3 = num6;
			} else if (num7 !== result1 && num7 !== result2) {
				result3 = num7;
			} else if (num8_1 !== 0 && num8_1 !== result1 && num8_1 !== result2) {
				result3 = num8_1;
			} else if (num8_2 !== 0 && num8_2 !== result1 && num8_2 !== result2) {
				result3 = num8_2;
			} else if (num8_3 !== 0 && num8_3 !== result1 && num8_3 !== result2) {
				result3 = num8_3;
			} else if (num8_4 !== 0 && num8_4 !== result1 && num8_4 !== result2) {
				result3 = num8_4;
			} else if (num8_5 !== result1 && num8_5 !== result2) {
				result3 = num8_5;
			}
		} else if (num3 !== result1) {
			result2 = num3;

			if (num4 !== 0 && num4 !== result1 && num4 !== result2) {
				result3 = num4;
			} else if (num5 !== 0 && num5 !== result1 && num5 !== result2) {
				result3 = num5;
			} else if (num6 !== result1 && num6 !== result2) {
				result3 = num6;
			} else if (num7 !== result1 && num7 !== result2) {
				result3 = num7;
			} else if (num8_1 !== 0 && num8_1 !== result1 && num8_1 !== result2) {
				result3 = num8_1;
			} else if (num8_2 !== 0 && num8_2 !== result1 && num8_2 !== result2) {
				result3 = num8_2;
			} else if (num8_3 !== 0 && num8_3 !== result1 && num8_3 !== result2) {
				result3 = num8_3;
			} else if (num8_4 !== 0 && num8_4 !== result1 && num8_4 !== result2) {
				result3 = num8_4;
			} else if (num8_5 !== result1 && num8_5 !== result2) {
				result3 = num8_5;
			}
		} else if (num4 !== 0 && num4 !== result1) {
			result2 = num4;

			if (num5 !== 0 && num5 !== result1 && num5 !== result2) {
				result3 = num5;
			} else if (num6 !== result1 && num6 !== result2) {
				result3 = num6;
			} else if (num7 !== result1 && num7 !== result2) {
				result3 = num7;
			} else if (num8_1 !== 0 && num8_1 !== result1 && num8_1 !== result2) {
				result3 = num8_1;
			} else if (num8_2 !== 0 && num8_2 !== result1 && num8_2 !== result2) {
				result3 = num8_2;
			} else if (num8_3 !== 0 && num8_3 !== result1 && num8_3 !== result2) {
				result3 = num8_3;
			} else if (num8_4 !== 0 && num8_4 !== result1 && num8_4 !== result2) {
				result3 = num8_4;
			} else if (num8_5 !== result1 && num8_5 !== result2) {
				result3 = num8_5;
			}
		} else if (num5 !== 0 && num5 !== result1) {
			result2 = num5;

			if (num6 !== result1 && num6 !== result2) {
				result3 = num6;
			} else if (num7 !== result1 && num7 !== result2) {
				result3 = num7;
			} else if (num8_1 !== 0 && num8_1 !== result1 && num8_1 !== result2) {
				result3 = num8_1;
			} else if (num8_2 !== 0 && num8_2 !== result1 && num8_2 !== result2) {
				result3 = num8_2;
			} else if (num8_3 !== 0 && num8_3 !== result1 && num8_3 !== result2) {
				result3 = num8_3;
			} else if (num8_4 !== 0 && num8_4 !== result1 && num8_4 !== result2) {
				result3 = num8_4;
			} else if (num8_5 !== result1 && num8_5 !== result2) {
				result3 = num8_5;
			}
		} else if (num6 !== result1) {
			result2 = num6;

			if (num7 !== result1 && num7 !== result2) {
				result3 = num7;
			} else if (num8_1 !== 0 && num8_1 !== result1 && num8_1 !== result2) {
				result3 = num8_1;
			} else if (num8_2 !== 0 && num8_2 !== result1 && num8_2 !== result2) {
				result3 = num8_2;
			} else if (num8_3 !== 0 && num8_3 !== result1 && num8_3 !== result2) {
				result3 = num8_3;
			} else if (num8_4 !== 0 && num8_4 !== result1 && num8_4 !== result2) {
				result3 = num8_4;
			} else if (num8_5 !== result1 && num8_5 !== result2) {
				result3 = num8_5;
			}
		} else if (num7 !== result1) {
			result2 = num7;

			if (num8_1 !== 0 && num8_1 !== result1 && num8_1 !== result2) {
				result3 = num8_1;
			} else if (num8_2 !== 0 && num8_2 !== result1 && num8_2 !== result2) {
				result3 = num8_2;
			} else if (num8_3 !== 0 && num8_3 !== result1 && num8_3 !== result2) {
				result3 = num8_3;
			} else if (num8_4 !== 0 && num8_4 !== result1 && num8_4 !== result2) {
				result3 = num8_4;
			} else if (num8_5 !== result1 && num8_5 !== result2) {
				result3 = num8_5;
			}
		} else if (num8_1 !== 0 && num8_1 !== result1) {
			result2 = num8_1;

			if (num8_2 !== 0 && num8_2 !== result1 && num8_2 !== result2) {
				result3 = num8_2;
			} else if (num8_3 !== 0 && num8_3 !== result1 && num8_3 !== result2) {
				result3 = num8_3;
			} else if (num8_4 !== 0 && num8_4 !== result1 && num8_4 !== result2) {
				result3 = num8_4;
			} else if (num8_5 !== result1 && num8_5 !== result2) {
				result3 = num8_5;
			}
		} else if (num8_2 !== 0 && num8_2 !== result1) {
			result2 = num8_2;

			if (num8_3 !== 0 && num8_3 !== result1 && num8_3 !== result2) {
				result3 = num8_3;
			} else if (num8_4 !== 0 && num8_4 !== result1 && num8_4 !== result2) {
				result3 = num8_4;
			} else if (num8_5 !== result1 && num8_5 !== result2) {
				result3 = num8_5;
			}
		} else if (num8_3 !== 0 && num8_3 !== result1) {
			result2 = num8_3;

			if (num8_4 !== 0 && num8_4 !== result1 && num8_4 !== result2) {
				result3 = num8_4;
			} else if (num8_5 !== result1 && num8_5 !== result2) {
				result3 = num8_5;
			}
		} else if (num8_4 !== 0 && num8_4 !== result1) {
			result2 = num8_4;

			if (num8_5 !== result1 && num8_5 !== result2) {
				result3 = num8_5;
			}
		}

		setState((prevState) => ({
			...prevState,
			openDateModal: false,
			pickedDate: e,
			count,
			tianfushu_num,
			num1,
			num2,
			num3,
			num4,
			num5,
			num6,
			num7,
			num8_1,
			num8_2,
			num8_3,
			num8_4,
			num8_5,
			result1,
			result2,
			result3,
			xingZuo: xingzuo + "座 " + allXingZuo.find(({ name_zh }) => name_zh === xingzuo + "座").name,
		}));
	};

	const getBackgroundColor = (index, count) => {
		return count >= index ? (theme === "dark" ? "grey" : "darkslategrey") : "transparent";
	};

	const showCard = (num) => {
		setState({ ...state, openDialog: true, numberToShow: num });
	};

	return (
		<Stack direction="column" spacing={2}>
			<SubmitNew />
			{advance_roles.some((role) => user.role.includes(role)) && (
				<>
					<Paper elevation={4} sx={{ px: 1, py: 2 }}>
						<Stack direction="column" spacing={2} alignItems="center">
							<Stack direction="row" spacing={2} alignItems="center">
								<MuiButton
									variant="outlined"
									content={displayDate(state.pickedDate)}
									onClick={() => setState({ ...state, openDateModal: true })}
								/>
								<DatePicker
									theme={theme === "dark" ? "android-dark" : "android"}
									showCaption={true}
									min={new Date(1900, 0, 1)}
									dateConfig={dateConfig}
									headerFormat="DD/MMM/YYYY"
									isOpen={state.openDateModal}
									value={state.pickedDate}
									onSelect={handleChangeDate}
									onCancel={() => setState({ ...state, openDateModal: false })}
								/>
							</Stack>
							<Stack direction="column" spacing={0} alignItems="center">
								{state.result1 !== 0 && (
									<Stack direction="column" alignItems="center" sx={stackStyleSx} onClick={() => showCard(state.result1)}>
										<Typography variant="h6">生命</Typography>
										<Typography variant="h5">{state.result1}</Typography>
									</Stack>
								)}
								<Stack direction="row" alignItems="flex-end" justifyContent="center" spacing={0}>
									{state.result3 !== 0 && (
										<Stack direction="column" alignItems="center" sx={stackStyleSx} onClick={() => showCard(state.result3)}>
											<Typography variant="h6">行为</Typography>
											<Typography variant="h5">{state.result3}</Typography>
										</Stack>
									)}
									<img src={theme === "dark" ? eyewhite : eyeblack} className="eye-logo" alt="logo" />
									{state.result2 !== 0 && (
										<Stack direction="column" alignItems="center" sx={stackStyleSx} onClick={() => showCard(state.result2)}>
											<Typography variant="h6">后天</Typography>
											<Typography variant="h5">{state.result2}</Typography>
										</Stack>
									)}
								</Stack>
							</Stack>
							{state.xingZuo !== "" && <Typography>{state.xingZuo}</Typography>}
							<table className="table-cell-outlined">
								<tbody>
									{state.count.map((cnt, index) => (
										<tr key={index}>
											<td style={{ textAlign: "center", cursor: "pointer" }} onClick={() => showCard(index + 1)}>
												{index + 1}
											</td>
											<td style={{ backgroundColor: getBackgroundColor(1, state.count[index]) }}></td>
											<td style={{ backgroundColor: getBackgroundColor(2, state.count[index]) }}></td>
											<td style={{ backgroundColor: getBackgroundColor(3, state.count[index]) }}></td>
											<td style={{ backgroundColor: getBackgroundColor(4, state.count[index]) }}></td>
											<td style={{ backgroundColor: getBackgroundColor(5, state.count[index]) }}></td>
											<td style={{ backgroundColor: getBackgroundColor(6, state.count[index]) }}></td>
											<td style={{ backgroundColor: getBackgroundColor(7, state.count[index]) }}></td>
											<td style={{ backgroundColor: getBackgroundColor(8, state.count[index]) }}></td>
											<td style={{ backgroundColor: getBackgroundColor(9, state.count[index]) }}></td>
										</tr>
									))}
								</tbody>
							</table>
						</Stack>
					</Paper>
					<CardDialog openDialog={state.openDialog} handleCloseDialog={handleCloseDialog} numberToShow={state.numberToShow} />
					{/* <Paper elevation={4} sx={{ p: 2 }}> */}
					<TableContainer component={Paper}>
						<Table size="small">
							<TableBody>
								<TableRow>
									<TableCell>1. 生命数</TableCell>
									<TableCell>
										{state.num1 !== 0 && state.num1} {state.tianfushu_num !== 0 && `(${state.tianfushu_num})`}
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell>2. 生日数 (1 - 9)</TableCell>
									<TableCell>{state.num2 !== 0 && state.num2}</TableCell>
								</TableRow>
								<TableRow>
									<TableCell>3. 星座</TableCell>
									<TableCell>{state.num3 !== 0 && state.num3}</TableCell>
								</TableRow>
								<TableRow>
									<TableCell>4. 生日第二个号码</TableCell>
									<TableCell>{state.num4 !== 0 && state.num4}</TableCell>
								</TableRow>
								<TableRow>
									<TableCell>5. 天赋数第二个号码</TableCell>
									<TableCell>{state.num5 !== 0 && state.num5}</TableCell>
								</TableRow>
								<TableRow>
									<TableCell>6. 生日加起来的单数</TableCell>
									<TableCell>{state.num6 !== 0 && state.num6}</TableCell>
								</TableRow>
								<TableRow>
									<TableCell>7. 月份</TableCell>
									<TableCell>{state.num7 !== 0 && state.num7}</TableCell>
								</TableRow>
								<TableRow>
									<TableCell>8. 年份后面开始</TableCell>
									<TableCell>
										{state.num8_1 !== 0 && state.num8_1 + " / "}
										{state.num8_2 !== 0 && state.num8_2 + " / "}
										{state.num8_3 !== 0 && state.num8_3 + " / "}
										{state.num8_4 !== 0 && state.num8_4 + " / "}
										{state.num8_5 !== 0 && state.num8_5}
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</TableContainer>
					{/* </Paper> */}
					<Accordion>
						<AccordionSummary expandIcon={<ExpandMoreIcon />}>塔罗 1-9</AccordionSummary>
						<AccordionDetails>
							<Stack direction="column" spacing={0}>
								{taluomimaDesc.map((val, index) => (
									<Accordion key={index}>
										<AccordionSummary expandIcon={<ExpandMoreIcon />}>
											<Typography>
												{val["number"]} {val["name_zh"]} {val["name"]}
											</Typography>
										</AccordionSummary>
										<AccordionDetails>
											<Stack direction={{ xs: "column", md: "row" }} spacing={2} alignItems="center">
												<Stack direction="row" justifyContent="center">
													<img
														src={val["image"]}
														className={`image-${index}`}
														alt="logo"
														style={{ xs: { width: "50%" } }}
													/>
												</Stack>
												<CardDetails
													title={val["title"]}
													gongke={val["gongke"]}
													mubiao={val["mubiao"]}
													goutong={val["goutong"]}
													qianneng={val["qianneng"]}
													guihua={val["guihua"]}
													xiaohai={val["xiaohai"]}
												/>
											</Stack>
										</AccordionDetails>
									</Accordion>
								))}
							</Stack>
						</AccordionDetails>
					</Accordion>
					<Accordion>
						<AccordionSummary expandIcon={<ExpandMoreIcon />}>星座</AccordionSummary>
						<AccordionDetails>
							<Typography>1: 白羊，摩羯</Typography>
							<Typography>2: 金牛，水瓶</Typography>
							<Typography>3: 双子，双鱼</Typography>
							<Typography>4: 巨蟹</Typography>
							<Typography>5: 狮子</Typography>
							<Typography>6: 处女</Typography>
							<Typography>7: 天秤</Typography>
							<Typography>8: 天蝎</Typography>
							<Typography>9: 射手</Typography>
							<TableContainer component={Paper}>
								<Table size="small">
									<TableBody>
										{allXingZuo.map((xingzuo, index) => (
											<TableRow key={index}>
												<TableCell>
													{xingzuo.value} - {xingzuo.name_zh} {xingzuo.name}
												</TableCell>
												<TableCell>{xingzuo.date}</TableCell>
											</TableRow>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						</AccordionDetails>
					</Accordion>
				</>
			)}
		</Stack>
	);
}

function SubmitNew() {
	const { theme } = useTheme();
	const { user } = useAuth();

	const [state, setState] = useState({
		name: "",
		nameZh: "",
		email: "",
		contact: "",
		dob: new Date(),
		occupation: "",
		address: "",
		nameError: false,
		nameZhError: false,
		openDateModal: false,
		isSubmitting: false,
		submitted: false,
		result1: 0,
		result2: 0,
		result3: 0,
		xingzuo: "",
		count: [0, 0, 0, 0, 0, 0, 0, 0, 0],
	});

	const [snackbar, setSnackbar] = useState({ open: false, vertical: "bottom", horizontal: "center", type: "info", message: "" });
	const showSnackbarMessage = (type, message) => setSnackbar({ ...snackbar, open: true, type, message });
	const handleCloseSnackbar = () => setSnackbar({ ...snackbar, open: false });

	const handleChangeDate = (e) => {
		setState({ ...state, openDateModal: false, dob: e });
	};

	const handleSubmit = () => {
		if (state.name !== "" && state.nameZh !== "" && state.email !== "" && state.contact !== "" && state.dob !== null) {
			const year = state.dob.getFullYear();
			const month = state.dob.getMonth() + 1;
			const date = state.dob.getDate();

			var solar = Solar.fromDate(new Date(parseInt(year), parseInt(month) - 1, parseInt(date)));
			const xingzuo = solar.getXingZuo();

			setState({ ...state, isSubmitting: true });

			fetch("https://tianshu.estoneweb.com/api/taluomima/submit.php", {
				method: "POST",
				body: JSON.stringify({
					name: state.name,
					nameZh: state.nameZh,
					email: state.email,
					contact: state.contact.replace(/-/g, "").replace(/ /g, ""),
					year: year,
					month: month,
					date: date,
					xingzuo: xingzuo,
					occupation: state.occupation,
					address: state.address,
					submitBy: user.username,
				}),
			})
				.then((res) => res.json())
				.then((result) => {
					if (result["success"]) {
						setState({
							...state,
							isSubmitting: false,
							submitted: true,
							result1: result["taluomima"][0],
							result2: result["taluomima"][1],
							result3: result["taluomima"][2],
							count: result["taluomima"][3],
							xingzuo: xingzuo + "座 " + allXingZuo.find(({ name_zh }) => name_zh === xingzuo + "座").name,
						});
					} else {
						console.log(result);
						setState({ ...state, isSubmitting: false });
						showSnackbarMessage("error", "Kindly contact Administrator");
					}
				})
				.catch((error) => console.error(error));
		} else {
			setState({
				...state,
				nameError: state.name === "",
				nameZhError: state.nameZh === "",
				emailError: state.email === "",
				contactError: state.contact === "",
				dobError: state.dob === null || state.dob === "",
			});
		}
	};

	const handleReset = () => {
		setState({
			...state,
			name: "",
			nameZh: "",
			email: "",
			contact: "",
			dob: new Date(),
			occupation: "",
			address: "",
			nameError: false,
			nameZhError: false,
			occupationError: false,
			addressError: false,
			openDateModal: false,
			submitted: false,
			result1: 0,
			result2: 0,
			result3: 0,
			xingzuo: "",
			count: [0, 0, 0, 0, 0, 0, 0, 0, 0],
		});
	};

	return (
		<Paper elevation={4} sx={{ p: 2 }}>
			<SnackBar snackbar={snackbar} handleCloseSnackbar={handleCloseSnackbar} />
			{!state.submitted && (
				<Stack direction="column" spacing={2}>
					<Typography sx={{ textAlign: "center" }}>塔罗密码数</Typography>
					<div className="row align-items-center">
						<div className="col-lg-3 col-md-4 col-sm-12 text-md-end text-sm-start">
							英文名字 <RedAsterisk />
						</div>
						<div className="col-lg-9 col-md-8 col-sm-12">
							<TextField
								fullWidth={true}
								variant="outlined"
								size="small"
								value={state.name}
								error={state.nameError}
								onChange={(e) => setState({ ...state, name: e.target.value })}
							/>
						</div>
					</div>
					<div className="row align-items-center">
						<div className="col-lg-3 col-md-4 col-sm-12 text-md-end text-sm-start">
							中文名字 <RedAsterisk />
						</div>
						<div className="col-lg-9 col-md-8 col-sm-12">
							<TextField
								fullWidth={true}
								variant="outlined"
								size="small"
								value={state.nameZh}
								error={state.nameZhError}
								onChange={(e) => setState({ ...state, nameZh: e.target.value })}
							/>
						</div>
					</div>
					<div className="row align-items-center">
						<div className="col-lg-3 col-md-4 col-sm-12 text-md-end text-sm-start">
							邮件地址 <RedAsterisk />
						</div>
						<div className="col-lg-9 col-md-8 col-sm-12">
							<TextField
								fullWidth={true}
								variant="outlined"
								size="small"
								value={state.email}
								error={state.emailError}
								onChange={(e) => setState({ ...state, email: e.target.value })}
							/>
						</div>
					</div>
					<div className="row align-items-center">
						<div className="col-lg-3 col-md-4 col-sm-12 text-md-end text-sm-start">
							电话号码 <RedAsterisk />
						</div>
						<div className="col-lg-9 col-md-8 col-sm-12">
							<MuiPhoneNumber
								variant="outlined"
								value={state.contact}
								error={state.contactError}
								onChange={(value) => setState({ ...state, contact: value, contactError: false })}
							/>
						</div>
					</div>
					<div className="row align-items-center">
						<div className="col-lg-3 col-md-4 col-sm-12 text-md-end text-sm-start">
							生日日期 <RedAsterisk />
						</div>
						<div className="col-lg-9 col-md-8 col-sm-12">
							<Stack direction="row" alignItems="center" justifyContent="space-between">
								<Typography>{displayDate(state.dob)}</Typography>
								<MuiButton variant="outlined" content="选择日期" onClick={() => setState({ ...state, openDateModal: true })} />
								<DatePicker
									theme={theme === "dark" ? "android-dark" : "android"}
									showCaption={true}
									min={new Date(1900, 0, 1)}
									dateConfig={dateConfig}
									headerFormat="DD/MMM/YYYY"
									isOpen={state.openDateModal}
									value={state.dob}
									onSelect={handleChangeDate}
									onCancel={() => setState({ ...state, openDateModal: false })}
								/>
							</Stack>
						</div>
					</div>
					<div className="row align-items-center">
						<div className="col-lg-3 col-md-4 col-sm-12 text-md-end text-sm-start">职业</div>
						<div className="col-lg-9 col-md-8 col-sm-12">
							<TextField
								fullWidth={true}
								variant="outlined"
								size="small"
								value={state.occupation}
								onChange={(e) => setState({ ...state, occupation: e.target.value })}
							/>
						</div>
					</div>
					<div className="row align-items-center">
						<div className="col-lg-3 col-md-4 col-sm-12 text-md-end text-sm-start">地址</div>
						<div className="col-lg-9 col-md-8 col-sm-12">
							<TextField
								fullWidth={true}
								variant="outlined"
								size="small"
								value={state.address}
								onChange={(e) => setState({ ...state, address: e.target.value })}
							/>
						</div>
					</div>
					<Stack direction="row" spacing={2} justifyContent="center">
						<MuiButton variant="contained" content="提交" loading={state.isSubmitting} onClick={handleSubmit} />
					</Stack>
				</Stack>
			)}
			{state.submitted && (
				<>
					<Result
						theme={theme}
						name={state.name}
						nameZh={state.nameZh}
						dob={state.dob}
						result1={state.result1}
						result2={state.result2}
						result3={state.result3}
						xingzuo={state.xingzuo}
						count={state.count}
					/>
					<Stack direction="row" spacing={2} justifyContent="center" sx={{ mt: 3 }}>
						<MuiButton variant="outlined" content="提交新的" onClick={handleReset} />
					</Stack>
				</>
			)}
		</Paper>
	);
}

function Result(props) {
	const [state, setState] = useState({
		openDialog: false,
		numberToShow: 0,
	});

	const stackStyleSx = {
		borderRadius: 3.5,
		px: 1.5,
		py: 0.5,
		transition: "all 0.5s ease",
		"&:hover": {
			cursor: "pointer",
			boxShadow: "0px 0px 15px #aaaaaa",
		},
	};

	const showCard = (num) => {
		setState({ ...state, openDialog: true, numberToShow: num });
	};

	const handleCloseDialog = () => {
		setState({ ...state, openDialog: false });
	};

	const getBackgroundColor = (index, count) => {
		return count >= index ? (props.theme === "dark" ? "grey" : "darkslategrey") : "transparent";
	};

	return (
		<Stack direction="column" spacing={2} alignItems="center">
			<Stack direction="column" spacing={0} alignItems="center">
				<Typography sx={{ mb: 1 }}>
					{props.name} {props.nameZh}
				</Typography>
				<Typography sx={{ mb: 2 }}>{displayDate(props.dob)}</Typography>
				{props.result1 !== 0 && (
					<Stack direction="column" alignItems="center" sx={stackStyleSx} onClick={() => showCard(props.result1)}>
						<Typography variant="h6">生命</Typography>
						<Typography variant="h5">{props.result1}</Typography>
					</Stack>
				)}
				<Stack direction="row" alignItems="flex-end" justifyContent="center" spacing={0}>
					{props.result3 !== 0 && (
						<Stack direction="column" alignItems="center" sx={stackStyleSx} onClick={() => showCard(props.result3)}>
							<Typography variant="h6">行为</Typography>
							<Typography variant="h5">{props.result3}</Typography>
						</Stack>
					)}
					<img src={props.theme === "dark" ? eyewhite : eyeblack} className="eye-logo" alt="logo" />
					{props.result2 !== 0 && (
						<Stack direction="column" alignItems="center" sx={stackStyleSx} onClick={() => showCard(props.result2)}>
							<Typography variant="h6">后天</Typography>
							<Typography variant="h5">{props.result2}</Typography>
						</Stack>
					)}
				</Stack>
			</Stack>
			{props.xingzuo !== "" && <Typography>{props.xingzuo}</Typography>}
			<table className="table-cell-outlined">
				<tbody>
					{props.count.map((cnt, index) => (
						<tr key={index}>
							<td style={{ textAlign: "center", cursor: "pointer" }} onClick={() => showCard(index + 1)}>
								{index + 1}
							</td>
							<td style={{ backgroundColor: getBackgroundColor(1, props.count[index]) }}></td>
							<td style={{ backgroundColor: getBackgroundColor(2, props.count[index]) }}></td>
							<td style={{ backgroundColor: getBackgroundColor(3, props.count[index]) }}></td>
							<td style={{ backgroundColor: getBackgroundColor(4, props.count[index]) }}></td>
							<td style={{ backgroundColor: getBackgroundColor(5, props.count[index]) }}></td>
							<td style={{ backgroundColor: getBackgroundColor(6, props.count[index]) }}></td>
							<td style={{ backgroundColor: getBackgroundColor(7, props.count[index]) }}></td>
							<td style={{ backgroundColor: getBackgroundColor(8, props.count[index]) }}></td>
							<td style={{ backgroundColor: getBackgroundColor(9, props.count[index]) }}></td>
						</tr>
					))}
				</tbody>
			</table>
			<CardDialog openDialog={state.openDialog} handleCloseDialog={handleCloseDialog} numberToShow={state.numberToShow} />
		</Stack>
	);
}

function CardDialog(props) {
	const [state, setState] = useState({
		number: 0,
		name_zh: "",
		name: "",
		image: null,
		title: "",
		gongke: "",
		mubiao: "",
		goutong: "",
		qianneng: "",
		guihua: "",
		xiaohai: "",
	});

	useEffect(() => {
		if (props.numberToShow !== 0) {
			const result = taluomimaDesc.find(({ number }) => number === props.numberToShow);
			setState(result);
		}
	}, [props]);

	return (
		<Dialog open={props.openDialog} fullWidth={true} maxWidth="md" onClose={props.handleCloseDialog}>
			<DialogTitle>
				{state.number} - {state.name_zh} {state.name}
			</DialogTitle>
			<DialogContent sx={{ px: 3 }}>
				<Stack direction={{ xs: "column", md: "row" }} spacing={2} alignItems="center">
					<Stack direction="row" justifyContent="center">
						{state.image !== null && state.image !== "" && (
							<img src={state.image} className={`image-${props.numberToShow}`} alt="logo" style={{ xs: { width: "50%" } }} />
						)}
					</Stack>
					<CardDetails
						title={state.title}
						gongke={state.gongke}
						mubiao={state.mubiao}
						goutong={state.goutong}
						qianneng={state.qianneng}
						guihua={state.guihua}
						xiaohai={state.xiaohai}
					/>
				</Stack>
			</DialogContent>
			<DialogActions>
				<Tooltip title="关闭" TransitionComponent={Zoom} arrow>
					<Button variant="outlined" onClick={props.handleCloseDialog}>
						<CloseIcon />
					</Button>
				</Tooltip>
			</DialogActions>
		</Dialog>
	);
}

export default TaLuoMiMaShu;
