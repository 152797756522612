import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

// export const drawerWidth = 265;
export const headerHeight = 50;

export const Main = styled("main")(({ theme, open }) => ({
	transition: theme.transitions.create("margin", {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	// marginLeft: `-${drawerWidth}px`,
	...(open && {
		transition: theme.transitions.create("margin", {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
		marginLeft: 0,
	}),
	marginTop: headerHeight,
	overflowX: "hidden",
	width: "100%",
}));

export const MainContainer = styled(Box)(({ theme }) => {
	return {
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(2),
		height: `calc(100vh - ${headerHeight}px)`,
		width: "100%",
		overflow: "auto",
	};
});

// export const DrawerHeader = styled("div")(({ theme }) => ({
// 	display: "flex",
// 	alignItems: "center",
// 	padding: theme.spacing(0, 1),
// 	minHeight: drawerHeaderHeight,
// 	justifyContent: "space-between",
// }));
