import { Box, Grid, Stack } from "@mui/material";
import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router";

import { MuiButton } from "../components/common";

function AdminPanel() {
	const navigate = useNavigate();

	const buttonStyle = { fontSize: "18px", px: 2, width: "100%" };

	const pages = [
		{ link: "/adminpanel/users", name: "用户" },
		{ link: "/adminpanel/allsubmission", name: "所有提交" },
		{ link: "/adminpanel/modules", name: "模块" },
	];

	useEffect(() => {});

	return (
		<Stack direction="column" alignItems="center" justifyContent="flex-start">
			<Stack direction="column" spacing={2} sx={{ width: { xs: "100%", sm: "90%", md: "80%", lg: "70%", xl: "60%" } }}>
				<Box sx={{ p: 1 }}>
					<Grid container spacing={2} justifyContent="center">
						{pages.map((page, index) => (
							<Grid item key={index}>
								<MuiButton
									variant={window.location.pathname === page.link ? "contained" : "outlined"}
									content={page.name}
									buttonStyle={buttonStyle}
									onClick={() => navigate(page.link)}
								/>
							</Grid>
						))}
					</Grid>
				</Box>
				<Box sx={{ px: 2 }}>
					<Outlet />
				</Box>
			</Stack>
		</Stack>
	);
}

export default AdminPanel;
