import { Button, Paper, Stack, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";

import { SnackBar } from "./components/common";
import { useAuth } from "./hooks/useAuth";
import Cookies from "./utils/Cookies";

function Login(props) {
	const navigate = useNavigate();
	const { user, login } = useAuth();

	const [snackbar, setSnackbar] = useState({ open: false, vertical: "bottom", horizontal: "center", type: "info", message: "" });
	const showSnackbarMessage = (type, message) => setSnackbar({ ...snackbar, open: true, type, message });
	const handleCloseSnackbar = () => setSnackbar({ ...snackbar, open: false });

	const [state, setState] = useState({
		username: "",
		password: "",
		loggedIn: false,
	});

	const handleKeyDown = (e) => {
		if (e.code === "Enter" || e.code === "NumpadEnter") {
			handleLogin();
		}
	};

	const handleLogin = () => {
		fetch("https://tianshu.estoneweb.com/api/auth/authenticate.php", {
			// mode: "no-cors",
			method: "POST",
			body: JSON.stringify({
				username: state.username,
				password: state.password,
			}),
		})
			.then((res) => res.json())
			.then(async (result) => {
				if (result["success"]) {
					Cookies.set("username", state.username);
					Cookies.set("email", result["email"]);
					Cookies.set("role", result["role"]);

					// navigate("/dashboard");

					await login({
						username: state.username,
						password: result["password"],
						email: result["email"],
						role: result["role"],
					});
				} else if (result["failed"]) {
					showSnackbarMessage("error", "Wrong Username or Password");
				} else {
					showSnackbarMessage("error", result["error"]);
				}
			})
			.catch((error) => console.error(error));
	};

	useEffect(() => {
		if (user) {
			navigate("/dashboard");
		}
	}, [user, navigate]);

	return (
		<Stack direction="column" alignItems="center" justifyContent="center" spacing={3} paddingX={3}>
			<SnackBar snackbar={snackbar} handleCloseSnackbar={handleCloseSnackbar} />
			<Paper sx={{ p: 3 }} elevation={4}>
				<Stack direction="column" spacing={2}>
					<div className="row align-items-center">
						<div className="col-md-4 col-sm-12 text-md-end text-sm-start">用户名</div>
						<div className="col-md-8 col-sm-12">
							<TextField
								fullWidth={true}
								variant="outlined"
								size="small"
								value={state.username}
								onChange={(e) => {
									setState({ ...state, username: e.target.value });
								}}
								onKeyDown={handleKeyDown}
							/>
						</div>
					</div>
					<div className="row align-items-center">
						<div className="col-md-4 col-sm-12 text-md-end text-sm-start">密码</div>
						<div className="col-md-8 col-sm-12">
							<TextField
								fullWidth={true}
								variant="outlined"
								type="password"
								size="small"
								value={state.password}
								onChange={(e) => {
									setState({ ...state, password: e.target.value });
								}}
								onKeyDown={handleKeyDown}
							/>
						</div>
					</div>
					<div className="row align-items-center">
						<div className="col-12">
							<Button fullWidth={true} variant="contained" onClick={handleLogin}>
								登入
							</Button>
						</div>
					</div>
					{/* <div className="row align-items-center">
						<div className="col-12">
							<Button
								fullWidth={true}
								variant="outlined"
								onClick={() => {
									navigate("/register");
								}}
							>
								注冊
							</Button>
						</div>
					</div> */}
				</Stack>
			</Paper>
		</Stack>
	);
}

export default Login;
