import { Paper, Stack, Typography } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Foto, Lunar, Solar } from "lunar-javascript";
import { useState } from "react";

function Calendar() {
	const [state, setState] = useState({
		weekdays: ["Sun", "Mon", "Tue", "Wed", "Thurs", "Fri", "Sat"],
		months: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
		currentDay: new Date(),
		pickedDate: new Date(),
		otherFestivals: [],
	});

	const handleChangeDate = (e) => {
		const year = e.getFullYear();
		const month = e.getMonth() + 1;
		const date = e.getDate();

		var solar = Solar.fromYmd(year, month, date);
		var lunar = Lunar.fromSolar(solar);
		var foto = Foto.fromLunar(lunar);
		var otherFestivals = foto.getOtherFestivals();

		setState({ ...state, otherFestivals: otherFestivals });
	};

	return (
		<Paper elevation={4} sx={{ p: 2 }}>
			<Stack direction="column" spacing={2}>
				<LocalizationProvider dateAdapter={AdapterDateFns}>
					<DatePicker label="选择日期" format="dd/MM/yyyy" value={state.pickedDate} onChange={handleChangeDate} />
				</LocalizationProvider>
				<Stack direction="column" spacing={2}>
					{state.otherFestivals.map((festival, index) => (
						<Typography key={index}>{festival}</Typography>
					))}
				</Stack>
				<Typography>
					{state.months[state.currentDay.getMonth()]} {state.currentDay.getFullYear()}
				</Typography>
			</Stack>
		</Paper>
	);
}

export default Calendar;
